import React, { useContext, useState } from 'react';
import { translateX } from 'utils/helpers';
import { Button, Col, Flex, Form, Modal, Row, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import { ParametersContext } from 'components/jarvisly-module/ParametersProvider';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PipelineTab from '../../Parameters/Pipeline/PipelineTab';
import PipelineModal from '../../Parameters/Pipeline/PipelineModal';
import crmService from '../../crmService';

// COMPONENT *******************************************************************
// *****************************************************************************
const DetailsBoardTab = props => {
  return (
    <Row gutter={ROW_GUTTER} className="mb-3">
      <Col span={24}>
        <JarvislyInput name="title" />
        <JarvislyTextArea name="description"/>
      </Col>
    </Row>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
const PipelineTab1 = props => {
  return (
    <Row gutter={ROW_GUTTER}>
      <Col span={24}>
        {/* FORM-LIST */}
        <Form.List name="pipeline">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Flex
                  justify="space-between"
                  align="center"
                  key={field.key}
                  style={{ height: 56 }}
                >
                  <Form.Item
                    {...field}
                    name={[field.name, 'key']}
                    rules={[
                      {
                        required: true,
                        message: translateX('required_field'),
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <JarvislyInput name={[field.name, 'title']} x-no-label />
                  </Form.Item>

                  <Button
                    icon={<MinusCircleOutlined />}
                    onClick={() => {
                      remove(field.name);
                      onClickRemovePipe(field.name);
                    }}
                    style={{
                      marginBottom: 48,
                      marginLeft: 12,
                    }}
                  />
                </Flex>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  &nbsp;{translateX('add_step')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        {/* FORM-LIST */}
      </Col>
    </Row>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { refreshParameters } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showModal,
    setShowModal,
    // selectedRecord,
    // setSelectedRecord,
    // isUpdated,
    // setIsUpdated,
    parameters,
    moduleType,
    refreshList,
  } = props;

  // local variables I ----------------------------------------------------------

  // component states -----------------------------------------------------------
  const [tabKey, setTabKey] = useState('details');

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowModal(false);
    console.log("botao de salvar")
  };


  function onTabClick(key) {
    setTabKey(key);

    switch (key) {
      // case 'configurations':
      //   configurationTabRef?.current?.getFirstFocus();
      //   break;

      // case 'RequiredFields':
      //   requiredFieldsTabRef?.current?.getFirstFocus();
      //   break;

      default:
        break;
    }
  }

  const onClickRemovePipe = (value, firstValidation) => {
    console.log('onChangeCoverages', value, firstValidation);
  };

  // local variables III --------------------------------------------------------
  const childProps = {
    ...props,
    parameters,

    // isUpdated,
    // setIsUpdated,
    // selectedRecord,
    // setSelectedRecord,
  };

  const tabItems = buildTabItems();

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        title={translateX('new_funnel')}
        destroyOnClose={true}
        open={showModal}
        width={640}
        onCancel={onClose}
        cancelText={translateX('close')}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => form.submit()}
        okText={translateX("save")}
        getContainer={false}
        okType={'primary'}
        afterClose={() => {
          // if (isUpdated && moduleType === 'list') refreshList();
          // setIsUpdated(false);
        }}
      >
        <Tabs onChange={onTabClick} items={tabItems} activeKey={tabKey} />
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItems() {
    return [
      {
        label: translateX('details'),
        key: 'details',
        hide: false,
        children: <DetailsBoardTab {...childProps} />,
      },
      /*
            {
              label: translateX('pipeline'),
              key: 'pipeline',
              hide: false,
              children: <PipelineTab {...childProps} />,
            },
            {
              label: translateX('automatization'),
              key: 'automatization',
              hide: false,
              children: <p>AUTOMATIZAÇÃO DE PROCESSOS</p>,
            },
      */
    ];
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const CrmBoardModal = props => {
  // providers context ---------------------------------------------------------
  const { moduleType } = useSelector(s => s.moduleSlice);
  const { refreshParameters, refreshList } = useContext(ModuleContext);
  const {
    selectedRecord,
    setSelectedRecord,
    showModalLevel,
    setShowModalLevel,
    isUpdated,
    setIsUpdated,
  } = useContext(ParametersContext);

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states ----------------------------------------------------------
  const [isLoading, setIsLoading] = useState(false);
  const [showPipelineModal, setShowPipelineModal] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
// No CrmBoardModal.js, modifique a função onConfirm:
const onConfirm = async ({body}) => {
  console.log("Dados do board:", body);
  console.log("Grupo selecionado:", props.selectedGroup);
  
  // Combine os dados do board com o ID do grupo
  const dataToSend = {
    ...body,
    groupId: props.selectedGroup._id // Adicione o ID do grupo
  };
  
  await crmService.addBoard(dataToSend, props.selectedGroup);
}
  // variables III -------------------------------------------------------------
  const childProps = {
    ...props,
    setIsUpdating: setIsLoading,
    isUpdating: isLoading,

    parameters,
    moduleType,

    showPipelineModal,
    setShowPipelineModal,

    refreshList,

    // selectedRecord,
    // setSelectedRecord,
    // isUpdating,
    // setIsUpdating,
    // showModalLevel,
    // setShowModalLevel,
    // refreshParameters,
    // refreshList,
    // isUpdated,
    // setIsUpdated,
  };

  // console.log('show modal', showModal);

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        autoFocus="title"
        onConfirm={onConfirm}
        abortComponentSave={true}
        >
        <ModalComponent {...childProps} />
      </JarvislyFormProvider>

      {/* PIPELINE MODAL WRAPPER */}
      {/*<PipelineModal {...childProps} />*/}
      {/* PIPELINE MODAL WRAPPER */}
    </>
  );
};

export default CrmBoardModal;
