import { Button, Flex, notification, Table, Tag, Tooltip } from 'antd';
import { parseDateToDisplay, translateX } from 'utils/helpers';
import { useEffect, useState } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import { useSelector } from 'react-redux';
import appmultiService from 'modules/app-views/_apps/appmulti/appmultiService';
import Icon from 'components/util-components/Icon';
import { MdOutlinePanTool } from 'react-icons/md';

// COMPONENT *******************************************************************
// *****************************************************************************

const IntegrationLogsTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);

  // local variables I ---------------------------------------------------------
  useTabChild('integration', null, props);
  const [notificationApi, contextHolder] = notification.useNotification();
  const columns = buildColumns();

  // component states ----------------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setLoading(true);
    (async () => {
      const docs = await appmultiService.getApiLogs();

      setData(docs || []);
      setLoading(false);
    })();
  }, []);

  // methods -------------------------------------------------------------------
  const onClickForceApi = async () => {
    const apis = parameters?.apis || [];
    setLoading(true);

    for (const item of apis) {
      const { value: api } = item;

      // rum api for exchange rate
      await appmultiService.runApi(api, 'update-exchange');

      // rum api for plans
      await appmultiService.runApi(api, 'update-plans');
    }

    const docs = await appmultiService.getApiLogs();

    setData(docs || []);
    setLoading(false);
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {contextHolder}

      <Flex justify="flex-end">
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          loading={loading}
          onClick={onClickForceApi}
        >
          {translateX('force_apis')}
        </Button>
      </Flex>
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data}
        size="small"
        loading={loading}
        pagination={{ defaultPageSize: 100, showSizeChanger: false }}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildColumns() {
    return [
      {
        dataIndex: 'createdAt',
        title: translateX('date'),
        width: 160,
        render: (_, record) => (
          <span>{parseDateToDisplay(record?.createdAt, true, true, true)}</span>
        ),
      },
      {
        dataIndex: 'api',
        title: translateX('api'),
        width: 120,
      },
      {
        dataIndex: 'action',
        title: translateX('action'),
        render: (_, record) => translateX(record?.action),
      },
      {
        dataIndex: 'status',
        title: translateX('status'),
        width: 180,
        render: (_, record) => (
          <Flex justify="start" align="center">
            <Tag
              color={
                record?.status === 'success'
                  ? 'green'
                  : record?.status === 'failed'
                    ? 'red'
                    : record?.status === 'in_progress'
                      ? 'orange'
                      : 'gray'
              }
              key={record.status}
              className="text-uppercase"
            >
              {translateX(record?.status)}
            </Tag>

            {record?.forced ? (
              <Tooltip title={translateX('manually_forced_api')}>
                <div style={{ marginTop: 6 }}>
                  <Icon
                    type={MdOutlinePanTool}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                    tootip
                  />
                </div>
              </Tooltip>
            ) : null}
          </Flex>
        ),
      },
      {
        dataIndex: 'description',
        title: translateX('description'),
      },
    ];
  }
};

export default IntegrationLogsTab;
