import { getFromLocalStorage } from 'utils/helpers';
import {
  DefaultFormHeaderColumn1,
  DefaultFormHeaderCustomIcons,
} from 'components/jarvisly-module/Form/FormHeader';
import dayjs from 'dayjs';
import EventsList from './List/List';
import EventsForm from './Form/Form';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MODULE_ID = 'applekkusEvents';
const MODULE_API = 'v1/events';

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const EVENTS_MODULE = {
  id: MODULE_ID,
  noBlockScreenWithAppNotConfigured: false,
  api: MODULE_API,
  urlCustomForm: null,
  defaultUrlQueryString: null,

  components: {
    // list
    dashboard: null,
    list: props => <EventsList {...props} />,
    grid: null,
    kanban: null,
    filter: null,

    // form
    form: (props, ref) => <EventsForm {...props} ref={ref} />,
    formHeaderTitle: null,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: null,
    formHeaderColumn3: null,
    formHeaderTags: null,
    formHeaderCustomIcons: props => (
      <DefaultFormHeaderCustomIcons {...props} showStatusIcon />
    ),

    // module Parameters
    parameters: null,

    // module data import
    dataImport: null,

    // data preview
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
    // canEditDocument
    // decorateParameters,
  },

  initialRequest: {
    folder: 'documents',

    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},

    filterOptions: {},

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },

    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {},

    sorterOptions: [],

    search: {},

    textSearch: {},
  },

  listState: {
    override: false,
    hideBreadcrumb: false,

    showParameters: false,
    showDataImport: false,

    initTab: null,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: false, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
    fieldsForRegex: ['name'],
    textSearchInDrawer: true, // inform that the textSearch field is into Drawer Filter to mark the filter button as danger (red)
    refreshDataOfList: 0,
  },

  documentState: {
    showParameters: false,

    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    newDocumentTitle: 'new_event',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: true,
    indexes: [],

    refreshDataOfDocument: 0,
    mockDocument: {
      name: 'Infodesk',
      cnpj: '02934311000153',
      fullName: 'Infodesk Technologies Ltda',
      phone: '19982320000',
      email: 'biruel@infodesk.com.br',
      zip: '13070173',
      address1: 'Avenida Marechal Rondon',
      number: '700',
      address2: 'Sala 307',
      neighborhood: 'Jardim Chapadão',
      __city: 'Campinas - SP',
      parameters: { __exchangeRate: 5.21 },
    },
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default EVENTS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  if (doc?.eventDate) doc.eventDate = dayjs(doc.eventDate);

  return doc;
}
