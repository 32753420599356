import appService from './appService';
import {
  parseCnpjToDB,
  parseCnpjToDisplay,
  parseCpfToDisplay,
  parsePhoneToDisplay,
  toCapitalCase,
  translateX,
} from '../utils/helpers';
import { API_BANKS, API_ENTITIES } from '../constants/ApiConstant';
import axiosService from './axiosService';
import dayjs from 'dayjs';
import bankService from './bankService';
import { RECEIVABLES_API } from '../modules/app-views/receivables/receivables';

const asaasService = {};

//*************************************************************************** //
// SUB-ACCOUNTS
//*************************************************************************** //

asaasService.createAccount = async (body, document) => {
  appService.notificationDestroy('failure');
  appService.message(
    'i',
    `${translateX('opening_digital_account')}... ${translateX('please_wait')}`,
    'open_account',
    10,
  );

  // do the asaas api request through the Jarvisly asaas-v1 integrator
  let asaasBody = {};

  try {
    asaasBody = {
      name: document?.fullName,
      email: body?.email,
      loginEmail: body?.email,
      cpfCnpj: parseCnpjToDB(document?.cnpj),
      birthDate: null,
      companyType: document?.companyType?.toUpperCase(),
      phone: document?.phone,
      mobilePhone: body?.phone,
      site: document?.website,
      incomeValue: null,
      address: document?.address1,
      addressNumber: document?.number,
      complement: document?.address2 || null,
      province: document?.neighborhood,
      city: document?.city,
      postalCode: document?.zip,
    };

    let axiosOptions = {
      url: `/v3/asaas/accounts`,
      data: asaasBody,
      headers: { 'x-subscriptionid': document?._metadata?.subscriptionId },
    };

    let [doc] = await axiosService.post(axiosOptions);

    const bank = bankService.getBankByNumber(body?.selectedBank) || {};

    const decoratedBody = {
      profile: document?.dataType,
      accountType: 'digital',
      alias: body?.alias,
      bankCode: bank?.code,
      bankNumber: bank?.number,
      bankName: bank?.name,
      bankTitle: bank?.title,

      agency: doc?.agency,
      agencyDigit: null,
      account: doc?.account,
      accountDigit: doc?.accountDigit,
      agencyCode: doc?.agencyCode,
      accountCode: doc?.accountCode,
      note: body?.note,

      entityId: document?._id,

      asaasInfo: {
        subAccountId: doc?.subAccountId,
        loginEmail: doc?.loginEmail,
        tradingName: doc?.tradingName?.toUpperCase(),
        createdAt: doc?._metadata?.createdAt,
        updatedAt: doc?._metadata?.updatedAt,
        statusCommercialInfo: doc?.asaasStatus?.commercialInfo,
        statusBankAccountInfo: doc?.asaasStatus?.bankAccountInfo,
        statusDocumentation: doc?.asaasStatus?.documentation,
        statusGeneral: doc?.asaasStatus?.general,
      },
      apiIntegrated: true,
      status: 'in_analysis',
      sandbox: body?.sandbox,
      accountProfile: body?.accountProfile,
    };

    axiosOptions = {
      url: API_BANKS,
      data: decoratedBody,
      headers: { 'x-subscriptionid': document?._metadata?.subscriptionId },
    };

    await axiosService.post(axiosOptions);

    appService.notification(
      's',
      translateX('open_digital_account_success'),
      'success',
      translateX(
        'open_digital_account_success_description',
        ['%EMAIL%'],
        [body.email],
      ),
      0,
    );
  } catch (error) {
    const response = error?.response?.data || error;

    appService.messageDestroy('open_account');

    if (response?.status === 400) {
      const title = translateX('open_digital_account_failure');
      let description;
      // "invalid_object: O CNPJ 23339151000174 já está em uso."
      // "invalid_object: O email email1@infodesk.com.br já está em uso."
      // "invalid_object: O email de login email1@infodesk.com.br já está em uso."
      // "invalid_action: O número de celular informado já excedeu o limite de uso. Informe outro e tente novamente."

      if (response?.message?.includes('invalid_object: O email')) {
        description = translateX(
          'open_digital_account_failure_email_description',
          ['%VALUE%'],
          [asaasBody.email],
        );
      } else if (response?.message?.includes('invalid_object: O CNPJ')) {
        description = translateX(
          'open_digital_account_failure_cnpj_description',
          ['%VALUE%'],
          [parseCnpjToDisplay(asaasBody.cpfCnpj)],
        );
      } else if (response?.message?.includes('invalid_object: O CPF')) {
        description = translateX(
          'open_digital_account_failure_cpf_description',
          ['%VALUE%'],
          [parseCpfToDisplay(asaasBody.cpfCnpj)],
        );
      } else if (
        response?.message?.includes('invalid_action: O número de celular')
      ) {
        description = translateX(
          'open_digital_account_failure_mobile_description',
          ['%VALUE%'],
          [parsePhoneToDisplay(asaasBody.phone)],
        );
      } else {
        description = response.message;
      }

      appService.notification('e', title, 'failure', description, 0);
    }

    throw error;
  }
};

asaasService.getAccountByCode = async accountCode => {
  try {
    const axiosOptions = {
      url: `/v3/asaas/accounts/get-by-field/accountCode/${accountCode}`,
    };

    const [docs] = await axiosService.get(axiosOptions);
    return docs?.[0] || null;
  } catch (error) {
    throw error;
  }
};

asaasService.getAccountByApiKey = async (apiKey, options) => {
  const { userName, userEmail } = options;

  try {
    const axiosOptions = {
      url: `/v3/asaas/accounts/get-by-api-key`,
      data: { apiKey, userName, userEmail },
    };

    const [doc] = await axiosService.post(axiosOptions);
    return doc;
  } catch (error) {
    throw error;
  }
};

//*************************************************************************** //
// CUSTOMERS
//*************************************************************************** //

asaasService.createCustomer = async (accountCode, customer) => {
  try {
    let axiosOptions = {
      url: `/v3/asaas/customers`,
      headers: { 'x-bankaccountcode': accountCode },
      data: decorateAsaasCustomerBody(customer),
    };

    const [doc] = await axiosService.post(axiosOptions);

    // update asaas customer id
    axiosOptions = {
      url: API_ENTITIES,
      _id: customer?._id,
      data: {
        _integrations: {
          asaasCustomerId: doc.id,
        },
      },
    };
    axiosService.put(axiosOptions).then();

    return doc || null;
  } catch (error) {
    throw error;
  }
};

asaasService.updateCustomer = async (accountCode, customerId, customer) => {
  try {
    let axiosOptions = {
      url: `/v3/asaas/customers`,
      headers: { 'x-bankaccountcode': accountCode },
      _id: customerId,
      data: decorateAsaasCustomerBody(customer),
    };

    const [doc] = await axiosService.put(axiosOptions);

    // update asaas customer id
    if (!customer?.parameters?._integrations?.asaasCustomerId) {
      axiosOptions = {
        url: API_ENTITIES,
        _id: customer?._id,
        data: {
          _integrations: {
            asaasCustomerId: customerId,
          },
        },
      };
      axiosService.put(axiosOptions).then();
    }

    return doc || null;
  } catch (error) {
    throw error;
  }
};

asaasService.getCustomerByCpfCnpj = async (accountCode, cpfCnpj) => {
  try {
    let axiosOptions = {
      url: `/v3/asaas/customers?cpfCnpj=${cpfCnpj}`,
      headers: { 'x-bankaccountcode': accountCode },
    };

    const [docs] = await axiosService.get(axiosOptions);
    return docs?.[0] || null;
  } catch (error) {
    throw error;
  }
};

//*************************************************************************** //
// PAYMENTS
//*************************************************************************** //

asaasService.generatePaymentUrl = async (
  bill,
  customer,
  hideMessages = false,
) => {
  const bank = bankService.getBankByAccountCode(bill?.accountCode) || {};

  if (!bank?.apiIntegrated) {
    appService.message('w', 'no_integrated_bank', 'payment', 5);
    return;
  } else if (bank?.status !== 'approved') {
    appService.message('w', 'no_active_bank', 'payment', 5);
    return;
  }

  const cpfCnpj = customer?.cpfCnpj || customer?.cpf || customer?.cnpj;

  try {
    let asaasCustomer = await asaasService.getCustomerByCpfCnpj(
      bank.accountCode,
      cpfCnpj,
    );

    if (!asaasCustomer?.id) {
      asaasCustomer = await asaasService.createCustomer(
        bank.accountCode,
        customer,
      );
    } else {
      asaasCustomer = await asaasService.updateCustomer(
        bank.accountCode,
        asaasCustomer.id,
        customer,
      );
    }

    // billing description
    const description = `${translateX('invoice_bill')} Nº ${
      bill._metadata.counter
    }     
${bill.description || ''}`;

    // BILLING TYPE
    let billingType = 'UNDEFINED';
    if (bill?.billingType === 'slip_pix') {
      billingType = 'BOLETO';
    } else if (bill?.billingType === 'pix') {
      billingType = 'PIX';
    } else if (bill?.billingType === 'credit_card') {
      billingType = 'CREDIT_CARD';
    }

    // INTEREST
    const interest = { value: bill?.interestPercentage || 0 };

    // DISCOUNT
    const discount = {
      value:
        bill?.discountType === 'value'
          ? bill?.discountValue || 0
          : bill?.discountPercentage || 0,
      type: bill?.discountType === 'value' ? 'FIXED' : 'PERCENTAGE',
    };
    if (bill?.discountExpireDate && dayjs(bill?.discountExpireDate).isValid()) {
      discount.dueDate = dayjs(bill.dueDate)
        .endOf('day')
        .diff(dayjs(bill.discountExpireDate).endOf('day'), 'days');
    }

    // FINE
    const fine = {
      value:
        bill?.fineType === 'value'
          ? bill?.fineValue || 0
          : bill?.finePercentage || 0,
      type: bill?.fineType === 'value' ? 'FIXED' : 'PERCENTAGE',
    };

    const body = {
      billingType,
      discount,
      interest,
      fine,
      // callback: {
      //   successUrl: 'https://www.infodesk.com.br/payment-done',
      //   autoRedirect: true,
      // },
      customer: asaasCustomer.id,
      value: bill.value,
      dueDate: dayjs(bill.dueDate).endOf('day').format('YYYY-MM-DD'),
      description,
      daysAfterDueDateToCancellationRegistration: 30,
      externalReference: bill._id,
      // installmentCount: bill?.installmentTotal || 1, // SOMENTE CASO DE PARCELAMENTO
      // installmentValue: bill.value / bill.installmentTotal,
      postalService: false,
    };

    if (bill?.installmentTotal > 1) {
      body.installmentCount = bill.installmentTotal;
      body.installmentValue = bill.value / bill.installmentTotal;
    }

    let axiosOptions = {
      url: `/v3/asaas/payments`,
      headers: { 'x-bankaccountcode': bank.accountCode },
      data: body,
    };

    /*
            const BODY_TO_ASAAS = {
              billingType: 'CREDIT_CARD',
              discount: { value: 0, type: 'PERCENTAGE' },
              interest: { value: 0 },
              fine: { value: 0, type: 'PERCENTAGE' },
              customer: 'cus_000005884693',
              value: 157.36,
              dueDate: '2024-04-30',
              description:
                'Fatura Nº 86     \nPedido Nº 108 | Gta | Msv América Latina Slim 24k | América Central (01/05/2024 - 09/05/2024) | 1 Viajante',
              daysAfterDueDateToCancellationRegistration: 30,
              externalReference: '6630fdc5a7b8fa00638c3873',
              postalService: false,
              installmentCount: 3,
              installmentValue: 52.45333333333334,
            };
        */

    const [asaasBill] = await axiosService.post(axiosOptions);

    /*
        const RETORNO_ASAAS = {
          object: 'payment',
          id: 'pay_u0eg3rmj9qug0ct7',
          dateCreated: '2024-04-30',
          customer: 'cus_000005884693',
          installment: 'e5e31325-2142-4d78-9869-c6b6ceaf2eb1',
          paymentLink: null,
          value: 52.45,
          netValue: 50.46,
          originalValue: null,
          interestValue: null,
          description:
            'Parcela 1 de 3. Fatura Nº 86     \nPedido Nº 108 | Gta | Msv América Latina Slim 24k | América Central (01/05/2024 - 09/05/2024) | 1 Viajante',
          billingType: 'CREDIT_CARD',
          confirmedDate: null,
          creditCard: null,
          pixTransaction: null,
          status: 'PENDING',
          dueDate: '2024-04-30',
          originalDueDate: '2024-04-30',
          paymentDate: null,
          clientPaymentDate: null,
          installmentNumber: 1,
          invoiceUrl: 'https://sandbox.asaas.com/i/u0eg3rmj9qug0ct7',
          invoiceNumber: '05655090',
          externalReference: '6630fdc5a7b8fa00638c3873',
          deleted: false,
          anticipated: false,
          anticipable: false,
          creditDate: null,
          estimatedCreditDate: null,
          transactionReceiptUrl: null,
          nossoNumero: null,
          bankSlipUrl: null,
          lastInvoiceViewedDate: null,
          lastBankSlipViewedDate: null,
          discount: {
            value: 0,
            limitDate: null,
            dueDateLimitDays: 0,
            type: 'PERCENTAGE',
          },
          fine: { value: 0, type: 'PERCENTAGE' },
          interest: { value: 0, type: 'PERCENTAGE' },
          postalService: false,
          custody: null,
          refunds: null,
        };
    */

    /*
        const WEBHOOK_BODY = {
          id: 'evt_05b708f961d739ea7eba7e4db318f621&6341322',
          event: 'PAYMENT_CREATED',
          payment: {
            object: 'payment',
            id: 'pay_ov0bk47255oyiu6a',
            dateCreated: '2024-04-30',
            customer: 'cus_000005884693',
            installment: 'dad3f6d4-f349-42e3-8d4c-0ec8789b0018',
            paymentLink: null,
            value: 26.22,
            netValue: 25.23,
            originalValue: null,
            interestValue: null,
            description:
              'Parcela 1 de 6. Fatura Nº 85     \nPedido Nº 107 | Gta | Msv América Latina Slim 24k | América Central (01/05/2024 - 09/05/2024) | 1 Viajante',
            billingType: 'CREDIT_CARD',
            confirmedDate: null,
            creditCard: {
              creditCardNumber: null,
              creditCardBrand: null,
            },
            pixTransaction: null,
            status: 'PENDING',
            dueDate: '2024-04-30',
            originalDueDate: '2024-04-30',
            paymentDate: null,
            clientPaymentDate: null,
            installmentNumber: 1,
            invoiceUrl: 'https://sandbox.asaas.com/i/ov0bk47255oyiu6a',
            invoiceNumber: '05655122',
            externalReference: '6630fcdaa7b8fa00638c2c29',
            deleted: false,
            anticipated: false,
            anticipable: false,
            creditDate: null,
            estimatedCreditDate: null,
            transactionReceiptUrl: null,
            nossoNumero: null,
            bankSlipUrl: null,
            lastInvoiceViewedDate: null,
            lastBankSlipViewedDate: null,
            discount: {
              value: 0,
              limitDate: null,
              dueDateLimitDays: 0,
              type: 'PERCENTAGE',
            },
            fine: {
              value: 0,
              type: 'PERCENTAGE',
            },
            interest: {
              value: 0,
              type: 'PERCENTAGE',
            },
            postalService: false,
            custody: null,
            refunds: null,
          },
          _metadata: {
            audit: {
              createdOnClient: {
                userAgent: 'Java/1.8.0_362',
                acceptLanguage: 'gzip',
              },
              updatedOnClient: {
                userAgent: 'Java/1.8.0_362',
                acceptLanguage: 'gzip',
              },
            },
            i18n: {
              countryCode: 'br',
              timezoneOffset: -180,
              languageCode: 'pt-br',
              localeCode: 'br',
            },
          },
        };
    */

    // update receivable
    axiosOptions = {
      url: RECEIVABLES_API,
      _id: bill?._id,
      data: {
        originalDueDate: asaasBill.originalDueDate,
        netValue: asaasBill.netValue,
        paymentUrl: asaasBill.invoiceUrl,
        bankSlipLink: asaasBill?.bankSlipUrl,
        bankSlipNumber: asaasBill.nossoNumero,
        status: 'waiting_for_payment',
        _integrations: {
          asaasPaymentId: asaasBill.id,
          asaasInstallmentKey: asaasBill?.installment, // only for credit card installments
        },
        asaasInfo: {
          customer: asaasBill?.customer,
          createdAt: asaasBill?.dateCreated,
          updatedAt: asaasBill?.dateCreated,
          canBePaidAfterDueDate: asaasBill?.canBePaidAfterDueDate,
        },
        asaasData: {
          pixTransaction: asaasBill?.pixTransaction,
          // anticipated: false,
          // anticipable: false,
          // creditDate: null,
          // estimatedCreditDate: null,
          // lastInvoiceViewedDate: null,
          // lastBankSlipViewedDate: null,
          // postalService: false,
          // custody: null,
          // refunds: null,
          status: asaasBill?.status,
        },
      },
    };

    const result = await axiosService.put(axiosOptions);

    if (!hideMessages) {
      appService.message('s', 'payment_link_generated_success', 'payment', 5);
    }

    return result;
  } catch (error) {
    appService.notification(
      'e',
      'payment_link_generated_failure',
      'payment',
      error?.response?.data?.message,
      0,
    );
    throw error;
  }
};

export default asaasService;

function decorateAsaasCustomerBody(data) {
  return {
    name: toCapitalCase(data?.fullName),
    cpfCnpj: data?.cpf || data?.cnpj,
    email: data?.email,
    phone: data?.phone,
    mobilePhone: data?.phone,
    address: data?.address1,
    addressNumber: data?.number,
    complement: data?.address2 || null,
    province: data?.neighborhood, // neighborhood
    postalCode: data?.zip,
    externalReference: data?._id,
    notificationDisabled: true,
    additionalEmails: data?.billingEmails.join(','),
    municipalInscription: data?.parameters?.municipalInscription || null,
    stateInscription: data?.parameters?.stateInscription || null,
    observations: 'Cadastro criado via API',
    // groupName: 'Pedidos Online',
    company: data?.cnpj ? data?.name : null,
  };
}
