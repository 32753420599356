import axiosService from 'services/axiosService';
import appService from 'services/appService';
import { MODULE_CONTEXT_VALUES } from 'components/jarvisly-module/ModuleContext';
import { PROCUCT_PARAMETERS_API } from './products';
import { generateHexColor, translateX } from 'utils/helpers';

const productsService = {};

productsService.addUnits = async ({ data, selectedRecord }) => {
  try {
    const { parameters: appParameters, refreshParameters } =
      MODULE_CONTEXT_VALUES || {};
    const parameters = appParameters?.productsParameters;

    if (!parameters?._id) {
      throw new Error('Parameters ID is missing');
    }

    const payload = {
      ...data,
      backColor: data.backColor?.toHexString?.() || '#b9bcc4',
      _id: selectedRecord?._id,
    };

    payload.foreColor = generateHexColor(payload.backColor, true);

    const axiosOptions = {
      url: `${PROCUCT_PARAMETERS_API}/add-to-set/${parameters._id}/$push/units`,
      data: payload,
      returnAsObject: true,
    };

    await axiosService.put(axiosOptions);
    appService.message('s', 'saved_parameters', 'onSave');
    refreshParameters?.();
    return true;
  } catch (error) {
    console.error('Failed to add units:', error);
    appService.message('e', 'save_failed', 'onSave');
    throw error;
  }
};

productsService.removeUnits = async record => {
  try {
    const { parameters: appParameters, refreshParameters } =
      MODULE_CONTEXT_VALUES || {};
    const parameters = appParameters?.productsParameters;

    if (!parameters?._id) {
      throw new Error('Parameters ID is missing');
    }

    const axiosOptionsGet = {
      url: `v1/products/get-by-field/unitId/${record._id}/`,
    };

    const doc = await axiosService.get(axiosOptionsGet);

    const qtdItems = doc[0]?.length;
    const translateQtd = `${translateX('There_are_still')} ${qtdItems} ${translateX('products_linked_unit')}`

    if (qtdItems > 0) {
      appService.message('w', translateQtd)
    } else {
      const axiosOptions = {
        url: `${PROCUCT_PARAMETERS_API}/add-to-set/${parameters._id}/$pull/units._id`,
        data: {
          _id: record?._id,
        },
      };

      await axiosService.put(axiosOptions);
      appService.message('s', 'saved_parameters', 'onSave');
      refreshParameters?.();
    }
    return true;
  } catch (error) {
    console.error('Failed to add units:', error);
    appService.message('e', 'save_failed', 'onSave');
    throw error;
  }
};

export default productsService;
