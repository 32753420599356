import store from 'store';
import { clone } from 'utils/helpers';
import axiosService from 'services/axiosService';
import { rdxSetLoadingModule } from '../../store/slices/moduleSlice';

const moduleService = {};

moduleService.getIndex = async function (_id, qsStr, partialData) {
  const { selectedModule } = store.getState().moduleSlice;
  let url = selectedModule.api;

  if (qsStr)
    url += partialData ? `/get-partial-data?${qsStr}` : `/get-index?${qsStr}`;
  // : `/get-pagination?${qsStr}`;

  let headers = null;
  if (Array.isArray(selectedModule?.listState?.fieldsForRegex)) {
    headers = {
      'x-fields-for-regex': selectedModule.listState.fieldsForRegex,
    };
  }

  try {
    const [docs, result] = await axiosService.get({
      method: 'get',
      headers,
      _id,
      url,
      // returnAsObject:true
    });

    return [clone(docs), result, result?.request];
  } catch (error) {
    throw error;
  }
};

moduleService.getKanban = async function (_id, qsStr) {
  const { selectedModule } = store.getState().moduleSlice;
  let url = selectedModule.api;

  // if (_id) url += `/${_id}`;
  if (qsStr) url += `/get-kanban?${qsStr}`;

  try {
    const [docs, result] = await axiosService.get({
      method: 'get',
      _id,
      url,
      returnAsObject: true,
    });

    return [clone(docs), result, result?.request];
  } catch (error) {
    throw error;
  }
};

moduleService.getGrid = async function (_id, qsStr) {
  const { selectedModule } = store.getState().moduleSlice;
  let url = selectedModule.api;

  // if (_id) url += `/${_id}`;
  if (qsStr) url += `/get-grid?${qsStr}`;

  try {
    const [docs, result] = await axiosService.get({
      method: 'get',
      _id,
      url,
      returnAsObject: false,
    });

    return [clone(docs), result, result?.request];
  } catch (error) {
    throw error;
  }
};

moduleService.getDashboard = async function (_id, qsStr) {
  const { selectedModule } = store.getState().moduleSlice;
  let url = selectedModule.api;

  // let url = `${selectedModule.api}/get-dashboard`;

  // if (_id) url += `/${_id}`;
  if (qsStr) url += `/get-dashboard?${qsStr}`;

  let headers = null;
  if (Array.isArray(selectedModule?.listState?.fieldsForRegex)) {
    headers = {
      'x-fields-for-regex': selectedModule.listState.fieldsForRegex,
    };
  }

  try {
    const [docs, result] = await axiosService.get({
      method: 'get',
      headers,
      _id,
      url,
      returnAsObject: true,
    });
    return [clone(docs), result, result?.request];
  } catch (error) {
    throw error;
  }
};

moduleService.getParameters = async function (force) {
  const { selectedModule } = store.getState().moduleSlice;

  if (!selectedModule?.id && !force) return null;

  const url = `/v1/parameters`;

  try {
    const [docs, result] = await axiosService.get({
      method: 'get',
      url,
      returnAsObject: true,
    });

    return [clone(docs), result, result?.request];
  } catch (error) {
    throw error;
  }
};

moduleService.updateKanbanColumnsOrder = async columnsArr => {
  const { selectedModule } = store.getState().moduleSlice;

  if (!selectedModule?.id) return null;

  // const url = `/v1/Parameters/pipeline/${selectedModule.id}`;

  // try {
  //   const [docs, result] = await axiosService.put({
  //     method: 'put',
  //     url,
  //     returnAsObject: true,
  //     data: columnsArr,
  //   });
  //
  //   return [clone(docs), result, result?.request];
  // } catch (error) {
  //   throw error;
  // }
};

moduleService.updateKanbanReordered = async kanbanObject => {
  const { selectedModule } = store.getState().moduleSlice;

  if (!selectedModule?.id) return null;

  // const url = `${selectedModule.api}/kanban-reorder`;

  // try {
  //   const [docs, result] = await axiosService.put({
  //     method: 'put',
  //     url,
  //     returnAsObject: true,
  //     data: kanbanObject,
  //   });
  //
  //   return [clone(docs), result, result?.request];
  // } catch (error) {
  //   throw error;
  // }
};

moduleService.loadingModule = (loading = false, timeout = 200) => {
  setTimeout(() => {
    store.dispatch(rdxSetLoadingModule(loading));
  }, timeout);
};

export default moduleService;
