import { useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
  generateHexColor,
  isValidCpf,
  returnOnlyNumbers,
  toCapitalCase,
  translateWord,
  translateX,
} from 'utils/helpers';
import {
  FormCpf,
  FormDatePicker,
  FormInput,
  FormSelectGender,
  FormWrapper,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appService from 'services/appService';
import dayjs from 'dayjs';
import axiosService from 'services/axiosService';
import entityService from 'services/entityService';
import appmultiService from 'modules/app-views/_apps/appmulti/appmultiService';
import { API_ENTITIES } from 'constants/ApiConstant';

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const MultiQuotationsOrderPassengerModal = props => {
  // props deconstruction ------------------------------------------------------
  const {
    record,
    travellers,
    showModal,
    setShowModal,
    onCallbackFn,
    isWorking,
    setSelectedRecord,
    filter,
    plan,
    isDisabled,
  } = props;

  // component states -----------------------------------------------------------
  const [isError, setIsError] = useState(false);
  const [entity, setEntity] = useState(null);
  const [updating, setUpdating] = useState(null);
  const [noCpf, setNoCpf] = useState(false);

  // local variables ------------------------------------------------------------
  const elRefs = useRef();
  const [form] = Form.useForm();

  // format data to form
  if (record?.birthdate) record.birthdate = dayjs(record.birthdate);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form.resetFields();

    if (record) {
      form.setFieldsValue(record);

      if (record?.entityId) {
        setEntity({
          _id: record.entityId,
          cpf: record?.cpf,
          noCpf: record?.noCpf || false,
          backColor: record?.backColor,
          foreColor: record?.foreColor,
        });
      }
      setNoCpf(record?.noCpf || false);
    } else {
      setEntity(null);
      setNoCpf(false);
    }
  }, [record, showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const onClose = () => {
    setSelectedRecord(null);
    form.resetFields();
    setShowModal(false);
    setUpdating(false);
  };

  const onChangeBirthdate = value => {
    const limitDate = dayjs(filter.departure).endOf('day');
    const age = limitDate.diff(dayjs(value), 'years');
    form.setFieldValue('age', age);

    if (age < plan.minAge || age > plan.maxAge) {
      appService.message('e', 'age_not_allowed', 'error');
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  // const getTravellerByField = async (value, field = 'cpf') => {
  //   const projection = [
  //     '_id',
  //     'name',
  //     'birthdate',
  //     'gender',
  //     'foreColor',
  //     'backColor',
  //   ];
  //
  //   return entityService.getEntityByField(value, field, projection);
  // };

  const onConfirm = async values => {
    // check if this passenger already exists
    const cpf = returnOnlyNumbers(values.cpf);
    const name = toCapitalCase(values.name);

    if (
      travellers.findIndex(
        t =>
          ((cpf && t.cpf === cpf) || t.name === name) && t.key !== record?.key,
      ) > -1
    ) {
      appService.notification(
        'e',
        'check_info',
        'check_info',
        'passenger_already_exists',
        5,
      );
      return;
    }

    if (values.noCpf && values.age >= 18) {
      appService.notification(
        'e',
        'cpf_required',
        'cpf_required',
        'cpf_required_for_adult',
        5,
      );

      return;
    }

    setUpdating(true);

    if (!entity?._id && cpf) {
      const doc = await entityService.getEntityByField(cpf, 'cpf');
      values.entityId = doc?._id;
    } else if (!entity?._id && name) {
      const doc = await entityService.getEntityByField(name, 'name');
      values.entityId = doc?._id;
    } else {
      values.entityId = entity?._id;
    }

    values.key =
      typeof record?.key !== 'undefined' ? record?.key : travellers.length;
    values.cpf = cpf;
    values.name = name;

    const backColor =
      !entity?.backColor || entity?.backColor === '#b9bcc4'
        ? generateHexColor()
        : entity.backColor;

    const foreColor =
      !entity?.backColor || entity?.backColor === '#b9bcc4'
        ? generateHexColor(backColor, true)
        : entity.foreColor;

    values.backColor = backColor;
    values.foreColor = foreColor;

    const body = {
      ...values,
      uniqueKey: cpf || +new Date(),
      dataType: 'person',
      dataProfile: 'traveller',
      name: name,
    };

    const [doc] = await axiosService.upsert({
      _id: values?.entityId,
      data: body,
      url: API_ENTITIES,
    });

    values.entityId = doc?._id;
    values.grievanceLevel = appmultiService.getTravelGrievanceLevel(
      plan,
      values.age,
    );

    if (onCallbackFn) onCallbackFn(values).then();
    onClose();
  };

  const onCpfChange = async cpf => {
    if (cpf === entity?.cpf) return true;
    if (!isValidCpf(cpf)) return setEntity(null);

    const doc = await entityService.getEntityByField(cpf, 'cpf');

    if (!doc) {
      setEntity(null);
      return true;
    }

    setEntity({
      _id: doc?._id,
      cpf,
      noCpf: false,
      foreColor: doc.foreColor,
      backColor: doc.backColor,
    });

    form.setFieldsValue({
      name: doc.name,
      fullName: doc.fullName,
      birthdate: doc?.birthdate ? dayjs(doc.birthdate) : null,
      gender: doc?.gender ? doc.gender : null,
      foreColor: doc?.foreColor,
      backColor: doc?.backColor,
    });

    if (doc?.birthdate) onChangeBirthdate(doc.birthdate);

    return true;
  };

  // const onNoCpfSwitchChange = checked => {
  //   setNoCpf(checked);
  //   setEntity({ ...entity, cpf: '', noCpf: checked });

  //   if (checked) {
  //     form.setFieldValue('cpf', null);
  //     form.resetFields(['cpf']);
  //   }
  // };

  // UI COMPONENT --------------------------------------------------------------

  return (
    <FormWrapper
      name="form-passenger"
      form={form}
      initialValues={record}
      elRefs={elRefs}
      onConfirm={onConfirm}
    >
      <Modal
        title={
          <>
            <UserOutlined />
            <span className="ml-2">{translateWord('passenger')}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        width={640}
        okText={translateX(!isDisabled ? 'save' : 'close')}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ disabled: isError }}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={isWorking || updating}
      >
        <Row gutter={ROW_GUTTER} className="mt-4">
          {/* CPF */}
          <Col xs={24} sm={8}>
            <FormCpf
              elRefs={elRefs}
              required={!noCpf}
              disabled={noCpf || isDisabled}
              form={form}
              hasFeedback={!noCpf}
              autoFocus
              onChange={onCpfChange}
            />
          </Col>

          {/* CPF */}

          {/* switch de opção sem cpf */}

          {/* <Col xs={8} sm={4} className="text-center text-md-left">
            <FormSimpleSwitch
              name="noCpf"
              label="Sem CPF"
              className="form-item-no-margin"
              onChange={onNoCpfSwitchChange}
              value={noCpf}
              disabled={isDisabled}
            />
          </Col> */}

          {/* FULL NAME */}

          <Col xs={24} sm={8}>
            <FormInput
              name="name"
              label="name_social"
              disabled={isDisabled || updating}
              onBlur={() => {
                const nameValue = form.getFieldValue('name');
                const fullNameValue = form.getFieldValue('fullName');
          
                // Atualiza o 'fullName' apenas se ele estiver vazio
                if (nameValue && (!fullNameValue || fullNameValue === "")) {
                  form.setFieldValue('fullName', nameValue); // Copia o valor de 'name' para 'fullName'
                }
              }}
              required
            />
          </Col>

          {/* FULL NAME */}
          <Col xs={24} sm={8}>
            <FormInput
              name="fullName"
              label="full_name"
              disabled={isDisabled || updating}
            />
          </Col>
          {/* FULL NAME */}
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/* BIRTHDATE */}
          <Col xs={18} sm={8}>
            <FormDatePicker
              name="birthdate"
              elRefs={elRefs}
              onChange={onChangeBirthdate}
              disabled={isDisabled}
              format={{
                format: 'DD/MM/YYYY',
                type: 'mask',
              }}
              required
            />
          </Col>
          {/* BIRTHDATE */}

          {/* AGE */}
          <Col xs={6} sm={4}>
            <FormInput name="age" elRefs={elRefs} disabled={true} />
          </Col>
          {/* AGE */}

          {/* GENDER */}
          <Col xs={24} sm={12}>
            <FormSelectGender elRefs={elRefs} disabled={isDisabled} required />
          </Col>
          {/* GENDER */}
        </Row>
      </Modal>
    </FormWrapper>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT **********************************************************************
// *****************************************************************************

export default MultiQuotationsOrderPassengerModal;
