import {
  BODY_BACKGROUND,
  BORDER,
  DARK_MODE,
  GRAY_SCALE,
  THEME_COLOR,
} from 'constants/ThemeConstant';
import appmulti from './applications/appmulti.js';
import appveertical from './applications/appveertical.js';
import appsanasa from './applications/appsanasa.js';
import applekkus from './applications/applekkus.js';
import appdedalo from './applications/appdedalo.js';
import appvaliant from './applications/appvaliant.js';
import appsindico from './applications/appsindico.js';
import appmundosaber from './applications/appmundosaber.js';

import { onProduction } from '../utils/helpers';
import foo from './scafold-modules/foo';
import payable from './scafold-modules/payable';
import applekkusReceivables from '../modules/app-views/receivables/receivables';
import invoices from './scafold-modules/invoices';
import appmultiLeads from '../modules/app-views/_apps/appmulti/leads/leads';
import appmultiPlans from '../modules/app-views/_apps/appmulti/plans/plans';

import applekkusCustomers, {
  setCustomerDataTypeProfile,
} from '../modules/app-views/customers/customers';
import crm from '../modules/app-views/crm/crm';
import appmultiQuotations from '../modules/app-views/_apps/appmulti/quotations/quotations';
import appmultiOrders from '../modules/app-views/_apps/appmulti/orders/orders';
import accounts from '../modules/app-views/account/account';
import coupons from '../modules/app-views/_apps/appmulti/coupons/coupons';
import orders from 'modules/app-views/_apps/applekkus/orders/orders.js';

// APPMULTI
import appmultiOperators from '../modules/app-views/_apps/appmulti/operators/operators';

// APPVEERTICAL

// APPDEDALO
import appdedaloAgendas from '../modules/app-views/_apps/appdedalo/agendas/agendas';

// APPVALIANT
import appvaliantLeads from '../modules/app-views/_apps/appvaliant/leads/leads';

// APPLEKKUS
import applekkusProducts from '../modules/app-views/products/products';
import events from '../modules/app-views/_apps/applekkus/events/events';
import applekkusDataSearch from '../modules/app-views/dataSearch/dataSearch';
import applekkusCrm from '../modules/app-views/crm/crm';
import applekkusServices from '../modules/app-views/services/services.js';

// APPVEERTICAL
import appveerticalIotDevices from '../modules/app-views/_apps/appveertical/iotDevices/iotDevices';

//APPSINDICO
import units from 'modules/app-views/_apps/appsindico/units/units.js';
import vehicles from 'modules/app-views/_apps/appsindico/Vehicles/vehicles.js';

/*
 * A cada novo modulo...
 * 1) Adicione o novo modulo neste arquivo (ex: veerticalStock)
 * 2) Adicione o novo modulo neste arquivo no objeto MODULES = {}
 * 3) Garantir a existência do módulo no array MODULES do arquivo de configuração do projeto, app<<name>>.js
 * 4) No arquivo routes/RoutesIndex.js, em 'componentsByPath'... Adicione o MAIN_ROUTE_... do module.json
 * */

const FORCE_APP_IN_DEV = appmulti; // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DEV STARTUP PROJECT
const ENV_APP = process.env.REACT_APP_APPLICATION_CODE;

export const APPLICATIONS = [
  appmulti,
  appveertical,
  applekkus,
  appdedalo,
  appvaliant,
  appsindico,
  appsanasa,
  appmundosaber,
];

export const FORCE_APP = ENV_APP
  ? APPLICATIONS.find(a => a.ID === ENV_APP) || FORCE_APP_IN_DEV
  : FORCE_APP_IN_DEV;

setCustomerDataTypeProfile(FORCE_APP?.ID);

export const MODULES = {
  // applekkus
  applekkusProducts,
  applekkusCustomers,
  applekkusDataSearch,
  applekkusCrm,
  applekkusReceivables,
  applekkusServices,

  // appveertical
  appveerticalIotDevices,

  // appmulti
  appmultiOperators,
  appmultiPlans,
  appmultiLeads,
  appmultiQuotations,
  appmultiOrders,

  // core
  foo,
  accounts,
  // customersPersons,
  payable,

  invoices,
  crm,
  coupons,
  orders,

  // appveertical
  // quotations,

  // appdedalo
  appdedaloAgendas,

  // appvaliant
  appvaliantLeads,

  events,

  // appsindico
  units,
  vehicles,
};

export const PROFILES = {
  singleCompany: {
    TYPE: 'singleCompany',
    NAME: 'organization',
    NAME_PLURAL: 'organizations',
    REGISTER_ORGANIZATION: 'register_an_organization',
    TITLE_ORGANIZATIONS_TAP: 'organizations',
    NEW_MESSAGE: 'new_organization',
    SELECT_A_COMPANY_PLACEHOLDER: 'select_an_organization',
  },

  // franchising
  multiCompanies: {
    TYPE: 'multiCompanies',
    NAME: 'organization',
    NAME_PLURAL: 'organizations',
    REGISTER_ORGANIZATION: 'register_an_organization',
    TITLE_ORGANIZATIONS_TAP: 'organizations',
    NEW_MESSAGE: 'new_organization',
    SELECT_A_COMPANY_PLACEHOLDER: 'select_an_organization',
  },

  singleClinic: {
    TYPE: 'singleClinic',
    NAME: 'clinic',
    NAME_PLURAL: 'clinics',
    REGISTER_ORGANIZATION: 'register_a_clinic',
    TITLE_ORGANIZATIONS_TAP: 'my_clinic',
    NEW_MESSAGE: 'new_clinic',
    SELECT_A_COMPANY_PLACEHOLDER: 'select_a_clinic',
  },

  MultiClinics: {
    TYPE: 'MultiClinics',
    NAME: 'clinic',
    NAME_PLURAL: 'clinics',
    REGISTER_ORGANIZATION: 'register_a_clinic',
    TITLE_ORGANIZATIONS_TAP: 'my_clinic',
    NEW_MESSAGE: 'new_clinic',
    SELECT_A_COMPANY_PLACEHOLDER: 'select_a_clinic',
  },

  condominium: {
    TYPE: 'condominium',
    NAME: 'condominium',
    NAME_PLURAL: 'condominiums',
    NEW_MESSAGE: 'new_condominium',
    SELECT_A_COMPANY_PLACEHOLDER: 'select_a_condominium',
  },
};

const getApplicationColorTheme = () => {
  const url = new URL(window.location.href);
  const domain = url.hostname; // return subdomain + domain

  let primaryColor = THEME_COLOR.BLUE;

  if (!onProduction()) {
    if (FORCE_APP?.THEME_COLOR && THEME_COLOR?.[FORCE_APP.THEME_COLOR]) {
      primaryColor = THEME_COLOR?.[FORCE_APP.THEME_COLOR];
    }
  } else {
    for (const app of APPLICATIONS) {
      if (app.DOMAINS.includes(domain)) {
        if (app?.THEME_COLOR && THEME_COLOR?.[app.THEME_COLOR]) {
          primaryColor = THEME_COLOR?.[app.THEME_COLOR];
        }
      }
    }
  }

  return primaryColor;
};

export const baseTheme = {
  borderRadius: 8,
  colorPrimary: getApplicationColorTheme(),
  colorSuccess: THEME_COLOR.CYAN,
  colorWarning: THEME_COLOR.GOLD,
  colorError: THEME_COLOR.VOLCANO,
  colorInfo: THEME_COLOR.BLUE,
  colorText: GRAY_SCALE.GRAY,
  colorBorder: BORDER.BASE_COLOR,
  bodyBg: BODY_BACKGROUND,
  controlHeight: 40,
  controlHeightLG: 48,
  controlHeightSM: 36,
  fontFamily: `'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji'`,
  fontSizeHeading2: 22,
  fontSizeHeading4: 17,
  colorLink: getApplicationColorTheme(),
};

const getBaseComponentThemeConfig = ({
  Button = {},
  Menu = {},
  Card = {},
  Table = {},
  Select = {},
  DatePicker = {},
  Layout = {},
  Input = {},
  Dropdown = {},
  Calendar = {},
} = {}) => {
  return {
    Button: {
      ...Button,
    },
    Dropdown: {
      controlPaddingHorizontal: 20,
      controlHeight: 37,
      borderRadiusLG: 10,
      paddingXXS: '4px 0px',
      ...Dropdown,
    },
    Calendar: {
      ...Calendar,
    },
    Checkbox: {
      lineWidth: 2,
      borderRadiusSM: 4,
    },
    Card: {
      colorTextHeading: GRAY_SCALE.GRAY_DARK,
      paddingLG: 20,
      ...Card,
    },
    Layout: {
      bodyBg: BODY_BACKGROUND,
      headerBg: GRAY_SCALE.WHITE,
      ...Layout,
    },
    Breadcrumb: {
      colorTextDescription: GRAY_SCALE.GRAY_LIGHT,
      colorText: baseTheme.colorPrimary,
      colorBgTextHover: 'transparent',
    },
    Menu: {
      itemBg: 'transparent',
      colorActiveBarHeight: 0,
      colorActiveBarWidth: 0,
      horizontalItemSelectedColor: baseTheme.colorPrimary,
      itemHoverColor: baseTheme.colorPrimary,
      itemSelectedColor: baseTheme.colorPrimary,
      itemSelectedBg: rgba(baseTheme.colorPrimary, 0.2),
      itemHoverBg: 'transparent',
      radiusItem: 0,
      subMenuItemBg: 'transparent',
      itemMarginInline: 4,
      controlHeightLG: 40,
      controlHeightSM: 22,
      ...Menu,
    },
    Input: {
      ...Input,
    },
    Pagination: {
      paginationItemSize: 30,
      borderRadius: '50%',
      colorBgContainer: baseTheme.colorPrimary,
      colorPrimary: GRAY_SCALE.WHITE,
      colorPrimaryHover: GRAY_SCALE.WHITE,
    },
    Steps: {
      wireframe: true,
      controlHeight: 32,
      waitIconColor: GRAY_SCALE.GRAY_LIGHT,
    },
    DatePicker: {
      controlHeight: 40,
      controlHeightSM: 26,
      borderRadiusSM: 6,
      lineWidthBold: 0,
      ...DatePicker,
    },
    Radio: {
      fontSizeLG: 18,
    },
    Slider: {
      colorPrimaryBorder: rgba(baseTheme.colorPrimary, 0.8),
      colorPrimaryBorderHover: baseTheme.colorPrimary,
    },
    Select: {
      paddingXXS: '4px 0px',
      controlHeight: 40,
      controlHeightSM: 30,
      controlItemBgActive: rgba(baseTheme.colorPrimary, 0.1),
      ...Select,
    },
    TreeSelect: {
      controlHeightSM: 24,
    },
    Tooltip: {
      colorBgDefault: rgba(GRAY_SCALE.DARK, 0.75),
      controlHeight: 32,
    },
    Timeline: {
      lineType: 'dashed',
    },
    Tag: {
      lineHeight: 2.1,
    },
    Table: {
      colorText: GRAY_SCALE.GRAY,
      tableHeaderBg: 'transparent',
      ...Table,
    },
  };
};

export const lightTheme = {
  token: {
    ...baseTheme,
    colorTextBase: GRAY_SCALE.GRAY,
  },
  components: getBaseComponentThemeConfig(),
};

export const darkTheme = {
  token: {
    ...baseTheme,
    colorTextBase: DARK_MODE.TEXT_COLOR,
    colorBgBase: DARK_MODE.BG_COLOR,
    colorBorder: DARK_MODE.BORDER_BASE_COLOR,
    colorText: DARK_MODE.TEXT_COLOR,
  },
  components: getBaseComponentThemeConfig({
    Button: {
      controlOutline: 'transparent',
      colorBorder: DARK_MODE.BORDER_BASE_COLOR,
      colorText: DARK_MODE.TEXT_COLOR,
    },
    Card: {
      colorTextHeading: DARK_MODE.HEADING_COLOR,
    },
    Layout: {
      bodyBg: '#1b2531',
      headerBg: DARK_MODE.BG_COLOR,
    },
    Menu: {
      itemSelectedBg: 'transparent',
      horizontalItemSelectedColor: GRAY_SCALE.WHITE,
      itemHoverColor: GRAY_SCALE.WHITE,
      itemSelectedColor: GRAY_SCALE.WHITE,
      itemColor: DARK_MODE.TEXT_COLOR,
      colorActiveBarWidth: 0,
      boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
    },
    Dropdown: {
      boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
    },
    Calendar: {
      controlItemBgActive: '#303a4e',
    },
    Select: {
      controlOutline: 'transparent',
      controlItemBgActive: DARK_MODE.ACTIVE_BG_COLOR,
      controlItemBgHover: DARK_MODE.HOVER_BG_COLOR,
      boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
    },
    Input: {
      controlOutline: 'transparent',
    },
    DatePicker: {
      controlOutline: 'transparent',
      boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
      controlItemBgActive: DARK_MODE.ACTIVE_BG_COLOR,
      controlItemBgHover: DARK_MODE.HOVER_BG_COLOR,
    },
    Table: {
      colorText: DARK_MODE.TEXT_COLOR,
      colorTextHeading: DARK_MODE.HEADING_COLOR,
    },
  }),
};

export function rgba(hex, opacity = 1) {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    throw new Error('Invalid Hex');
  }

  const decimal = parseInt(hex.substring(1), 16);

  const r = (decimal >> 16) & 255;
  const g = (decimal >> 8) & 255;
  const b = decimal & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
