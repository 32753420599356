import React, { useContext, useEffect } from 'react';
import { Card } from 'antd';
import JarvislyCompanyIndex from 'components/jarvisly-components/forms/JarvislyCompanyIndex';
import { JarvislyFormContext } from 'components/jarvisly-components/others/FormComponents';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import useTabChild from '../../../../../../../components/jarvisly-components/hooks/useTabChild';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const CompaniesRecordIndex = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  useTabChild('record', [], props);

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Card>
      <JarvislyCompanyIndex {...props} showWebsite />
    </Card>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default CompaniesRecordIndex;
