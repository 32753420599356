import { Tabs } from 'antd';
import { useEffect } from 'react';
import { translateX } from 'utils/helpers';
import ServicesRecordIndex from './Record/RecordIndex';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import useTabParent from 'components/jarvisly-components/hooks/useTabParentHook';
import { clone } from 'lodash';
import { useSelector } from 'react-redux';

const ServicesForm = props => {
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, setRequiredFields, requiredFields, mode } =
    props;

  // local variables I ---------------------------------------------------------
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab];
  const { parameters } = useSelector(s => s.moduleSlice);
  const moduleParameters = parameters?.servicesParameters || {};

  // component states ----------------------------------------------------------
  const [tab, setTab] = useTabParent(availableTabs, 'reference');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedTab: tab,
    initTab,
    tab,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const parameterRequiredFields = clone(
      moduleParameters?.requiredFields || [],
    );

    // if (Array.isArray(parameterRequiredFields)) {
    //   if (parameterRequiredFields.includes('cpfCnpj')) {
    //     parameterRequiredFields.push('cpf', 'cnpj');
    //   }
    //   if (parameterRequiredFields.includes('address')) {
    //     parameterRequiredFields.push('zip', 'number');
    //   }
    // }

    setRequiredFields([
      ...parameterRequiredFields,
      'reference',
      'description',
      'unitId',
      '',
    ]);
  }, [moduleParameters?.requiredFields]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;
    return body;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <>
      <JarvislyFormProvider
        document={document}
        requiredFields={requiredFields}
        showSubmitButton={tab !== 'contacts'}
        setTab={setTab}
        onSave={onSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('identification'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <ServicesRecordIndex {...childProps} />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
};

export default ServicesForm;
