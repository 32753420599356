import axiosService from 'services/axiosService';
import { CRM_PARAMETERS_API } from './crm';
import { useSelector } from 'react-redux';
import store from '../../../store';
import appService from '../../../services/appService';
import {
  MODULE_CONTEXT_VALUES,
  ModuleContext,
} from '../../../components/jarvisly-module/ModuleContext';
import { useContext } from 'react';

const crmService = {};

crmService.addBoardGroup = async data => {
  const { parameters: appParameters, refreshParameters } =
    MODULE_CONTEXT_VALUES;
  const parameters = appParameters?.crmParameters;

  const axiosOptions = {
    url: `${CRM_PARAMETERS_API}/add-to-set/${parameters?._id}/$push/boardGroups`,
    data,
    returnAsObject: true,
  };

  await axiosService.put(axiosOptions);
  refreshParameters();
};

crmService.editBoardGroupTitle = async (key, title) => {
  const { refreshParameters } = MODULE_CONTEXT_VALUES;

  const axiosOptions = {
    url: `${CRM_PARAMETERS_API}/board-groups/${key}`,
    data: { label: title },
    returnAsObject: true,
  };

  await axiosService.put(axiosOptions);
  refreshParameters();
};

crmService.removeBoardGroup = async groupId => {
  const { parameters: appParameters, refreshParameters } =
    MODULE_CONTEXT_VALUES;
  const parameters = appParameters?.crmParameters;

  const axiosOptions = {
    url: `${CRM_PARAMETERS_API}/add-to-set/${parameters?._id}/$pull/boardGroups._id`,
    data: { _id: groupId },
    returnAsObject: true,
  };

  appService.notification('s', 'success', 'removed', 'item_removed', 10);

  await axiosService.put(axiosOptions);
  refreshParameters();
};

crmService.addBoard = async (data, group) => {
  const { parameters: appParameters, refreshParameters } =
    MODULE_CONTEXT_VALUES;
  const parameters = appParameters?.crmParameters;

  console.log('Adicionando board com os seguintes dados:', {
    boardData,
    targetGroup: group,
    parametersId: parameters?._id,
  });

  const addBoardOptions = {
    url: `${CRM_PARAMETERS_API}/add-to-set/${parameters?._id}/$push/boards/`,
    data,
    returnAsObject: true,
  };

  const doc = await axiosService.put(addBoardOptions);

  // const lastAddedBoard = doc[0].boards[doc[0].boards.length - 1];
  // console.log('Último board adicionado:', lastAddedBoard);

  // if (lastAddedBoard?._id) {
  //   const existingBoardsIds = group.boardsIds || [];
  //   const updatedBoardsIds = [...existingBoardsIds, lastAddedBoard._id];

  //   const updateGroupOptions = {
  //     url: `${CRM_PARAMETERS_API}/board-groups`,
  //     _id: group?._id,
  //     data: {
  //       boardsIds: updatedBoardsIds
  //       },
  // };

  //   console.log('Atualizando grupo com novos boardsId:', updatedBoardsIds);
  //   await axiosService.put(updateGroupOptions);
  // }

  refreshParameters();
};

export default crmService;
