// COMPONENT *******************************************************************
// *****************************************************************************
import { Flex, Table, Tag } from 'antd';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import { PlusOutlined } from '@ant-design/icons';
import { translateX } from 'utils/helpers';
import appService from 'services/appService';
import axiosService from 'services/axiosService';
import iotDevicesService from '../../iotDevicesService';

const StocksTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    parameters,
    setShowStockModal,
    setSelectedRecord,
    selectedRecord,
    refreshParameters,
  } = props;

  // local variables I ----------------------------------------------------------
  const tableColumns = buildTableColumns();

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onAdd = () => {
    setShowStockModal(true);
    setSelectedRecord(null)
  };

  const onEdit = record => {
    setSelectedRecord(record);
    setShowStockModal(true);
  };

  const onDelete = async record => {
console.log(record)
    await iotDevicesService.pullAddToSet({
      record,
      field: "stockId",
      path: "stocks._id"
    });
    refreshParameters(true);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={parameters?.categoriesParameters?.stocks || []}
        pagination={false}
        disabled={false}
        size="small"
      />

      <Flex justify="center">
        <FormButton
          title="add_stock"
          className="m-3"
          style={{ width: 'fit-content' }}
          icon={<PlusOutlined />}
          disabled={false}
          onClick={onAdd}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        title: translateX('stock'),
        dataIndex: 'stock',
        className: 'text-truncate',
        render: (_, record) => (
          <Tag key={record._id} color={record?.backColor}>
            <span
              className="text-uppercase"
              style={{ color: record?.foreColor }}
            >
              {record?.label}
            </span>
          </Tag>
        ),
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              disabled={false}
              deleteTitle={translateX('remove_this_item', ['%ITEM%'], ['item'])}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default StocksTab;
