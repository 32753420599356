import {
  DeploymentUnitOutlined,
  EditOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { FaChartBar, FaSearchengin } from 'react-icons/fa';
import { GiMining } from 'react-icons/gi';
import { FaRegHandshake } from 'react-icons/fa6';
import { LuBriefcaseBusiness } from 'react-icons/lu';
import { BsPersonVideo2 } from 'react-icons/bs';
import { MdOutlineAccountTree } from 'react-icons/md';
import { PiHandCoinsLight } from 'react-icons/pi';
import { TbMoneybag, TbSquarePercentage } from 'react-icons/tb';
import { GrMoney } from 'react-icons/gr';

const APP_VALIANT = {
  ID: 'appvaliant',
  NAME: 'Valiant Seguros',
  THEME_COLOR: 'CUSTOM_VALIANT_APP',
  THEME: 'light',
  FINTECH_SERVICES: true,
  DOMAINS: ['sistema.valiantseguros.com', 'valiant.jarvisly.com'],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'multiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: true,
  COPY_RIGHT: 'Valiant Seguros',
  '//API_DEV_HOST': ['localhost', 'vps', 'heroku'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8007,
  API_PORT_PRODUCTION: 8007,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'vps.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  API_HOST_DEV_HEROKU: 'valiant.herokuapp.com',
  WHATSAPP_INTEGRATED: false,
  API_INTEGRATED: false,
  TOKEN: process.env.REACT_APP_APPVALIANT_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/appvaliant/logo-app.png',
  LOGO_APP_WHITE: '/apps/appvaliant/logo-app-white.png',

  LOGO_APP_ICON: '/apps/appvaliant/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/appvaliant/logo-app-icon-white.png',

  LOGO: '/apps/appvaliant/logo.png',
  LOGO_WHITE: '/apps/appvaliant/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/appvaliant/logo-app-side.png',

  TITLE: 'Valiant Seguros',
  '//SLOGAN': 'Condomínios Inteligentes',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: false,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: true,
  },
  ENABLE_MARKETPLACE: true,
  MODULES: ['accounts', 'leads', 'dataSearch', 'customers'],
  SHOW_ALL_MENUS: true,
  MENU_NAVIGATION: [
    // ******************
    // DATA SOURCE
    // ******************
    {
      key: 'data_source',
      path: '/app/data_source',
      title: 'data_source',
      className: '',
      icon: GiMining,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'data_source_data_search',
          path: '/app/data_source/data_search',
          title: 'data_search',
          icon: FaSearchengin,
          moduleId: 'applekkusDataSearch',
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },

        /*
                {
                  key: 'data_source_lottery',
                  path: '/app/data_source/lottery',
                  title: 'lottery',
                  icon: HiOutlineTicket,
                  moduleId: 'applekkusLottery',
                  component: null,
                  marketplace: false,
                  subscriptionLevel: [0, 1],
                },
        */

        {
          key: 'data_source_leads',
          path: '/app/data_source/leads',
          title: 'leads',
          icon: DeploymentUnitOutlined,
          moduleId: 'appvaliantLeads',
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
      ],
    },

    // ******************
    // RECORDS
    // ******************
    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      className: '',
      icon: EditOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        // entities
        {
          key: 'records_entities',
          path: '/app/records/entities',
          title: 'entities',
          icon: TeamOutlined,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'records_entities_customers',
              path: '/app/records/entities/customers',
              title: 'customers',
              icon: TeamOutlined,
              moduleId: 'applekkusCustomers',
              component: null,
              marketplace: true,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_entities_consultants',
              path: '/app/records/entities/consultants',
              title: 'consultants',
              icon: LuBriefcaseBusiness,
              moduleId: 'applekkusConsultants',
              component: null,
              marketplace: true,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_entities_contacts',
              path: '/app/records/entities/contacts',
              title: 'contacts_list',
              icon: BsPersonVideo2,
              moduleId: 'applekkusContacts',
              component: null,
              marketplace: true,
              subscriptionLevel: [0, 1],
            },
          ],
        },
      ],
    },

    // ******************
    // ADMIN
    // ******************
    {
      key: 'admin',
      path: '/app/administrative',
      title: 'administrative',
      className: '',
      icon: MdOutlineAccountTree,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        // ------------------
        // COMMERCIAL
        // ------------------
        {
          key: 'admin_sales',
          path: '/app/administrative/commercial',
          title: 'commercial',
          className: '',
          icon: FaRegHandshake,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'admin_commercial_crm',
              path: '/app/administrative/commercial/crm',
              title: 'crm',
              icon: FaChartBar,
              moduleId: 'applekkusCrm',
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
          ],
        },

        // ------------------
        // FINANCIAL
        // ------------------
        {
          key: 'admin_financial',
          path: '/app/administrative/financial',
          title: 'financial',
          className: '',
          icon: GrMoney,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'admin_financial_payable',
              path: '/app/administrative/financial/payable',
              title: 'payable',
              icon: PiHandCoinsLight,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_financial_receivable',
              path: '/app/administrative/financial/receivable',
              title: 'receivable',
              icon: TbMoneybag,
              moduleId: 'applekkusReceivables',
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_financial_commissions',
              path: '/app/administrative/financial/commissions',
              title: 'commissions',
              icon: TbSquarePercentage,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
          ],
        },
      ],
    },
  ],
  FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_APPVALIANT_FIREBASE_CONFIG),
};

export default APP_VALIANT;
