import { Col, Flex, Row, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import appveerticalService from 'services/appveerticalService';
import { parsePhoneToDisplay, translateX } from 'utils/helpers';
import { Icon } from 'components/util-components/Icon';
import {
  PiBatteryVerticalFull,
  PiPlugsConnected,
  PiThermometerColdLight,
} from 'react-icons/pi';
import {
  TbAccessPoint,
  TbClockCheck,
  TbPhotoSensor2,
  TbPlugConnected,
  TbPlugConnectedX,
  TbSubmarine,
} from 'react-icons/tb';
import { PhoneOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { BsBuilding } from 'react-icons/bs';
import { FcElectricalSensor } from 'react-icons/fc';
import { RiArrowDownDoubleFill, RiArrowUpDoubleFill } from 'react-icons/ri';
import {
  PiBatteryWarningVerticalDuotone,
  PiBatteryVerticalFullDuotone,
  PiBatteryVerticalFullLight,
  PiBatteryVerticalHighLight,
  PiBatteryVerticalMediumLight,
  PiBatteryVerticalLowLight,
  PiBatteryWarningVerticalLight,
} from 'react-icons/pi';
import 'animate.css';
import DocumentStatusTag from '../../../../../components/jarvisly-components/others-old/DocumentStatusTag';

const realStatusMinutes = 5;
const padding = 12;

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesInfoIcons = props => {
  const { vertical = false, mr = 0, style = {}, className = '' } = props;

  return (
    <Flex
      vertical={vertical}
      style={{ ...style, marginRight: mr }}
      className={className}
    >
      <IotDevicesUpDownIcon {...props} pr />
      <IotDevicesModelIcons {...props} pr />
      <IotDevicesConnectedIcons {...props} />
    </Flex>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesUpDownIcon = props => {
  const { document, partialDocument, isEmpty, pl, pr, vertical } = props;

  if (!document || isEmpty) return;

  const infoValue =
    partialDocument?.field1?.infoValue ||
    partialDocument?.infoValue ||
    document?.field1?.infoValue ||
    document?.infoValue;

  const direction =
    partialDocument?.field1?.direction ||
    partialDocument?.direction ||
    document?.field1?.direction ||
    document?.direction;

  const lastReadAt = partialDocument?.lastReadAt || document?.lastReadAt;

  const onlineSeconds = lastReadAt && dayjs().diff(lastReadAt, 'seconds');

  const realStatusOnline = onlineSeconds
    ? onlineSeconds < 60 * realStatusMinutes
    : false;

  const colorIcon =
    direction === 'up' ? 'green' : direction === 'down' ? 'red' : 'blue';

  let levelIcon = PiBatteryWarningVerticalDuotone;
  let levelTooltip = 'empty_tank';
  let levelColor = 'red';

  if (infoValue > 100) {
    levelIcon = PiBatteryVerticalFullDuotone;
    levelTooltip = 'overflowing_tank';
    levelColor = 'red';
  }
  if (infoValue === 100) {
    levelIcon = PiBatteryVerticalFullLight;
    levelTooltip = 'full_tank';
    levelColor = 'green';
  }

  if (infoValue < 100) {
    levelIcon = PiBatteryVerticalHighLight;
    levelTooltip = 'tank_over_half_full';
    levelColor = 'green';
  }
  if (infoValue < 75) {
    levelIcon = PiBatteryVerticalMediumLight;
    levelTooltip = 'half_tank';
    levelColor = 'yellow';
  }

  if (infoValue < 50) {
    levelIcon = PiBatteryVerticalLowLight;
    levelTooltip = 'tank_below_half';
    levelColor = 'orange';
  }

  if (infoValue < 25) {
    levelIcon = PiBatteryWarningVerticalLight;
    levelTooltip = 'empty_tank';
    levelColor = 'red';
  }

  const icon =
    direction === 'up'
      ? RiArrowUpDoubleFill
      : direction === 'down'
        ? RiArrowDownDoubleFill
        : direction === 'idle'
          ? PiBatteryVerticalFull
          : null;

  const styleFlex = { opacity: realStatusOnline ? 1 : 0.3 };
  if (pr) {
    if (!vertical) {
      styleFlex.paddingRight = padding;
    } else {
      styleFlex.paddingBottom = padding;
    }
  } else if (pl) {
    if (!vertical) {
      styleFlex.paddingLeft = padding;
    } else {
      styleFlex.paddingTop = padding;
    }
  }

  // const animatedCss = 'animate__animated animate__bounce animate__infinite';
  const animatedCss = '';

  let msg = levelTooltip;

  if (realStatusOnline && icon) {
    if (direction === 'up') {
      msg = 'filling_tank';
    } else if (direction === 'down') {
      msg = 'emptying_tank';
    }
  }

  return (
    <>
      <Tooltip title={translateX(msg)}>
        <Flex justify="center" align="center" style={styleFlex}>
          <Icon
            type={realStatusOnline && icon ? icon : levelIcon}
            className={`${realStatusOnline && icon ? colorIcon : levelColor}  ${['up', 'down'].includes(direction) ? animatedCss : ''} font-size-md`}
            style={{ flexShrink: 0 }}
          />
        </Flex>
      </Tooltip>
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesModelIcons = props => {
  const { document, partialDocument, isEmpty, pl, pr, vertical } = props;

  if (!document || isEmpty) return;

  let icon, tooltip;
  const model = partialDocument?.model || document?.model;

  switch (model) {
    case 'ultrasonic':
      icon = TbAccessPoint;
      tooltip = 'ultrasonic_sensor_model';
      break;

    case 'infra_red':
      icon = TbPhotoSensor2;
      tooltip = 'infrared_sensor_model';
      break;

    case 'probe06':
      icon = TbSubmarine;
      tooltip = 'probe_sensor_model_06';
      break;

    case 'probe20':
      icon = TbSubmarine;
      tooltip = 'probe_sensor_model_20';
      break;

    default:
      icon = PiThermometerColdLight;
      tooltip = 'temperature_sensor';
  }

  const styleFlex = {};
  if (pr) {
    if (!vertical) {
      styleFlex.paddingRight = padding;
    } else {
      styleFlex.paddingBottom = padding;
    }
  } else if (pl) {
    if (!vertical) {
      styleFlex.paddingLeft = padding;
    } else {
      styleFlex.paddingTop = padding;
    }
  }

  return (
    <>
      <Tooltip title={translateX(tooltip)}>
        <Flex justify="center" align="center" style={styleFlex}>
          <Icon
            type={icon}
            className={`font-size-md`}
            style={{ flexShrink: 0 }}
          />
        </Flex>
      </Tooltip>
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesChannelTag = props => {
  const { document } = props;

  if (!document) return;

  return (
    <>
      <Tooltip title={translateX('channel')}>
        <Tag
          color="default"
          style={{ height: 22, marginInlineEnd: 4, marginRight: 10 }}
        >
          {document?.channel}
        </Tag>
      </Tooltip>
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesConnectedIcons = props => {
  const { document, partialDocument, isEmpty, pl, pr, vertical } = props;

  if (!document || isEmpty) return;

  const lastReadAt = partialDocument?.lastReadAt || document?.lastReadAt;

  const alreadyConnected =
    partialDocument?.firstReadAt || document?.firstReadAt;

  const onlineSeconds = lastReadAt && dayjs().diff(lastReadAt, 'seconds');

  const realStatusOnline = onlineSeconds
    ? onlineSeconds < 60 * realStatusMinutes
    : false;

  const lastConnectionTranslated = alreadyConnected
    ? realStatusOnline
      ? translateX('device_connected')
      : translateX(
          'device_last_connect',
          ['%LAST_CONNECTION_IN%'],
          [dayjs(lastReadAt).format('L LT')],
        )
    : translateX('device_never_connected');

  const colorIcon = alreadyConnected
    ? realStatusOnline
      ? 'green'
      : 'red'
    : 'gray';

  const icon = alreadyConnected
    ? realStatusOnline
      ? PiPlugsConnected
      : TbPlugConnected
    : TbPlugConnectedX;

  const styleFlex = { opacity: alreadyConnected ? 1 : 0.3 };
  if (pr) {
    if (!vertical) {
      styleFlex.paddingRight = padding;
    } else {
      styleFlex.paddingBottom = padding;
    }
  } else if (pl) {
    if (!vertical) {
      styleFlex.paddingLeft = padding;
    } else {
      styleFlex.paddingTop = padding;
    }
  }

  return (
    <>
      <Tooltip title={translateX(lastConnectionTranslated)}>
        <Flex justify="center" align="center" style={styleFlex}>
          <Icon
            type={icon}
            // type={connected ? ApiTwoTone : ApiOutlined}
            className={`${colorIcon} font-size-md`}
            style={{ flexShrink: 0 }}
          />
        </Flex>
      </Tooltip>
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesFormHeaderColumn2 = props => {
  const { document, partialDocument } = props;

  const lastReadAt = partialDocument?.lastReadAt || document?.lastReadAt;
  const type = partialDocument?.field1?.type || document?.field1?.type;

  return (
    <Col xs={24} sm={8}>
      {/* LAST READ */}
      <Row style={{ display: lastReadAt ? 'initial' : 'none' }}>
        <Col>
          {lastReadAt && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('last_reading')}>
                <Flex justify="start" align="center" className="text-truncate">
                  <Icon
                    type={TbClockCheck}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {dayjs(lastReadAt).fromNow()}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>
      {/* LAST READ */}

      {/* CURRENT VALUE */}
      <Row style={{ display: type ? 'initial' : 'none' }}>
        <Col>
          <IoTDistance data={partialDocument || document} />
        </Col>
      </Row>
      {/* CURRENT VALUE */}
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesFormHeaderColumn3 = props => {
  const { document } = props;

  const customer = document?.__customer;

  return (
    <Col xs={24} sm={8}>
      {/* CUSTOMER */}
      <Row style={{ display: customer?.name ? 'initial' : 'none' }}>
        <Col>
          {customer?.name && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('name')}>
                <Flex justify="start" align="center" className="text-truncate">
                  <Icon
                    type={BsBuilding}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {customer?.name}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>
      {/* CUSTOMER */}

      {/* CONTACT */}
      <Row style={{ display: customer?.contactName ? 'initial' : 'none' }}>
        <Col>
          {customer?.contactName && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('contact')}>
                <Flex justify="start" align="center" className="text-truncate">
                  <Icon
                    type={UserOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {customer?.contactName}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>
      {/* CONTACT */}

      {/* MOBILE */}
      <Row style={{ display: customer?.phone ? 'initial' : 'none' }}>
        <Col>
          {customer?.phone && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('phone')}>
                <Flex justify="start" align="center" className="text-truncate">
                  <Icon
                    type={PhoneOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {parsePhoneToDisplay(customer?.phone)}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>
      {/* MOBILE */}
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesHeaderCustomIcons = props => {
  return (
    <>
      <IotDevicesChannelTag {...props} pr />
      <IotDevicesInfoIcons {...props} pr />
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const DeviceTag = props => {
  const { record, uppercase } = props;
  const options = appveerticalService.buildDevicesOptions();
  const selected = record?.type
    ? options.find(d => d.value === record?.type) || options[0]
    : options[0];

  return (
    <Tag
      className={uppercase ? 'text-uppercase' : 'text-capitalize'}
      style={{ margin: 4 }}
      color={selected.color}
    >
      <span style={{ color: selected?.textColor || 'white' }}>
        {selected.text}
      </span>
    </Tag>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesFormHeaderTitle = props => {
  const { document } = props;

  return (
    <Tooltip title={translateX('device')}>
      <span>{document?.title}</span>
    </Tooltip>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesFormHeaderTags = props => {
  const { document } = props;

  return (
    <>
      <DocumentStatusTag {...props} record={document} />
      {/*<DocumentConditionTag record={record} />*/}
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
/*export const IotDevicesStatusTag = props => {
  const record = props?.record || props?.document;

  console.log('record', record);

  const dse = props?.status || record?.status; // document status in english
  const documentStatus = dse && translateX(dse);

  const overdue =
    !props?.status &&
    record?._metadata?.audit?.createdAt &&
    dayjs(record._metadata.audit.createdAt).diff(dayjs(), 'days') > 1;

  console.log('overdue', documentStatus, overdue);

  return (
    <>
      {documentStatus && (
        <Tooltip title={translateX('status')}>
          <Tag
            style={{ margin: 4 }}
            color={getIotDevicesStatusColor(overdue ? 'overdue' : dse)}
          >
            {documentStatus}
          </Tag>
        </Tooltip>
      )}
    </>
  );
};*/

// COMPONENT *******************************************************************
// *****************************************************************************
export const DocumentConditionTag = (data, className) => {
  if (!data) return;

  const record = data?.record;
  const dse = record?.condition; // document status in english
  const documentCondition = dse && translateX(dse);
  const tooltip = translateX(getDocumentConditionDescription(dse, record));

  return (
    <span className={className}>
      {documentCondition && (
        <Tooltip placement="top" title={tooltip}>
          <Tag
            className="text-capitalize"
            style={{ margin: 4 }}
            color={getDocumentConditionColor(dse)}
          >
            {documentCondition}
          </Tag>
        </Tooltip>
      )}
    </span>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IoTDistance = ({ data }) => {
  let currentValue = data?.field1?.currentValue || 0;

  const unit =
    data?.field1?.type === 'distance'
      ? `${(currentValue / 100).toFixed(2)}mts`
      : data?.field1?.type === 'temperature'
        ? `${Math.round(currentValue)}`
        : 'n/a';

  return (
    <>
      {unit && (
        <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
          <Tooltip placement="right" title={translateX('sensor_distance')}>
            <Flex justify="start" align="center" className="text-truncate">
              <Icon
                type={FcElectricalSensor}
                className="text-primary font-size-md"
                style={{ flexShrink: 0 }}
              />
              <div className="ml-2 font-weight-semibold" style={{ flex: 1 }}>
                {unit}
              </div>
            </Flex>
          </Tooltip>
        </Row>
      )}
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const OnOffStatusTag = props => {
  const { record } = props;

  const selected = buildOnOffStatusFilter(record?.status || 'offline');

  return (
    <Tag
      className={`text-capitalize ${props.className}`}
      style={{ margin: 4 }}
      color={selected.color}
    >
      <div style={{ marginTop: 2 }}>{selected.value}</div>
    </Tag>
  );
};

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================
export const getIotDevicesStatusColor = status => {
  switch (status) {
    case 'calibrating':
      return 'orange';

    case 'offline':
      return 'gray';

    case 'online':
      return 'green';

    default:
      return 'gray';
  }
};

export const buildOnOffStatusFilter = status => {
  switch (status) {
    case 'online':
      return { value: translateX('online'), color: 'green' };
    case 'removed':
      return { value: translateX('removed'), color: 'red' };
    case 'calibrating':
      return { value: translateX('calibrating'), color: 'orange' };
    case 'offline':
      return { value: translateX('offline'), color: 'gray' };
    default:
      return { value: 'offline', color: 'gray' };
  }
};

export const getDocumentConditionColor = status => {
  switch (status) {
    case 'new':
      return '#b9bcc4';

    case 'assembled':
      return '#87d068';

    case 'installed':
      return '#108ee9';

    case 'removed':
      return 'orange';

    case 'discarded':
      return 'red';

    default:
      return '#b9bcc4';
  }
};

export const getDocumentConditionDescription = status => {
  switch (status) {
    case 'removed':
      return 'removed';

    case 'assembled':
      return 'assembled';

    case 'installed':
      return 'installed';

    case 'discarded':
      return 'discarded';

    case 'new':
      return 'new';

    default:
      return 'n_a';
  }
};
