import { Card, Col, Row } from 'antd';
import {
  JarvislyFormContext,
  JarvislyNumber,
  JarvislySelect,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appveerticalService from 'services/appveerticalService';
import { translateX } from 'utils/helpers';
import { useContext, useEffect, useState } from 'react';
import { ModuleContext } from '../../../../../../../components/jarvisly-module/ModuleContext';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const InstallationTab = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { activeTab, document } = props;

  // local variables I ---------------------------------------------------------

  // appveerticalService.buildDevicesCategoriesOptions(Parameters);
  const tankShapeOptions = appveerticalService.buildDevicesFormatOptions();
  const tankMaterialOptions = appveerticalService.buildDevicesMaterialOptions();

  // component states ----------------------------------------------------------
  const [tankShape, setTankShape] = useState(null);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (document?.water_level_sensor?.tankShape) {
      setTankShape(document.water_level_sensor.tankShape);
    }
  }, [document, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps


  // methods -------------------------------------------------------------------
  const onTankShapeChange = value => {
    setTankShape(value);
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/*      <Card title={translateX('local')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="location"
              name="locationId"
              options={locationsOptions}
              required
            />
          </Col>

          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="sector"
              name="sectorId"
              options={sectorsOptions}
              required
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="installation_location"
              name={['water_level_sensor', 'tankInstallation']}
              options={tankLocationOptions}
              required
            />
          </Col>
        </Row>
      </Card>*/}


      <Card title={translateX('tank_dimensions')}>

        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12}>
            <JarvislySelect
              label='format'
              name={['water_level_sensor', 'tankShape']}
              options={tankShapeOptions}
              onChange={onTankShapeChange}
              required
            />
          </Col>
          <Col xs={24} sm={12}>
            <JarvislySelect
              label='material'
              name={['water_level_sensor', 'tankMaterial']}
              options={tankMaterialOptions}
              required
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>

          {/* DEPTH SENSOR */}
          <Col xs={24} sm={tankShape === 'square' ? 6 : 8}>
            <JarvislyNumber
              label='depth_sensor'
              name={['water_level_sensor', 'depthSensor']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=','
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          {/* DEPTH SENSOR */}


          {/* DEPTH FLOW */}
          <Col xs={24} sm={tankShape === 'square' ? 6 : 8}>
            <JarvislyNumber
              label='depth_flow'
              name={['water_level_sensor', 'depthFlow']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=','
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          {/* DEPTH FLOW */}

          {/* WIDTH */}
          <Col xs={24} sm={tankShape === 'square' ? 6 : 8}>
            <JarvislyNumber
              label={translateX(tankShape === 'square' ? 'width' : 'diameter')}
              name={['water_level_sensor', 'width']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=','
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          {/* WIDTH */}


          {/* LENGTH */}
          {tankShape === 'square'
            ? <Col xs={24} sm={6}>
              <JarvislyNumber
                label='length'
                name={['water_level_sensor', 'length']}
                min={1}
                max={62000}
                addonBefore={null}
                addonAfter={'cm'}
                decimalSeparator=','
                suffixStyle={{ width: 60 }}
                required
              />
            </Col>
            : null}
          {/* LENGTH */}


        </Row>

        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24}>
            <JarvislyTextArea name='note' />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
}; 

export default InstallationTab;
