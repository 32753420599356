import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.js';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/reset.css';
import './index.css';
// import ptBR from 'antd/lib/locale/pt_BR';
// import enUS from 'antd/lib/locale/en_US';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about apiConfig workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
