import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useContext, useEffect, useState } from 'react';
import {
  goFocus,
  JarvislyAsyncAutoComplete,
  JarvislyCpfCnpj,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyMultiEmails,
  JarvislyPhone,
  JarvislyRadioButtons,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import entityService from 'services/entityService';
import JarvislyContactForm from 'components/jarvisly-components/forms/Items/JarvislyContactForm';
import JarvislyAddressForm from 'components/jarvisly-components/forms/Items/JarvislyAddressForm';
import { Card, Col, Collapse, Descriptions, Flex, Grid, Row, Spin } from 'antd';
import { ShoppingOutlined } from '@ant-design/icons';
import { isValidCpfCnpj, returnOnlyNumbers, translateX } from 'utils/helpers';
import appmultiService from 'modules/app-views/_apps/appmulti/appmultiService';
import { Icon } from 'components/util-components/Icon';
import { MdPayment } from 'react-icons/md';
import bankSlipImg from 'assets/images/bank_slip.png';
import creditCardImg from 'assets/images/credit_card.png';
import pixImg from 'assets/images/pix.png';
import { Select } from 'antd/lib';
import utils from 'utils';
import financialService from 'services/financialService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const { useBreakpoint } = Grid;

// COMPONENT *******************************************************************
// *****************************************************************************
const OrderQuotationsCheckoutStep3 = props => {
  // props deconstruction ------------------------------------------------------
  const {
    // setStep,
    // setIsGenerating,
    isGenerating,
    isMobile,
    // buyer,
    travellers,
    plan,
    documentContext,
    // order,
    // setOrder,
    isDisabled,

    // emergencyContacts,
    setPlan,

    paymentSelected,
    setPaymentSelected,
    creditCardInstallment,
    setCreditCardInstallment,
  } = props;

  // local variables I ---------------------------------------------------------
  const filter = documentContext?.filter || {};
  const isLarge = utils.getBreakPoint(useBreakpoint()).includes('lg');

  // component states ----------------------------------------------------------
  const [installmentsArr, setInstallmentsArr] = useState([]);

  // local variables II --------------------------------------------------------
  // const onOpenPaymentUrl = () => {
  //   const link = order?.__receivables?.[0]?.paymentUrl || order?.paymentUrl;
  //   if (!link) return;
  //   window.open(link, '_blank');
  // };
  // const items = buildItems();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const total = plan?.summary?.total?.netPriceWithCouponDiscount;

    if (!total) {
      const value = financialService.formatMoney(total, 2, 'brl', true);
      setInstallmentsArr([
        {
          value: `1X`,
          label: (
            <span style={{ fontSize: 14 }}>{`1X DE ${value} (S/JUROS)`}</span>
          ),
        },
      ]);
    } else {
      const options = [];
      for (let i = 0; i < 6; i++) {
        const value = financialService.formatMoney(
          total / (i + 1),
          2,
          'brl',
          true,
        );
        options.push({
          value: `${i + 1}X`,
          label: (
            <span style={{ fontSize: 14 }}>
              {`${i + 1}X DE ${value} (S/JUROS)`}
            </span>
          ),
        });
      }

      setInstallmentsArr(options);
    }
  }, [plan?.summary?.total?.netPriceWithCouponDiscount]);
  // methods -------------------------------------------------------------------
  // const onGenerateOrder = async () => {
  //   setIsGenerating(true);

  //   const result = await appmultiService.generateOrderFromQuotation(
  //     filter,
  //     plan,
  //     travellers,
  //     buyer,
  //     emergencyContacts,
  //     paymentSelected,
  //   );

  //   setOrder(result);
  //   setIsGenerating(false);
  // };

  const onClickPaymentSelected = value => {
    if (value === paymentSelected) return;

    setPaymentSelected(value);
    const summary = appmultiService.calculatePlanValues({
      plan,
      days: filter.days,
      passengersArr: travellers,
      paymentMethod: value,
      creditCardInstallment:
        value === 'credit_card' ? creditCardInstallment : 1,
    });

    setPlan &&
      setPlan(prev => ({
        ...prev,
        summary: { ...summary },
      }));
  };

  const onClickCreditCardInstallment = parc => {
    setCreditCardInstallment(parc);

    const summary = appmultiService.calculatePlanValues({
      plan,
      days: filter.days,
      passengersArr: travellers,
      paymentMethod: paymentSelected,
      creditCardInstallment: parc,
    });

    setPlan &&
      setPlan(prev => ({
        ...prev,
        summary: { ...summary },
      }));
  };

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <div className="w-100">
      <Descriptions bordered  className="w-100 mb-4" />

      <Spin spinning={isGenerating} size="large">
        <Row justify="center" className="w-100">
          {/* BILLING TYPE */}
          <Col xs={24} lg={16} xl={16} className={isLarge ? 'pr-3' : ''}>
            <Card
              title={
                <Flex align="center" style={{ color: 'darkcyan' }}>
                  <div style={{ paddingTop: 4 }}>
                    <Icon type={MdPayment} />
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    {translateX('select_payment_method')}
                  </div>
                </Flex>
              }
              className="w-100"
            >
              <Row justify="space-between">
                {/* bankslip */}
                <Col xs={24} xl={12} xxl={8}>
                  <Row justify="center">
                    <Card
                      className="m-3"
                      style={{
                        width: 278,
                        border:
                          paymentSelected === 'slip_pix'
                            ? '2px solid darkcyan'
                            : '',
                        boxShadow:
                          paymentSelected === 'slip_pix'
                            ? '0 4px 8px rgba(0, 0, 0, 0.1)'
                            : '',
                        pointerEvents: isDisabled ? 'none' : 'initial',
                        cursor: isDisabled ? 'default' : 'pointer',
                      }}
                      hoverable={!isDisabled}
                      onClick={() => onClickPaymentSelected('slip_pix')}
                      cover={
                        <img
                          alt="Boleto Bancário"
                          src={bankSlipImg}
                          style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: 56,
                            margin: '28px 112px 0',
                          }}
                        />
                      }
                    >
                      <div
                        className="text-center w-100 font-weight-bold"
                        style={{ fontSize: 14 }}
                      >
                        BOLETO BANCÁRIO
                      </div>

                      <div
                        className="text-center w-100 text-muted"
                        style={{ fontSize: 14, padding: '24px 0 10px' }}
                      >
                        Desconto de 5%
                      </div>
                    </Card>
                  </Row>
                </Col>
                {/* bankslip */}

                {/* credit card */}
                <Col xs={24} xl={12} xxl={8}>
                  <Row justify="center">
                    <Card
                      className="m-3"
                      style={{
                        width: 278,
                        border:
                          paymentSelected === 'credit_card'
                            ? '2px solid darkcyan'
                            : '',
                        boxShadow:
                          paymentSelected === 'credit_card'
                            ? '0 4px 8px rgba(0, 0, 0, 0.1)'
                            : '',
                        pointerEvents: isDisabled ? 'none' : 'initial',
                        cursor: isDisabled ? 'default' : 'pointer',
                      }}
                      hoverable={!isDisabled}
                      onClick={() => onClickPaymentSelected('credit_card')}
                      cover={
                        <img
                          alt="Cartão de Crédito"
                          src={creditCardImg}
                          style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: 56,
                            margin: '28px 112px 0',
                          }}
                        />
                      }
                    >
                      <div
                        className="text-center w-100 font-weight-bold"
                        style={{ fontSize: 14 }}
                      >
                        CARTÃO DE CRÉDITO
                      </div>

                      <Select
                        className="pt-3 ant-select-selector-p8 w-100"
                        size="small"
                        style={{ height: 'unset', fontSize: 14 }}
                        value={creditCardInstallment}
                        onChange={onClickCreditCardInstallment}
                        options={installmentsArr}
                      />
                    </Card>
                  </Row>
                </Col>
                {/* credit card */}

                {/* pix */}
                <Col xs={24} xl={12} xxl={8}>
                  <Row justify="center">
                    <Card
                      className="m-3"
                      style={{
                        width: 278,
                        border:
                          paymentSelected === 'pix' ? '2px solid darkcyan' : '',
                        boxShadow:
                          paymentSelected === 'pix'
                            ? '0 4px 8px rgba(0, 0, 0, 0.1)'
                            : '',
                        pointerEvents: isDisabled ? 'none' : 'initial',
                        cursor: isDisabled ? 'default' : 'pointer',
                      }}
                      hoverable={!isDisabled}
                      onClick={() => onClickPaymentSelected('pix')}
                      cover={
                        <img
                          alt="PIX"
                          src={pixImg}
                          style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: 56,
                            margin: '28px 112px 0',
                          }}
                        />
                      }
                    >
                      <div
                        className="text-center w-100 font-weight-bold"
                        style={{ fontSize: 14 }}
                      >
                        PIX
                      </div>

                      <div
                        className="text-center w-100 text-muted"
                        style={{ fontSize: 14, padding: '24px 0 10px' }}
                      >
                        Desconto de 5%
                      </div>
                    </Card>
                  </Row>
                </Col>
                {/* pix */}
              </Row>
            </Card>
          </Col>
          {/* BILLING TYPE */}

          {/* CHECKOUT */}
          <Col xs={24} lg={8} xl={8}>
            <Card
              title={
                <Flex align="center" style={{ color: 'darkcyan' }}>
                  <div style={{ paddingTop: 0 }}>
                    <Icon type={ShoppingOutlined} />
                  </div>
                  <div style={{ marginLeft: 8 }}>{translateX('checkout')}</div>
                </Flex>
              }
              className="w-100"
            >
              <Row justify="space-between">
                <Col span={24} className="text-right">
                  {/* VALUE */}
                  {/*{plan?.summary?.total?.totalAdjusted !==*/}
                  {/*plan?.summary?.total?.totalToPay ? (*/}
                  {/*  <Flex justify="space-between" align="center">*/}
                  {/*    <div className="text-muted">VALOR</div>*/}
                  {/*    <div className="font-size-md">*/}
                  {/*      {financialService.formatMoney({*/}
                  {/*        value: plan?.summary?.total?.totalAdjusted,*/}
                  {/*        localeSymbol: 'brl',*/}
                  {/*        resultToStr: true,*/}
                  {/*      })}*/}
                  {/*    </div>*/}
                  {/*  </Flex>*/}
                  {/*) : null}*/}
                  {/* VALUE */}

                  {/* INSERT COUPOM */}
                  {/*<Flex justify="space-between" align="center" className="mt-2">*/}
                  {/*  <div className="text-muted">*/}
                  {/*    <Button*/}
                  {/*      type="link"*/}
                  {/*      onClick={onClickInsertCoupom}*/}
                  {/*      className="pl-0 pr-0"*/}
                  {/*    >*/}
                  {/*      INSERIR CUPOM DESCONTO*/}
                  {/*    </Button>*/}
                  {/*  </div>*/}
                  {/*  <div className="font-size-md text-danger">*/}
                  {/*    {financialService.formatMoney({*/}
                  {/*      value:*/}
                  {/*        plan?.summary?.total?.couponDiscount?.discountValue,*/}
                  {/*      localeSymbol: 'brl',*/}
                  {/*      resultToStr: true,*/}
                  {/*    })}*/}
                  {/*  </div>*/}
                  {/*</Flex>*/}
                  {/* INSERT COUPOM */}

                  {/* TOTAL */}
                  <Flex justify="space-between" align="center" className="mt-2">
                    <div className="text-muted">TOTAL</div>
                    <div className="font-size-md">
                      {financialService.formatMoney({
                        value: plan?.summary?.total?.netPriceWithCouponDiscount,
                        localeSymbol: 'brl',
                        resultToStr: true,
                      })}
                    </div>
                  </Flex>
                  {/* TOTAL */}

                  {/* PAYMENT DISCOUNT */}

                  <Flex justify="space-between" align="center" className="mt-2">
                    <div className="text-muted text-uppercase">
                      DESCONTO {translateX(paymentSelected)}{' '}
                      {paymentSelected === 'credit_card' ? '' : '(5%)'}
                    </div>
                    <div className="font-size-md text-danger">
                      -
                      {financialService.formatMoney({
                        value: plan?.summary?.total?.paymentDiscountValue,
                        localeSymbol: 'brl',
                        resultToStr: true,
                      })}
                    </div>
                  </Flex>

                  {/* PAYMENT DISCOUNT */}

                  {/* TOTAL TO PAY */}
                  <Flex justify="space-between" align="center" className="mt-2">
                    <div className="text-muted">TOTAL A PAGAR</div>
                    <div className="font-size-md">
                      {financialService.formatMoney({
                        value: plan?.summary?.total?.totalToPay,
                        localeSymbol: 'brl',
                        resultToStr: true,
                      })}
                    </div>
                  </Flex>
                  {/* TOTAL TO PAY */}

                  {/* INSTALLMENT */}
                  <Flex justify="space-between" align="center" className="mt-2">
                    <div className="text-muted">FORMA DE PAGAMENTO</div>
                    <div className="font-size-md">
                      {!paymentSelected
                        ? 'N/A'
                        : paymentSelected !== 'credit_card'
                        ? translateX(paymentSelected)
                        : creditCardInstallment === '1X'
                        ? 'CARTÃO Á VISTA'
                        : `${creditCardInstallment} ${financialService.formatMoney(
                            plan?.summary?.total?.netPriceWithCouponDiscount /
                              (returnOnlyNumbers(
                                plan?.summary?.total?.paymentInstallment,
                              ) || 1),
                            2,
                            'brl',
                            true,
                          )}`}
                    </div>
                  </Flex>

                  {/*
                      <Flex justify="flex-end" align="center" className="mt-1">
                    <div className="text-muted">
                      PARCELADO EM 10x DE R$ 4,96
                    </div>
                  </Flex>
*/}
                  {/* INSTALLMENT */}
                </Col>
              </Row>
            </Card>
          </Col>
          {/* CHECKOUT */}
        </Row>
      </Spin>

      {/*PREV/NEXT*/}
      {!isDisabled ? (
        <Flex
          justify={isMobile ? 'center' : 'flex-end'}
          align="center"
          className="mt-4"
        >
        </Flex>
      ) : null}
      {/* PREV/NEXT */}
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

};

const CustomerCard = props => {
  // providers context ---------------------------------------------------------
  const { form, elRefs, formRefreshed } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    selectedCustomer,
    setSelectedCustomer,
    context,
    document,
    _id,
    mode,
    isWorking,
  } = props;

  // local variables I ---------------------------------------------------------
  const isMobile = appService.isMobile();
  const isDisabled = isWorking || mode === 'view';

  // component states ----------------------------------------------------------
  const [billingEmailsOptions, setBillingEmailsOptions] = useState([]);
  const [customerType, setCustomerType] = useState('registered_customer');

  // local variables II --------------------------------------------------------
  const customerAdditionalFields = buildCustomerAdditionalFields();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form.setFieldsValue({
      [context]: {
        customerType: 'registered_customer',
      },
    });

    setSelectedCustomer(document?.customer);

    // if (document) {
    //   updateFormAdressState(document[context], context);
    // }
  }, [formRefreshed, document]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onChangeCustomerType = async ev => {
    const value = typeof ev === 'object' ? ev?.target?.value : ev;

    // resetForm();

    // form.setFieldsValue({ [context]: { customerType: value } });
    form.setFieldValue([context], {});

    if (value === 'registered_customer') {
      goFocus(elRefs, 'selectedEntity');
    } else {
      goFocus(elRefs, 'cpfCnpjNewCustomer');
    }

    setCustomerType(value);
    setSelectedCustomer(null);
    setBillingEmailsOptions([]);
  };

  const onCustomerSelect = async value => {
    const doc = await entityService.getEntityById(value.key);

    setSelectedCustomer(doc || null);
    setBillingEmailsOptions(entityService.getEntityAllEmailsArr(doc));

    const body = entityService.decorateEntity(doc, 1, context);

    form.setFieldsValue(body);
    form.validateFields([[context, 'mobile']]);
  };

  const onCpfCnpjChange = async ev => {
    let tagId = ev?.target?.id;
    let fieldName;

    if (tagId) {
      const parts = tagId.split('_');
      fieldName = parts && parts.length > 0 ? parts[parts.length - 1] : null;
    }

    const value = typeof ev === 'object' ? ev?.target?.value : ev;

    const cpfCnpj = returnOnlyNumbers(value);

    if (!isValidCpfCnpj(cpfCnpj)) return;

    const doc = await entityService.getEntityByField(cpfCnpj);

    if (!doc) return true;

    const body = entityService.decorateEntity(doc, 1, context);
    body[context].customerType = 'registered_customer';

    let title = translateX(
      'document_exists',
      ['%CPF_CNPJ%'],
      [doc?.cpf ? 'CPF' : 'CNPJ'],
    );
    const options = {
      Content: translateX(
        'document_exists_description',
        ['%CPF_CNPJ%'],
        [doc?.cpf ? 'CPF' : 'CNPJ'],
      ),
      okText: 'yes',
      cancelText: 'no',

      onCancel: () => {
        form.setFieldValue(
          [context, fieldName],
          selectedCustomer?.cpf || selectedCustomer?.cnpj,
        );
        if (fieldName) goFocus(elRefs, fieldName, 'all');
        return false;
      },

      onOk: () => {
        setCustomerType('registered_customer');
        setBillingEmailsOptions(entityService.getEntityAllEmailsArr(doc));
        setSelectedCustomer(doc);
        // updateFormAdressState(doc, 'customer');

        // setRefreshAddress(+new Date());

        form.setFieldValue([context], body[context]);
        form.validateFields([[context, 'mobile']]);
        goFocus(elRefs, 'selectedEntity');
        return false;
      },
    };

    appService.modal('q', title, options);

    return false;
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Card title={<div className="text-muted">{translateX('customer')}</div>}>
      {!_id ? (
        <Flex justify={isMobile ? 'center' : 'flex-start'} align="center">
          {/* CUSTOMER OPTIONS */}
          <JarvislyRadioButtons
            context={context}
            name="customerType"
            onChange={onChangeCustomerType}
            noLabel
            options={[
              {
                value: 'registered_customer',
                label: translateX('registered_customer'),
              },
              {
                value: 'new_customer',
                label: translateX('new_customer'),
              },
            ]}
          />
        </Flex>
      ) : null}
      {/* CUSTOMER OPTIONS */}

      <Row
        gutter={ROW_GUTTER}
        style={{
          display: customerType === 'registered_customer' ? 'flex' : 'none',
        }}
      >
        {/* CUSTOMER */}
        <Col xs={24} md={16}>
          <JarvislyAsyncAutoComplete
            context={context}
            name="selectedEntity"
            label="drawee"
            required={customerType === 'registered_customer'}
            disabled={
              isDisabled || (document?.status && document.status !== 'new')
            }
            x-data-profile={[
              'customer',
              'provider',
              'collaborator',
              'resident',
              'operator',
            ]}
            x-on-select={onCustomerSelect}
          />
        </Col>
        {/* CUSTOMER */}

        {/* CPF/CNPJ */}
        <Col xs={24} sm={8}>
          <JarvislyCpfCnpj
            context={context}
            disabled={
              isDisabled ||
              !selectedCustomer ||
              (document?.status && document.status !== 'new')
            }
            required={customerType === 'registered_customer'}
            onChange={onCpfCnpjChange}
          />
        </Col>
        {/* CPF/CNPJ */}
      </Row>

      <Row
        gutter={ROW_GUTTER}
        style={{
          display: customerType !== 'registered_customer' ? 'flex' : 'none',
        }}
      >
        <Col xs={24} sm={8}>
          <JarvislyCpfCnpj
            context={context}
            name="cpfCnpjNewCustomer"
            required={customerType !== 'registered_customer'}
            onChange={onCpfCnpjChange}
          />
        </Col>

        <Col xs={24} sm={16}>
          <JarvislyInput
            context={context}
            name="name"
            required={customerType !== 'registered_customer'}
          />
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        {/* MOBILE */}
        <Col xs={24} sm={8}>
          <JarvislyPhone
            context={context}
            name="mobile"
            profile="MOBILE"
            disabled={
              isDisabled ||
              (customerType === 'registered_customer' && !selectedCustomer)
            }
          />
        </Col>
        {/* MOBILE */}

        {/* EMAIL */}
        <Col xs={24} sm={16}>
          {/*<JarvislyEmail name='billingEmails' label='emails' />*/}
          <JarvislyMultiEmails
            context={context}
            name="billingEmails"
            label="billing_emails"
            disabled={
              isDisabled ||
              (customerType === 'registered_customer' && !selectedCustomer)
            }
            options={billingEmailsOptions}
          />
        </Col>
        {/* EMAIL */}
      </Row>

      {/* ADDITIONAL FIELDS */}
      <Collapse
        ghost
        style={{
          display:
            customerType !== 'registered_customer' || selectedCustomer
              ? 'flex'
              : 'none',
        }}
        onChange={keys => {
          if (keys?.includes('additional_fields')) {
            // if (selectedCustomer) {
            // setTimeout(() => {
            // form.validateFields(['mobile']);
            // });
            // }

            goFocus(elRefs, 'phone', 'all');
          }
        }}
        items={customerAdditionalFields}
        className="no-collapse-padding"
      />
      {/* ADDITIONAL FIELDS */}
    </Card>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildCustomerAdditionalFields() {
    return [
      {
        key: 'additional_fields',
        label: `${translateX('additional_info')} (${translateX('optional')})`,
        children: (
          <>
            {/* CONTACTS */}
            <JarvislyContactForm {...props} />
            {/* CONTACTS */}

            {/* ADDRESS */}
            <JarvislyAddressForm {...props} />
            {/* ADDRESS */}

            {/* NOTE */}
            {/*<JarvislyNoteForm*/}
            {/*  {...props}*/}
            {/*  disabled={*/}
            {/*    customerType === 'registered_customer' && !selectedCustomer*/}
            {/*  }*/}
            {/*/>*/}
            {/* NOTE */}
          </>
        ),
      },
    ];
  }
};

const OrdersRecordIndex = props => {
  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {}, []);
  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <CustomerCard {...props} context="customer"/>
      <Card>
      {/* <Row gutter={ROW_GUTTER}>
        <Col xs={24}>
          <JarvislyInput  name={'name'}/>
        </Col>
      </Row> */}
    </Card>
      <Card title={translateX('totalizer')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24}>
            <OrderQuotationsCheckoutStep3 {...props} />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default OrdersRecordIndex;
