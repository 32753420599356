import { Flex, Table } from 'antd';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import { PlusOutlined } from '@ant-design/icons';
import { translateX } from 'utils/helpers';
import appService from 'services/appService';
import axiosService from 'services/axiosService';
import React from 'react';

const modalName = 'BrandsModal';

// COMPONENT *******************************************************************
// *****************************************************************************
const BrandsTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    parameters,
    setShowModalLevel,
    setSelectedRecord,
    refreshParameters,
    isUpdating,
    setIsUpdated
  } = props;

  // local variables I ----------------------------------------------------------
  const productsParameters = parameters?.productsParameters || {};
  const tableColumns = buildTableColumns();

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onAdd = () => {
    setSelectedRecord(null);
    setShowModalLevel(modalName, true);
  };

  const onEdit = record => {
    setSelectedRecord(record);
    setShowModalLevel(modalName, true);
  };

  const onDelete = async record => {
    const axiosOptions = {
      url: `/v1/products/parameters/add-to-set/${productsParameters?._id}/$pull/brands._id`,
      data: {
        _id: record?._id,
      },
    };

    appService.message('s', 'saved_parameters', 'onSave');
    await axiosService.put(axiosOptions);

    setIsUpdated(true);
    refreshParameters(true);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={productsParameters?.brands || []}
        pagination={false}
        disabled={false}
        size="small"
        loading={isUpdating}
      />

      <Flex justify="center">
        <FormButton
          title="brand"
          className="m-3"
          style={{ width: 'fit-content' }}
          icon={<PlusOutlined />}
          disabled={false}
          onClick={onAdd}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        title: translateX('brand'),
        dataIndex: 'label',
        with: 100,
        className: 'text-truncate',
        render: (_, record) => (
          <span className="text-uppercase">{record?.label}</span>
        ),
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              disabled={false}
              deleteTitle={translateX('remove_this_item', ['%ITEM%'], ['item'])}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default BrandsTab;
