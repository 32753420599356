import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import {
  ModuleContext,
  useModuleContext,
} from 'components/jarvisly-module/ModuleContext';
import appService from 'services/appService';
import { useSelector } from 'react-redux';
import app from 'configs/ConfigIndex';
import TileItem from 'components/jarvisly-components/others/TileItem';
import apiConfig from 'auth/FetchInterceptor';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import Loading from '../../../../../components/jarvisly-components/others-old/Loading';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
let tabName = 'organizations';

// COMPONENT *******************************************************************
// *****************************************************************************
const AccountOrganizationsList = props => {
  // providers context ---------------------------------------------------------
  const { isWorking } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const { activeTab } = props;

  // local variables -----------------------------------------------------------
  useTabChild('organizations', null, props);

  const { selectedModule } = useSelector(s => s.moduleSlice);
  const { selectedUser } = useSelector(s => s.authSlice);
  const navigate = useNavigate();
  const location = useLocation();
  const { refreshList, dataList } = useModuleContext();

  // component states ----------------------------------------------------------
  const [list, setList] = useState(dataList);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setList(dataList);
  }, [dataList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    appService.initRoute({
      moduleType: 'list',
      showPageHeader: false,
      showBreadcrumb: false,
      disableSubscriptionCombo: false,
    });

    if (selectedUser?._id) {
      apiConfig.defaults.headers['x-accountid'] = selectedUser._id;
      apiConfig.defaults.headers['x-username'] = selectedUser?.name || null;
      apiConfig.defaults.headers['x-useremail'] = selectedUser?.email || null;
    }

    if (selectedModule?.url === location?.pathname && activeTab === tabName) {
      (async () => await refreshList({ tab: tabName }, tabName))();
      if (location?.state?.qsObj) delete location.state.qsObj;
    }
  }, [selectedModule?.url, activeTab, selectedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onClickNewOrganization = () =>
    navigate(`/app/account/settings/organization/add`);

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Row justify="end">
        <Button
          type="primary"
          className="ml-2 mt-2"
          onClick={onClickNewOrganization}
        >
          <PlusOutlined />
          <span>{translateX(app.PROFILE.NEW_MESSAGE)}</span>
        </Button>
      </Row>

      <Loading
        isFetching={isWorking}
        data={list}
        marginTop={-200}
        // fullscreen={fullscreen}
        // ignoreNoData={ignoreNoData}
      />

      <div className={`my-4 container-fluid`}>
        {
          <Row gutter={ROW_GUTTER}>
            {list?.map(record => (
              <Col xs={24} md={12} xl={8} key={record._id}>
                <TileItem record={record} hoverable strongBorder />
              </Col>
            ))}
          </Row>
        }
      </div>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT **********************************************************************
// *****************************************************************************

export default AccountOrganizationsList;
