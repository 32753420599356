import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';
import publicRoutes from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import AppRoute from './AppRoute';
import PublicRoute from './PublicRoute';
import NoRouteSet from 'components/jarvisly-components/others-old/NoRouteSet';
import WelcomeIndex from 'modules/app-views/home/WelcomeIndex';
import FormIndex from 'components/jarvisly-module/Form/FormIndex';
import app from 'configs/ConfigIndex';
import {
  ACCOUNTS_ORGANIZATIONS_ROUTE,
  ACCOUNTS_ROUTE,
} from '../modules/app-views/account/account';
import AccountIndex from '../modules/app-views/account/AccountIndex';
import { useEffect } from 'react';
import { rdxSetAvailableRoutes } from '../store/slices/authSlice';
import { useDispatch } from 'react-redux';

const coreRoutes = {
  'fallback.noRoute': NoRouteSet,
  '/app/home/welcome': WelcomeIndex,

  // user account
  [`${ACCOUNTS_ORGANIZATIONS_ROUTE}/:_id`]: FormIndex,
  [ACCOUNTS_ROUTE]: AccountIndex,
};

// update app routes
app.appRoutes = { ...coreRoutes, ...app.appRoutes };

const Routes = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!app.availableRoutes) return;
    const ar = Object.keys(app.appRoutes);
    dispatch(rdxSetAvailableRoutes(ar));
  }, [app?.availableRoutes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RouterRoutes>
      {/* PROTECTED ROUTES */}
      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<Navigate replace to={app.AUTHENTICATED_ENTRY} />}
        />

        {Object.keys(app.appRoutes).map(key => {
          return (
            <Route
              key={key}
              path={key}
              element={
                <AppRoute
                  {...props}
                  routeKey={key}
                  component={
                    app.appRoutes?.[key] || app.appRoutes['fallback.noRoute']
                  }
                />
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      {/* PROTECTED ROUTES */}

      {/* PUBLIC ROUTES */}
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map(route => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
      {/* PUBLIC ROUTES */}
    </RouterRoutes>
  );
};

export default Routes;
