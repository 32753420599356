import { forwardRef, useContext, useEffect } from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import appService from 'services/appService';
import { Col, ColorPicker, Form, Modal, Row } from 'antd';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { TagOutlined } from '@ant-design/icons';
import iotDevicesService from '../../iotDevicesService';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showStockModal,
    setShowStockModal,
    setSelectedRecord,
    selectedRecord,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = !selectedRecord?._id
      ? translateX('new_stock')
      : translateX('edit_stock');

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (selectedRecord && showStockModal) {
      form?.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord, showStockModal, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowStockModal(false);
  };

  const afterClose = () => {
    form.resetFields();
    setSelectedRecord(null);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showStockModal}
        // width={640}
        okText={translateX('save')}
        onCancel={onClose}
        cancelText={translateX('close')}
        // okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => form.submit()}
        getContainer={false}
        okType={'primary'}
        // confirmLoading={isWorking}
        afterClose={afterClose}
      >
        <Row gutter={ROW_GUTTER}>
          {/* NAME */}
          <Col xs={20}>
            <JarvislyInput name="label" label="name" disabled={false} />
          </Col>
          {/* NAME */}
          {/* NAME */}
          <Col xs={2}>
            <Form.Item
              name="backColor"
              // style={{ width: '0%', marginTop: '25px' }}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '25px',
              }}
              required={true}
            >
              <ColorPicker
                value={form?.getFieldValue('backColor')}
                onChange={backColor => form.setFieldsValue({ backColor })}
                defaultFormat="hex"
                size="small"
              />
            </Form.Item>
          </Col>
          {/* NAME */}
        </Row>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const StocksModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    parameters,
    setShowStockModal,
    selectedRecord,
    refreshParameters,
  } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onConfirm = async ({ body }) => {

    await iotDevicesService.pushAddToSet({
      data: body,
      selectedRecord,
      path: "stocks"
    });
    // const axiosOptions = {
    //   url: `/v1/iot-device-parameters/add-to-set/${parameters?.categoriesParameters?._id}/$push/stocks`,
    //   data: body,
    // };

    // appService.message('s', 'saved_parameters', 'onSave');
    // await axiosService.put(axiosOptions);

    setShowStockModal(false);
    refreshParameters(true);

    return body;
  };

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>

      <JarvislyFormProvider
        document={selectedRecord}
        requiredFields={['label']}
        showSubmitButton={false}
        onConfirm={onConfirm}
        autoFocus="label"
        abortComponentSave={true}
      >
        <ModalComponent {...props} />
      </JarvislyFormProvider>

    </>
  );
});

export default StocksModal;
