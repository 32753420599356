import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Badge, Button, Table, Tooltip } from 'antd';
import { setLocale, translateWord, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import React, { useState } from 'react';
import ListPreview from 'components/jarvisly-module/List/ListPreview';
import APPVEERTICAL_IOTDEVICES_MODULE from '../iotDevices';
import { DeviceTag, DocumentConditionTag, IoTDistance } from '../Components';
import appveerticalService from 'services/appveerticalService';
import { useSelector } from 'react-redux';

const IotDevicesIndex = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    onClickOpen,
    numberLineColumn,
  } = props;

  // local variables I ---------------------------------------------------------
  const { selectedSubscription } = useSelector(s => s.authSlice);
  const subscriptionLevel = selectedSubscription?._metadata?.level;

  const statusFilter = buildDocumentStatusFilter();
  // const locationFilter = buildDocumentLocationFilter();
  const typeFilter = buildDocumentTypeFilter();
  const deviceFilter = buildDocumentDeviceFilter();
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------
  const [selectedItem, setSelectedItem] = useState();
  const [showPreviewDrawer, setShowPreviewDrawer] = useState(false);
  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onClickPreview = record => {
    setSelectedItem(record);
    setShowPreviewDrawer(true);
  };

  const close = () => {
    setShowPreviewDrawer(false);
  };
  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* PREVIEW LEFT DRAWER */}
      <ListPreview
        close={close}
        visible={showPreviewDrawer}
        record={selectedItem}

        // showDrawer={showPreviewDrawer}
        // setShowDrawer={setShowPreviewDrawer}
        // _module={_module}
        // rdxModuleSettings={rdxModuleSettings}
      />

      {/* PREVIEW LEFT DRAWER */}
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
        size="small"
        expandable={
          subscriptionLevel <= 1
            ? {
                expandedRowRender: buildTableItemsColumns,
                defaultExpandedRowKeys: ['0'],
              }
            : false
        }
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      numberLineColumn,

      {
        title: translateX('device'),
        dataIndex: 'title',
        ...columnSearch('title'),
        ...columnSorter('title', deviceFilter),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                shape="square"
                imageUrl={appveerticalService.getDevicePicture(record?.type)}
                style={{ padding: 14 }}
                title={
                  <Badge
                    className="badge-size-12"
                    color={record?.color || 'gray'}
                    text={record?.title}
                  />
                }
                // subtitle={record?.__customer?.name}
                subtitleClassName="text-uppercase text-muted font-weight-bold"
                backColor="#ecf2fe"
              />
            </div>
          );
        },
      },
      {
        title: translateX('channel'),
        dataIndex: 'channel',
        ...columnSorter('channel'),
        ...columnSearch('channel'),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <>
              <div className="text-uppercase">{record?.channel}</div>
            </>
          );
        },
      },

      {
        title: translateX('type'),
        dataIndex: 'type',
        ...columnSorter('type'),
        // ...columnSearch('type'),
        ...columnFilters('type', typeFilter),
        className: 'text-truncate',
        render: (_, record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DeviceTag record={record} uppercase />
            {/*<IotDevicesModelIcons document={record} />*/}
          </div>
        ),

        // render: (_, record) => (
        //   <>
        //     <div className="text-center">
        //       <DeviceTag record={record} uppercase />
        //     </div>
        //     <div className="text-center text-uppercase font-size-sm font-weight-bold text-muted">
        //       {translateX(record?.model)}
        //     </div>
        //   </>
        // ),
      },
      /*      {
        title: translateX('location'),
        dataIndex: 'location',
        ...columnSorter('location'),
        ...columnFilters('location', locationFilter),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <>
              {/!* <Tag
            className="text-capitalize"
            style={{ margin: 4 }}
            color={record?.__locationColor || 'yellow'}
          >
            {record?.__locationName}
          </Tag> *!/}
              <div className="text-uppercase">{record?.__locationName}</div>
            </>
          );
        },
      },*/
      {
        title: translateX('status'),
        dataIndex: 'status',
        ...columnSorter('status'),
        ...columnFilters('status', statusFilter),
        render: (_, record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DocumentStatusTag record={record} />
            {/*<IotDevicesConnectedIcons document={record} />*/}
          </div>
        ),
      },
      {
        title: translateX('condition'),
        dataIndex: 'condition',
        ...columnSorter('condition'),
        ...columnFilters('condition', statusFilter),
        render: (_, record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DocumentConditionTag record={record} className="custom-class" />
            {/*<IotDevicesConnectedIcons document={record} />*/}
          </div>
        ),
      },

      /*
            {
              title: translateX('info'),
              dataIndex: 'info',
              render: (_, record) => <IotDevicesInfoIcons document={record} />,
            },
      */

      {
        title: translateX('last_reading'),
        dataIndex: 'lastReadAt',
        ...columnSorter('lastReadAt'),
        hidden: false,
        render: (_, record) => (
          <>
            <div>
              {record?.lastReadAt
                ? dayjs(record.lastReadAt).fromNow()
                : translateX('n_a')}
            </div>
            <div>
              <IoTDistance data={record} />
            </div>
          </>
        ),
      },

      {
        title: setLocale('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }

  function buildTableItemsColumns(record) {
    const dataItem = dataList.filter(i => i?._id === record?._id);

    const columns = [
      {
        title: translateX('code'),
        dataIndex: 'code',
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {record?._metadata?.__documentCode}
          </span>
        ),
      },

      {
        title: translateWord('customer'),
        dataIndex: 'customer',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <>
              <div>{record?.__customer?.name || 'n/a'}</div>
              {/*<div className="text-muted">{record?.__operator?.name}</div>*/}
            </>
          );
        },
      },

      {
        title: setLocale('sales_representative'),
        dataIndex: 'representative',
        render: (_, record) => <span>{record?.__partner?.name || 'n/a'}</span>,
      },
    ].filter(x => !x.hidden);

    return (
      <Table
        rowKey="_id"
        size="small"
        className="no-table-overflow"
        columns={columns}
        dataSource={dataItem || []}
        pagination={false}
      />
    );
  }

  function buildDocumentStatusFilter() {
    const options = [
      // 'not_updated',
      // 'pending_update',
      // 'waiting_for_update',
      // 'updated',
      'online',
      'offline',
      'calibrating',
    ];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildDocumentTypeFilter() {
    const options = ['temperature_sensor', 'water_level_sensor'];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildDocumentDeviceFilter() {
    const options = [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }
};

export default IotDevicesIndex;
