import { useContext, useEffect, useState } from 'react';
import { translateX } from 'utils/helpers';
import { Modal, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import { ParametersContext } from 'components/jarvisly-module/ParametersProvider';
import UnitsTab from './UnitsTab/UnitsTab';
import UnitsModal from './UnitsTab/UnitsModal';
import { JarvislyFormProvider } from 'components/jarvisly-components/others/FormComponents';
import ServicesParametersRequiredFields from './RequiredFields/ServicesParametersRequiredFields';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    showParametersModal,
    setShowParametersModal,
    selectedRecord,
    setSelectedRecord,
    isUpdated,
    setIsUpdated,
    selectedModule,
    parameters,
    moduleType,
    refreshList,
  } = props;

  // local variables I ----------------------------------------------------------
  const initTab = moduleType === 'document' ? 'formSettings' : 'units';

  // component states -----------------------------------------------------------
  const [tabKey, setTabKey] = useState(initTab);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!showParametersModal) return;
    setTabKey(initTab);
  }, [showParametersModal]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowParametersModal(false);
  };

  function onTabClick(key) {
    setTabKey(key);

    switch (key) {
      default:
        break;
    }
  }

  // local variables III --------------------------------------------------------
  const childProps = {
    ...props,
    isDisabled: false,
    isUpdated,

    parameters,
    setIsUpdated,
    selectedRecord,
    setSelectedRecord,
  };

  const tabItems = buildTabItems();

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        title={translateX('parameters')}
        destroyOnClose={true}
        open={showParametersModal}
        width={640}
        onCancel={onClose}
        cancelText={translateX('close')}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={onClose}
        getContainer={false}
        okType={'default'}
        afterClose={() => {
          if (isUpdated && moduleType === 'list') refreshList();
          setIsUpdated(false);
        }}
      >
        <Tabs
          onChange={onTabClick}
          items={tabItems?.filter(t => !t.hide)}
          activeKey={tabKey}
        />
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItems() {
    return [
      {
        label: translateX('form'),
        key: 'formSettings',
        hide: moduleType !== 'document',
        children: (
          <ServicesParametersRequiredFields
            {...childProps}
            moduleName={selectedModule?.id}
          />
        ),
      },

      {
        label: translateX('units'),
        key: 'units',
        hide: false,
        children: <UnitsTab {...childProps} />,
      },

      // {
      //   label: translateX('brands'),
      //   key: 'brands',
      //   hide: false,
      //   children: <BrandsTab {...childProps} />,
      // },

      // {
      //   label: translateX('stocks'),
      //   key: 'stocks',
      //   hide: false,
      //   children: <StocksTab {...childProps} />,
      // },
    ];
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const ServicesParametersModal = props => {
  // providers context ---------------------------------------------------------
  const { moduleType } = useSelector(s => s.moduleSlice);
  const { refreshParameters, refreshList } = useContext(ModuleContext);
  const {
    selectedRecord,
    setSelectedRecord,
    showModalLevel,
    setShowModalLevel,
    isUpdated,
    setIsUpdated,
  } = useContext(ParametersContext);

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states ----------------------------------------------------------
  const [isUpdating, setIsUpdating] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // variables III -------------------------------------------------------------
  const childProps = {
    ...props,
    selectedRecord,
    setSelectedRecord,
    isUpdating,
    setIsUpdating,
    showModalLevel,
    setShowModalLevel,
    parameters,
    refreshParameters,
    refreshList,
    isUpdated,
    setIsUpdated,
    moduleType,
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
      // document={document}
      // onConfirm={onConfirm}
      // onValuesChange={(v, allValues) => setFormData({ ...allValues })}
      >
        <ModalComponent {...childProps} />
      </JarvislyFormProvider>

      {/* UNITS MODAL WRAPPER */}
      <UnitsModal {...childProps} />
      {/* UNITS MODAL WRAPPER */}

      {/* BRANDS MODAL WRAPPER */}
      {/* <BrandsModal {...childProps} /> */}
      {/* BRANDS MODAL WRAPPER */}

      {/* STOCKS MODAL WRAPPER */}
      {/*<StocksModal {...childProps} />*/}
      {/* STOCKS MODAL WRAPPER */}
    </>
  );
};

export default ServicesParametersModal;
