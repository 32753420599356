import axiosService from './axiosService';
import { MULTI_ORDERS_API } from '../modules/app-views/_apps/appmulti/orders/orders';
import appService from './appService';
import { translateX } from '../utils/helpers';

const orderService = {};

orderService.getOrderById = async (_id, projection) => {
  let url = MULTI_ORDERS_API;

  if (projection) url += `/${projection}`;

  const axiosOptions = { url, _id };
  const [doc] = await axiosService.get(axiosOptions);
  return doc;
};

orderService.sendEmail = async (
  _id,
  action,
  emailTo = null,
  ignoreResult = false,
) => {
  if (emailTo) {
    const sendingEmailTo = translateX(
      'sending_email_to',
      ['%EMAIL%'],
      [emailTo],
    );

    appService.message('i', sendingEmailTo, 'sendingKey', 5);
  }

  const axiosOptions = {
    url: `${MULTI_ORDERS_API}/send-order-email`,
    data: { _id, action },
    headers: { 'x-ignore-result': ignoreResult },
  };

  try {
    const [doc] = await axiosService.post(axiosOptions);
    return doc;
  } catch (error) {
    console.error('sending email to buyer', error);
    return false;
  }
};

orderService.finishOrder = document => {
  return new Promise((resolve, reject) => {
    const travellers = document?.travellers || [];
    const idx = travellers.findIndex(t => !t?.voucherUrl);

    if (idx > -1) {
      appService.notification(
        'w',
        'finish_order_failure',
        'finish',
        'finish_order_failure_missing_vouchers',
        6,
      );

      return reject();
    } else {
      appService.modal('q', 'finish_order', {
        Content: 'finish_order_description',
        onCancel: () => {
          return reject();
        },
        onOk: async () => {
          // update order finishing setting to 'done'
          let axiosOptions = {
            url: MULTI_ORDERS_API,
            _id: document?._id,
            data: {
              status: 'done',
            },
          };
          axiosService
            .put(axiosOptions)
            .then()
            .catch(error => console.error('update order to done', error));

          // send email to buyer
          orderService
            .sendEmail(document?._id, 'done', document?.__buyer?.email, true)
            .then();

          return resolve();
        },
      });
    }
  });
};

export default orderService;
