import MultiLeadsList from './List/List';
import MultiLeadsForm from './Form/Form';
import { DefaultFormHeaderColumn1 } from 'components/jarvisly-module/Form/FormHeader';
import {
  MultiLeadsFormHeaderColumn2,
  MultiLeadsFormHeaderColumn3,
} from './Components';
import dayjs from 'dayjs';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MODULE_ID = 'appmultiLeads';
const MODULE_API = 'v1/lead';

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const MULTI_LEADS_MODULE = {
  id: MODULE_ID,
  noBlockScreenWithAppNotConfigured: false,
  api: MODULE_API,
  urlCustomForm: null,
  defaultUrlQueryString: null,

  components: {
    // list
    dashboard: null,
    list: props => <MultiLeadsList {...props} />,
    grid: null,
    kanban: null,
    filter: null,

    // form
    form: (props, ref) => <MultiLeadsForm {...props} ref={ref} />,
    formHeaderTitle: null,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: props => <MultiLeadsFormHeaderColumn2 {...props} />,
    formHeaderColumn3: props => <MultiLeadsFormHeaderColumn3 {...props} />,
    formHeaderTags: null,
    formHeaderCustomIcons: null,

    // module Parameters
    parameters: null,

    // module data import
    dataImport: null,

    // data preview
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
    // canEditDocument
    // decorateParameters,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],
    /*

        filter: {
          dataType: ['person', 'company'],
        },

        filterOptions: {
          dataType: ['person', 'company'],
        },
    */

    pagination: {
      pageNumber: 1, // current page
      pageSize: ['10', '20', '50', '100'].includes(
        localStorage.getItem('pageSize'),
      )
        ? Number(localStorage.getItem('pageSize'))
        : 10,
    },

    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },
    /*
        sorter: {
          '_metadata.audit.updatedAt': -1,
        },

        sorterOptions: ['name', '_metadata.audit.updatedAt', 'destiny', 'status'],

        search: {},

        textSearch: {},*/
  },

  listState: {
    override: false,
    hideBreadcrumb: false,

    hideTitleSummary: false,
    hasFilter: false, // show the filter (folders and customs) drawer button
    hasNewOptionsButtons: true, // show options action button. p.s. if not exists 'new' action in documentState.actions, the new button will be hidden

    actions: ['refreshList', 'clearSearch', 'showLineNumbers'],

    showParameters: false,
    showDataImport: false,

    initTab: null,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
    fieldsForRegex: [],
    textSearchInDrawer: true, // inform that the textSearch field is into Drawer Filter to mark the filter button as danger (red)
    refreshDataOfList: 0,

    // sort options
    sorterInitialize: {
      '_metadata.audit.updatedAt': -1,
    },
    sorterOrderPrecedence: [
      'name',
      '_metadata.audit.updatedAt',
      'destiny',
      'status',
    ],
  },

  documentState: {
    showParameters: false,

    disableLockForEdit: false,
    initTab: 'quotations',
    fieldName: 'name',
    newDocumentTitle: 'new_lead',
    autoFill: false,
    actions: [
      // 'new',
      // 'edit',
      'reload',
      // 'divider',
      // 'duplicate',
      'file',
      'remove',
      'permanently_remove',
      // 'divider',
      // 'document_status',
    ],
    refreshParametersOnSave: true,
    indexes: [],

    refreshDataOfDocument: 0,
    mockDocument: {},
  },
};

export default MULTI_LEADS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  if (doc?.followUp && Array.isArray(doc.followUp)) {
    doc.followUp = doc.followUp.map(f => {
      f.label = (
        <>
          <div>
            Data de criação:
            {dayjs(f.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </div>
        </>
      );
      f.children = (
        <>
          <div>Comentario: {f.text}</div>
        </>
      );
      return f;
    });
  }
  // arrival: '2024-12-17T03:00:00.000Z';
  // createdAt: '2024-11-28T16:27:55.815Z';
  // departure: '2024-11-28T03:00:00.000Z';

  // if (doc?.quotations && Array.isArray(doc.quotations)) {
  //   doc.quotations = doc.quotations.map(f => {
  //     if (f?.arrival) doc.arrival = dayjs(doc.arrival);
  //   });
  // }
}
