import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { parsePhoneToDisplay, setLocale, translateX } from 'utils/helpers';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import Icon from 'components/util-components/Icon';
import { FaPlaneArrival, FaPlaneDeparture } from 'react-icons/fa';
import appmultiService from 'modules/app-views/_apps/appmulti/appmultiService';
import {
  MdBoy,
  MdChildFriendly,
  MdDirectionsWalk,
  MdOutlineElderly,
} from 'react-icons/md';
import dayjs from 'dayjs';

const MultiLeadsList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,

    numberLineColumn,
    lastUpdatedColumn,
    actionsButtonColumn,

  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const destinyFilter = buildDestinyFilter();
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      numberLineColumn(),

      {
        title: setLocale('name'),
        dataIndex: 'name',
        ...columnSorter('name'),
        ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                title={record?.name}
                subtitle={record?.email}
                subtitle2={parsePhoneToDisplay(record?.phone)}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },

      // {
      //   title: setLocale('phone'),
      //   dataIndex: 'phone',
      //   width: 180,
      //   render: (_, record) => parsePhoneToDisplay(record?.phone),
      // },

      {
        title: setLocale('destiny'),
        dataIndex: 'destiny',
        ...columnFilters('destiny', destinyFilter),
        width: 200,
        render: (_, record) => (
          <>
            <div style={{ display: 'flex' }}>
              <strong>{translateX(record?.quotations?.[0]?.destiny)}</strong>
            </div>

            <div style={{ display: 'flex' }}>
              <div>
                {/* {record?.quotations?.[0]?.departure} */}
                {dayjs(record?.quotations?.[0]?.departure).format('DD/MM/YYYY')}
              </div>
              <div style={{ marginTop: 2 }}>
                <Icon type={FaPlaneDeparture} className="ml-2" />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div>
                {dayjs(record?.quotations?.[0]?.arrival).format('DD/MM/YYYY')}
              </div>
              <div style={{ marginTop: 2 }}>
                <Icon type={FaPlaneArrival} className="ml-2" />
              </div>
            </div>
          </>
        ),
      },

      {
        title: setLocale('passengers'),
        dataIndex: 'passengers',
        render: (_, record) => (
          <div className="text-center" style={{ display: 'flex' }}>
            {record?.quotations?.[0]?.passengers?.map((age, idx) => (
              <Tooltip
                key={idx}
                title={translateX(
                  'age_year_old',
                  ['%AGE%'],
                  [age?.toString() ? age.toString() : 0],
                )}
              >
                <div style={{ marginRight: '8px' }}>
                  <Icon
                    type={
                      age >= 60
                        ? MdOutlineElderly
                        : age >= 18
                          ? MdDirectionsWalk
                          : age >= 10
                            ? MdBoy
                            : MdChildFriendly
                    }
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        ),
      },

      lastUpdatedColumn(),
      actionsButtonColumn(),

    ].filter(x => !x.hidden);
  }

  function buildDestinyFilter() {
    const destinations = appmultiService.getDestinations();

    return destinations?.map(d => {
      return {
        value: d.value,
        text: d.label,
      };
    });
  }
};

export default MultiLeadsList;
