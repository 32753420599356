// COMPONENT *******************************************************************
// *****************************************************************************
import { Flex, Table } from 'antd';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
  JarvislyTag,
} from 'components/jarvisly-components/others/FormComponents';
import { PlusOutlined } from '@ant-design/icons';
import { translateWord, translateX } from 'utils/helpers';
import productsService from '../../productsService';

const modalName = 'UnitsModal';

const UnitsTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    parameters,
    setShowModalLevel,
    setSelectedRecord,
    refreshParameters,
    isUpdating,
    setIsUpdated,
  } = props;

  // local variables I ----------------------------------------------------------
  const productsParameters = parameters?.productsParameters || {};
  const tableColumns = buildTableColumns();

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onAdd = () => {
    setSelectedRecord(null);
    setShowModalLevel(modalName, true);
  };

  const onEdit = record => {
    setSelectedRecord(record);
    setShowModalLevel(modalName, true);
  };

  const onDelete = async record => {
    console.log(record)
    await productsService.removeUnits(record);
    setIsUpdated(true);
    refreshParameters(true);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={productsParameters?.units || []}
        pagination={false}
        disabled={false}
        size="small"
        loading={isUpdating}
      />

      <Flex justify="center">
        <FormButton
          title="unit_of_measurement"
          className="m-3"
          style={{ width: 'fit-content' }}
          icon={<PlusOutlined />}
          disabled={false}
          onClick={onAdd}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        title: translateX('unit'),
        dataIndex: 'label',
        with: 100,
        className: 'text-truncate',
        render: (_, record) => (
          <JarvislyTag
            key={record._id}
            backColor={record?.backColor}
            foreColor={record?.foreColor}
            label={record?.label}
          />
        ),
      },

      {
        title: translateWord('description'),
        dataIndex: 'description',
        render: (_, record) => <span>{record?.description}</span>,
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              disabled={false}
              deleteTitle={translateX('remove_this_item', ['%ITEM%'], ['item'])}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default UnitsTab;
