import React, { lazy, memo, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import Loading from 'components/shared-components/Loading';
import { darkTheme, lightTheme } from 'configs/InitializeApp';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import Routes from 'routes/RoutesIndex';
import { fetchQuote } from 'store/slices/themeSlice';
import ModuleBlocked from 'components/jarvisly-module/ModuleBlocked';
import authService from 'services/authService';
import { WebSocketProvider } from 'components/jarvisly-components/others/WebSocketContext';
import appmultiService from 'modules/app-views/_apps/appmulti/appmultiService';
import app from 'configs/ConfigIndex';

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
  const dispatch = useDispatch();

  const { token, selectedUser } = useSelector(state => state.authSlice);

  const { blankLayout, locale, direction, currentTheme, quote } = useSelector(
    state => state.theme,
  );

  const Layout = token && !blankLayout ? AppLayout : AuthLayout;

  const currentAppLocale = resources[locale || 'pt'];

  useBodyClass(`dir-${direction}`);

  const themeConfig =
    currentTheme === 'light' ? { ...lightTheme } : { ...darkTheme };

  useEffect(() => {
    if (!quote) dispatch(fetchQuote());
    if (token && !selectedUser) {
      authService.refreshAccount();
    }

    // load all Parameters (after application loaded)
    (async () => {
      if (app.ID === 'appmulti') await appmultiService.loadParameters();
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale.antd}
    >
      <Suspense>
        <Loading cover="page" />
        <ModuleBlocked cover="page" forceShow={true} />

        <WebSocketProvider>
          <Layout>
            <Routes />
          </Layout>
        </WebSocketProvider>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
