import { toCapitalCase, translateX } from 'utils/helpers';
import { Avatar, Button, Col, Row } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import AppDedaloAgendasSettingsAddModal from './SettingsHolidaysAddModal';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const AppdedaloAgendasSettingsHollydaysTab = () => {
  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);

  console.log('Parameters -->', parameters);
  const blocks = parameters?.blocks;
  const agendasAddModalRef = useRef(null);

  const childProps = {
    showAgendasAddModal: agendasAddModalRef?.current?.showModal,
    setShowAgendasAddModal: agendasAddModalRef?.current?.setShowModal,
  };

  // component states ----------------------------------------------------------
  const [showRemove, setShowRemove] = useState(null);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onClickAdd = () => {
    childProps?.setShowAgendasAddModal &&
      childProps?.setShowAgendasAddModal(true);
  };

  const decorateDate = b => {
    const fDate = dayjs(b.dateFrom).format('DD/MM/YY');
    const tDate = dayjs(b.dateTo).format('DD/MM/YY');

    const fHour = dayjs(b.dateFrom).format('LT');
    const tHour = dayjs(b.dateTo).format('LT');

    const fFull = dayjs(b.dateFrom).format('DD/MM/YY LT');
    const tFull = dayjs(b.dateTo).format('DD/MM/YY LT');

    if (b.betweenDates) {
      if (b.allDay) {
        if (fDate === tDate) {
          return fDate;
        } else {
          return `${fDate} á ${tDate}`;
        }
      } else {
        if (fDate === tDate) {
          return `${fDate} das ${fHour} ás ${tHour}`;
        } else {
          return `${fFull} á ${tFull}`;
        }
      }
    } else {
      return fDate;
    }
  };

  /*
  const onDelete = day => {
    const onOk = async () => {
      // setIsUpdating(true);
      // msg('l', 'Removendo Data...', 'removing');
      await removeHoliday(day._id);
      // message.destroy('removing');
      msg('s', 'Data removida com sucesso!');
      // onClose();
      refreshData();
    };

    const options = {
      type: 'confirm', // title: 'Remover Documento',
      title: dayjs(day.dateFrom).format('L'),
      message: `Tem realmente certeza que deseja excluir permanentemente esta data desbloqueando o agendamento neste período?`,
      okText: 'Sim',
      cancelText: 'Não',
      onOk: onOk,
    };

    modal(options);
  };
*/

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* BANKS ADD MODAL WRAPPER */}
      {/*<AppDedaloAgendasSettingsAddModal {...banksTabProps} ref={bankAddModalRef} />*/}
      <AppDedaloAgendasSettingsAddModal
        {...childProps}
        ref={agendasAddModalRef}
      />
      {/* BANKS ADD MODAL WRAPPER */}

      <Row>
        <Button
          type="dashed"
          onClick={onClickAdd}
          block
          icon={<PlusOutlined />}
        >
          &nbsp;{translateX('add')}
        </Button>
      </Row>

      <Row>
        {blocks?.map(d => (
          <div key={d.month} className="mb-4">
            <h4 className="text-muted">
              {toCapitalCase(dayjs(d.month).format('MMMM, YYYY'))}
            </h4>

            {d.blocks.map(b => (
              <Row
                key={b._id}
                justify="space-between"
                onMouseOver={() => setShowRemove(b._id)}
                onMouseLeave={() => setShowRemove(null)}
              >
                <Row>
                  <Col className="mr-2">
                    <Avatar
                      src={`/img/calendar-days/day-${dayjs(b.dateFrom).format(
                        'DD',
                      )}.png`}
                    ></Avatar>
                  </Col>

                  <Col>
                    <strong
                      style={{ marginTop: -4, maxWidth: 220 }}
                      className="text-truncate"
                    >
                      {decorateDate(b)}
                    </strong>
                    <br />

                    <p
                      style={{ marginTop: -4, maxWidth: 220 }}
                      className="text-truncate"
                    >
                      {toCapitalCase(b.description)}
                    </p>
                  </Col>
                </Row>

                <Col>
                  {showRemove === b._id && (
                    <Button
                      type="default"
                      size="small"
                      // onClick={() => onDelete(b)}
                      icon={<CloseOutlined />}
                    />
                  )}
                </Col>
              </Row>
            ))}
          </div>
        ))}
      </Row>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default AppdedaloAgendasSettingsHollydaysTab;
