import { EditOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Flex, Table, Tooltip } from 'antd';
import {
  parseCnpjToDisplay,
  parseDateToDisplay,
  setLocale,
  translateWord,
  translateX,
} from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import accounting from 'accounting';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import Icon from 'components/util-components/Icon';
import { DefaultApiIcon } from 'components/jarvisly-module/Form/FormHeader';

const MultiOperatorsList = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,

    numberLineColumn,
    documentCodeColumn,
    documentStatusColumn,
    lastUpdatedColumn,
    actionsButtonColumn,
  } = props;

  // local variables I ---------------------------------------------------------
  // const statusFilter = buildDocumentStatusFilter();
  // const apiIntegrationFilter = buildApiIntegrationFilter();
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
/*
  function buildDocumentStatusFilter() {
    const options = [
      // 'not_updated',
      // 'pending_update',
      // 'waiting_for_update',
      // 'updated',
      'in_analysis',
      'with_reservation',
      'revised',
    ];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }
*/

  function buildTableColumns() {
    return [
      numberLineColumn(),
      documentCodeColumn(),

      {
        title: translateWord('name'),
        dataIndex: 'name',
        ...columnSorter('name'),
        ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                imageUrl={record?.pictureUrl}
                title={record?.name}
                subtitle={parseCnpjToDisplay(record?.cnpj)}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },

      {
        title: translateWord('quotation'),
        dataIndex: 'Parameters.__exchangeRate',
        // ...columnSorter('Parameters.__exchangeRate'),
        width: 120,
        render: (_, record) => (
          <div className="text-center">
            {record?.parameters?.__exchangeRate &&
            !record?.parameters?.__hideExchangeOnList ? (
              <Flex justify="flex-end" align="start" style={{ width: 100 }}>
                <Tooltip title={translateWord(record?.parameters.__tooltip)}>
                  {accounting.formatMoney(
                    record.parameters.__exchangeRate,
                    `${record?.parameters?.__symbol} `,
                    2,
                    '.',
                    ',',
                  )}
                </Tooltip>

                {record.parameters.__changedByApi ? (
                  <Tooltip
                    title={translateX(
                      'data_changed_by_api',
                      ['%DATE%'],
                      [
                        parseDateToDisplay(
                          dayjs(record?.parameters?.__changedAt),
                          true,
                          true,
                        ),
                      ],
                    )}
                  >
                    <div className="mt-1" style={{ width: 21 }}>
                      <Icon
                        type={ThunderboltOutlined}
                        className="text-primary font-size-md ml-1"
                        style={{ flexShrink: 0 }}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <div className="mt-1" style={{ width: 21 }} />
                )}
              </Flex>
            ) : (
              ''
            )}
          </div>
        ),
      },

      {
        title: translateWord('api'),
        dataIndex: '_integrations.operatorCode',
        // ...columnFilters('Parameters.integrated', apiIntegrationFilter),
        ...columnSorter('_integrations.operatorCode'),
        width: 70,
        render: (_, record) => (
          <div className="text-center">
            <DefaultApiIcon
              document={record}
              showApiIcon
              showApiIconOnlyConnected
            />
          </div>
        ),
      },

      // {
      //   title: translateWord('status'),
      //   dataIndex: 'status',
      //   ...columnFilters('status', statusFilter),
      //   ...columnSorter('status'),
      //   render: (_, record) => <DocumentStatusTag record={record} />,
      // },

      documentStatusColumn(),
      lastUpdatedColumn(),
      actionsButtonColumn(),
    ].filter(x => !x.hidden);
  }

  // function buildApiIntegrationFilter() {
  //   return [
  //     {
  //       text: translateWord('api_integrated'),
  //       value: 'yes',
  //     },
  //     {
  //       text: translateWord('no_api_integrated'),
  //       value: 'no',
  //     },
  //   ];
  // }
};

export default MultiOperatorsList;
