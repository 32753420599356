import MultiOrdersList from './List/List';
import MultiOrdersForm from './Form/Form';
import {
  MultiOrdersFormHeaderColumn1,
  MultiOrdersFormHeaderColumn2,
  MultiOrdersFormHeaderColumn3,
  MultiOrdersFormHeaderSubtitle,
  MultiOrdersFormHeaderTags,
  MultiOrdersFormHeaderTitle,
} from './Components';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MODULE_ID = 'appmultiOrders';
export const MULTI_ORDERS_API = '/v1/order';

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const MULTI_ORDERS_MODULE = {
  id: MODULE_ID,
  noBlockScreenWithAppNotConfigured: false,
  api: MULTI_ORDERS_API,
  urlCustomForm: null,
  defaultUrlQueryString: null,

  components: {
    // list
    dashboard: null,
    list: props => <MultiOrdersList {...props} />,
    grid: null,
    kanban: null,
    filter: null,

    // form
    form: (props, ref) => <MultiOrdersForm {...props} ref={ref} />,
    formHeaderTitle: props => <MultiOrdersFormHeaderTitle {...props} />,
    formHeaderSubtitle: props => <MultiOrdersFormHeaderSubtitle {...props} />,
    formHeaderColumn1: props => <MultiOrdersFormHeaderColumn1 {...props} />,
    formHeaderColumn2: props => <MultiOrdersFormHeaderColumn2 {...props} />,
    formHeaderColumn3: props => <MultiOrdersFormHeaderColumn3 {...props} />,
    formHeaderTags: props => <MultiOrdersFormHeaderTags {...props} />,
    formHeaderCustomIcons: null,

    // module Parameters
    parameters: null,

    // module data import
    dataImport: null,

    // data preview
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
    // canEditDocument
    // decorateParameters,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    /*
        filter: {},

        filterOptions: {
          status: [
            'waiting_for_payment',
            // 'late_payment',
            'waiting_for_voucher',
            'done',
            'cancelled',
            'cancelled_by_customer',
          ],
        },
    */

    pagination: {
      pageNumber: 1, // current page
      pageSize: ['10', '20', '50', '100'].includes(
        localStorage.getItem('pageSize'),
      )
        ? Number(localStorage.getItem('pageSize'))
        : 10,
    },

    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    /*
        sorter: {
          '_metadata.audit.updatedAt': -1,
        },

        sorterOptions: [
          'customer',
          'destiny',
          '_metadata.audit.createdAt',
          '_metadata.audit.updatedAt',
          '_metadata.counter',
          'status',
        ],

        search: {},

        textSearch: {},
    */
  },

  listState: {
    override: false,
    hideBreadcrumb: false,

    hideTitleSummary: false,
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasNewOptionsButtons: true, // show options action button. p.s. if not exists 'new' action in documentState.actions, the new button will be hidden

    actions: ['refreshList', 'clearSearch', 'showLineNumbers'],

    showParameters: false,
    showDataImport: false,

    initTab: null,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
    fieldsForRegex: [],
    textSearchInDrawer: true, // inform that the textSearch field is into Drawer Filter to mark the filter button as danger (red)
    refreshDataOfList: 0,

    // filter options
    filterInitialize: {
      // dataProfile: dataProfile,
      // dataType: dataType,
    },

    // sort options
    sorterInitialize: {
      '_metadata.audit.updatedAt': -1,
    },

    sorterOrderPrecedence: [
      'customer',
      'destiny',
      '_metadata.audit.createdAt',
      '_metadata.audit.updatedAt',
      '_metadata.counter',
      'status',
    ],
  },

  documentState: {
    showParameters: false,
    disableLockForEdit: false,
    initTab: 'record',
    fieldName: '_metadata.__documentCode',
    newDocumentTitle: 'new_order',
    autoFill: false,
    actions: [
      // 'new',
      // 'edit',
      'reload',
      // 'divider',
      // 'duplicate',
      'file',
      'remove',
      'permanently_remove',
      // 'divider',
      // 'document_status',
      'finish_order',
      'resend_email',
    ],
    refreshParametersOnSave: true,
    indexes: [],

    refreshDataOfDocument: 0,
    mockDocument: {},
  },
};

export default MULTI_ORDERS_MODULE;

function decorateDocument(doc) {
  return doc;
}
