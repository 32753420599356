import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Input,
  Row,
  Tag,
  Tooltip,
  Modal,
} from 'antd';
import { translateX } from 'utils/helpers';
import {
  ModuleContext,
  useModuleContext,
} from 'components/jarvisly-module/ModuleContext';
import { useSelector } from 'react-redux';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import Loading from 'components/jarvisly-components/others-old/Loading';
import Meta from 'antd/es/card/Meta';
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import CrmBoardModal from './modals/BoardModal';
import crmService from '../crmService';

const { confirm } = Modal;

const CrmIndex = props => {
  // Context providers
  const { isWorking, refreshParameters } = useContext(ModuleContext);

  // Props and hooks
  useTabChild('organizations', null, props);
  const parameters = useSelector(s => s.moduleSlice?.parameters?.crmParameters);
  const inputRef = useRef(null);
  const { dataList } = useModuleContext();

  // State management
  const [showFunnelModal, setShowFunnelModal] = useState(false);
  const [workAreas, setWorkAreas] = useState([]);
  const [labels, setLabels] = useState({});
  const [tempLabels, setTempLabels] = useState({});
  const [editingKey, setEditingKey] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  // Effects
  useEffect(() => {
    if (editingKey !== null && inputRef.current) {
      inputRef.current.focus({ cursor: 'all' });
    }
  }, [editingKey]);

  useEffect(() => {
    if (parameters?.__boardGroups?.length) {
      setWorkAreas(
        parameters.__boardGroups.map(g => {
          return {
            key: g._id,
            label: g.label,
            children: <Funnels {...props} boards={g?.boards} />,
          };
        }),
      );

      setLabels(
        parameters.__boardGroups.map(g => {
          return {
            [g._id]: g.label,
          };
        }),
      );
    }
  }, [parameters]);

  // Group management functions
  const onClickAddGroup = async () => {
    const group = {
      label: `${translateX('funnels_group')} #${workAreas.length + 1}`,
    };

    await crmService.addBoardGroup(group);
  };

  const onClickEditGroup = async (e, key) => {
    e.stopPropagation();

    if (e.key === 'Enter' && tempLabels[key]?.trim()) {
      await crmService.editBoardGroupTitle(key, tempLabels[key]);
      setEditingKey(null);
    }
  };

  const onClickDeleteGroup = key => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await crmService.removeBoardGroup(key);
      },
    });
  };

  // Render group title with edit/delete options
  const GroupTitle = item => {
    const { key, label } = item;

    if (editingKey === key) {
      return (
        <Input
          ref={inputRef}
          value={tempLabels[key] || label}
          onChange={e =>
            setTempLabels({ ...tempLabels, [key]: e.target.value })
          }
          onKeyDown={e => onClickEditGroup(e, key)}
          onBlur={() => setEditingKey(null)}
          autoFocus
        />
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>{labels[key] || label}</span>
        <div>
          <Button
            icon={<EditOutlined />}
            type="text"
            onClick={e => {
              e.stopPropagation();
              setEditingKey(key);
              setTempLabels({ ...tempLabels, [key]: labels[key] || label });
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            type="text"
            danger
            onClick={e => {
              e.stopPropagation();
              onClickDeleteGroup(key);
            }}
          />
        </div>
      </div>
    );
  };

  // Child component for funnels/boards
  function FunnelsOld({ group }) {
    const onClickEditFunnel = funnel => {
      console.log('Board clicado:', funnel);
      console.log('Grupo pai deste board:', group);
    };

    const onClickAddFunnel = () => {
      console.log('Adicionando board ao grupo:', group);
      setSelectedGroup(group);
      setShowFunnelModal(true);
    };

    return (
      <Row gutter={[16, 16]}>
        {group?.boards?.map(board => (
          <Col key={board._id} xs={24} sm={12} md={8} lg={6}>
            <Card
              className="agenda-settings-card card-clickable-effect"
              onClick={() => onClickEditFunnel(board)}
            >
              <Row justify="space-between">
                <Meta
                  title={board.title}
                  description={`${translateX('board')}`}
                />
              </Row>
              {board.tags?.map(tag => (
                <Tag key={tag} className="tag text-uppercase">
                  {translateX(tag)}
                </Tag>
              ))}
            </Card>
          </Col>
        ))}

        <Col xs={24} sm={12} md={8} lg={6}>
          <Tooltip title={translateX('new_board')}>
            <Button
              type="dashed"
              size="large"
              onClick={onClickAddFunnel}
              className="agenda-settings-card card-clickable-effect add-agenda"
              block
              style={{ height: '100%' }}
            >
              +
            </Button>
          </Tooltip>
        </Col>
      </Row>
    );
  }

  // Prepare child props for modal
  const childProps = {
    ...props,
    showModal: showFunnelModal,
    setShowModal: setShowFunnelModal,
    selectedGroup,
    setSelectedGroup,
  };

  return (
    <Suspense
      fallback={
        <Row justify="center">
          <Col>
            <h1>Loading...</h1>
          </Col>
        </Row>
      }
    >
      <Loading isFetching={isWorking} data={dataList} marginTop={-200} />

      <div className="my-4 container-fluid">
        <Row gutter={24}>
          <Collapse
            style={{ display: workAreas?.length ? 'inline-block' : 'none' }}
            size="large"
            items={workAreas.map(item => ({
              key: item.key,
              label: GroupTitle(item),
              children: item.children,
            }))}
            className="w-100 collapse-top"
          />
        </Row>

        <Row gutter={24} justify="center" className="pt-4">
          <Button type="primary" size="large" onClick={onClickAddGroup}>
            {translateX('new_funnels_group')}
          </Button>
        </Row>
      </div>

      <CrmBoardModal {...childProps} />
    </Suspense>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function Funnels({ boards }) {
    console.log('funnels boards', boards);

    const onClickEditFunnel = funnel => {
      console.log('edit the funnel', funnel);
    };

    const onClickAddFunnel = () => {
      setShowFunnelModal(true);
    };

    return (
      <Row>
        {boards?.map(f => (
          <div key={f?._id}>
            <Card
              className="agenda-settings-card card-clickable-effect"
              onClick={() => onClickEditFunnel(f)}
            >
              <Row justify="space-between">
                <Meta
                  title={`${f?.title}`}
                  description={`${10} ${translateX('opportunities')}`}
                />
                <span className="font-size-sm text-muted">HH:mm</span>
                <div>
                  <Button
                    icon={<EditOutlined />}
                    type="text"
                    onClick={e => {
                      e.stopPropagation();
                      setEditingKey(f?.i_id);
                      setTempLabels({
                        ...tempLabels,
                        [f?._id]: labels[f?._id],
                      });
                    }}
                  />
                </div>
              </Row>

              <div>
                {f?.tags?.map(tag => (
                  <Tag
                    key={tag}
                    // color={getTypeTagColor(tag)}
                    className="tag text-uppercase"
                  >
                    {translateX(tag)}
                  </Tag>
                ))}
              </div>
            </Card>
          </div>
        ))}

        <Tooltip placement="top" title={translateX('new_funnel')}>
          <Button
            type="default"
            size="large"
            onClick={() => onClickAddFunnel()}
            className="agenda-settings-card card-clickable-effect add-agenda"
          >
            +
          </Button>
        </Tooltip>
      </Row>
    );
  }
};

export default CrmIndex;
