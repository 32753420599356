import dayjs from 'dayjs';
import axiosService from './axiosService';
import bankService from './bankService';
import entityService from './entityService';
import orderService from './orderService';
import { translateX } from '../utils/helpers';
import { RECEIVABLES_API } from '../modules/app-views/receivables/receivables';
import asaasService from './asaasService';

const receivableService = {};

receivableService.generateBillFromOrder = async (
  orderId,
  buyerId,
  bankId,
  billingType = 'whatever',
  dueDate = dayjs().endOf('day'),
  hideMessages,
) => {
  const order = await orderService.getOrderById(orderId);
  const buyer = await entityService.getEntityById(buyerId);
  const bank = await bankService.getBankById(bankId);

  let customer = entityService.decorateEntity({ ...buyer });
  customer = await entityService.upsertEntity(customer?._id, customer);

  const destination = `${translateX(order?.destiny)} (${dayjs(
    order?.departure,
  ).format('L')} - ${dayjs(order?.arrival).format('L')}) | ${
    order?.travellers?.length || 0
  } ${order?.travellers?.length === 1 ? 'Viajante' : 'Viajantes'}`;
  const description = `Pedido Nº ${order?._metadata?.counter} | ${order?.__operator?.name} | ${order?.__plan?.name} | ${destination}`;


  /*netPriceWithCouponDiscount:500,
  paymentDiscount:0,
  paymentDiscountValue:0,
  paymentInstallment:6,
  paymentInstallmentTitle:"6X",
  paymentInstallmentValue:83.33,
  providerGrossPrice:309.72,
  providerNetPrice:216.8,
  resellerGrossPrice:774.3,
  resellerGrossProfit:325.21,
  resellerNetPrice:542.01,
  resellerNetProfit:283.2,
  totalAdjusted:500,
  totalToPay:500,
  totalWithPaymentDiscount:500
  */
  const total = order?.totalInOrderDate;

  const body = {
    billingType,

    value: total?.totalToPay,
    valueCurrency: 'brl',
    dueDate,

    bankCode: bank?.bankCode,
    agencyCode: bank?.agencyCode,
    accountCode: bank?.accountCode,

    description,
    installment: 1,
    installmentTotal: total?.paymentInstallment || 1,

    // FINE AND INTEREST
    fineValue: 0,
    finePercentage: 0,
    fineType: 'percentage',

    interestValue: 0,
    interestPercentage: 0,
    interestType: 'percentage',

    discountValue: 0,
    discountPercentage: 0,
    discountType: 'percentage',

    discountExpireDate: null,

    // CUSTOMER
    entityId: customer._id,

    orderId,
    order: order?.order, // order number
  };

  // POST RECEIVABLE
  let axiosOptions = { url: RECEIVABLES_API, data: body };
  let [doc] = await axiosService.post(axiosOptions);

  // GENERATE ASAAS PAYMENT LINK
  await asaasService.generatePaymentUrl(doc, customer, hideMessages);

  return doc;
};

export default receivableService;
