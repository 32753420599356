import {
  DeploymentUnitOutlined,
  EditOutlined,
  HomeOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { LiaFileContractSolid, LiaShippingFastSolid } from 'react-icons/lia';
import { BsBoxSeam, BsPersonVideo2 } from 'react-icons/bs';
import {
  TbBuildingWarehouse,
  TbForklift,
  TbMoneybag,
  TbSquarePercentage,
} from 'react-icons/tb';
import { FaRegHandshake } from 'react-icons/fa6';
import {
  FaBarcode,
  FaChartBar,
  FaRegCalendarCheck,
  FaRegClipboard,
  FaRegEnvelope,
  FaRegIdBadge,
  FaSearchengin,
} from 'react-icons/fa';

import {
  HiOutlineBuildingOffice2,
  HiOutlineCalculator,
  HiOutlineDocumentArrowDown,
  HiOutlineDocumentArrowUp,
} from 'react-icons/hi2';
import { LuBriefcaseBusiness, LuWarehouse, LuWrench } from 'react-icons/lu';
import {
  MdOutlineAccountBalance,
  MdOutlineAccountTree,
  MdOutlineEvent,
  MdOutlineFactory,
  MdOutlinePrecisionManufacturing,
  MdOutlineProductionQuantityLimits,
  MdOutlineShoppingCart,
  MdOutlineWarehouse,
  MdShoppingCartCheckout,
} from 'react-icons/md';
import {
  RiCoupon3Line,
  RiDiscountPercentLine,
  RiRefund2Line,
} from 'react-icons/ri';
import {
  IoCalculatorOutline,
  IoDocumentOutline,
  IoDocumentTextOutline,
  IoReceiptOutline,
} from 'react-icons/io5';
import {
  PiGavel,
  PiHandCoinsLight,
  PiInvoiceLight,
  PiMoney,
  PiTruck,
} from 'react-icons/pi';
import { HiOutlineDocumentSearch, HiOutlineTicket } from 'react-icons/hi';
import { FiShoppingBag } from 'react-icons/fi';
import { VscTools } from 'react-icons/vsc';
import { GrMoney } from 'react-icons/gr';
import DataSearchIndex from '../../modules/app-views/dataSearch/List/ListIndex';
import { GiMining } from 'react-icons/gi';

const menus = [
  {
    key: 1,
    children: [
      {
        key: 'a1',
        children: [
          {
            key: 'a11',
            children: [],
          },
          {
            key: 'b11',
          },
          {
            key: 'c11',
          },
        ],
      },
      {
        key: 'b1',
        children: [],
      },
      {
        key: 'c1',
        children: [],
      },
    ],
  },
  {
    key: 2,
    children: [
      {
        key: 'a2',
        children: [],
      },
      {
        key: 'b2',
        children: [
          {
            key: 'b22',
          },
        ],
      },
      {
        key: 'c2',
        children: [],
      },
    ],
  },
  {
    key: 3,
    children: [
      {
        key: 'a3',
      },
      {
        key: 'b3',
        children: [],
      },
      {
        key: 'c3',
        children: [],
      },
    ],
  },
];

const menusResult = [
  {
    key: 1,
    children: [
      {
        key: 'a1',
        children: [
          {
            key: 'b11',
          },
          {
            key: 'c11',
          },
        ],
      },
    ],
  },

  {
    key: 2,
    children: [
      {
        key: 'b2',
        children: [
          {
            key: 'b22',
          },
        ],
      },
    ],
  },
  {
    key: 3,
    children: [
      {
        key: 'a3',
      },
    ],
  },
];

const APP_LEKKUS = {
  ID: 'applekkus',
  NAME: 'Lekkus ERP',
  THEME_COLOR: 'CUSTOM_LEKKUS_APP',
  THEME: 'light',
  FINTECH_SERVICES: true,
  DOMAINS: ['sistema.lekkus.com', 'sistema.lekkus.com.br'],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'multiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: false,
  COPY_RIGHT: 'Lekkus Technologies',
  '//API_DEV_HOST': ['localhost', 'vps', 'heroku'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8000,
  API_PORT_PRODUCTION: 8000,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'vps.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  API_HOST_DEV_HEROKU: 'lekkus.herokuapp.com',
  WHATSAPP_INTEGRATED: true,
  API_INTEGRATED: false,
  TOKEN: process.env.REACT_APP_APPLEKKUS_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/applekkus/logo-app.png',
  LOGO_APP_WHITE: '/apps/applekkus/logo-app-white.png',

  LOGO_APP_ICON: '/apps/applekkus/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/applekkus/logo-app-icon-white.png',

  LOGO: '/apps/applekkus/logo.png',
  LOGO_WHITE: '/apps/applekkus/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/applekkus/logo-app-side.png',

  LOGO_SM_WHITE: '/apps/applekkus/logo-sm-white.png',

  TITLE: 'Lekkus ERP',
  '//SLOGAN': 'Condomínios Inteligentes',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: true,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: true,
  },
  ENABLE_MARKETPLACE: true,
  ENABLE_JARVIS: true,
  SHOW_ALL_MENUS: false,
  MENU_NAVIGATION: [
    // ******************
    // HOME
    // ******************
    /*
        {
          key: 'home',
          path: `/app/home`,
          title: 'menu',
          icon: HomeOutlined,
          isGroupTitle: true,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: '/app/home/welcome',
              path: `/app/home/welcome`,
              title: 'home',
              icon: HomeOutlined,
              moduleId: null,
              component: null,
              marketplace: false,
              className: '',
              subscriptionLevel: [0, 1],
              submenu: [],
            },
          ],
        },
    */

    // ******************
    // DATA SOURCE
    // ******************
    {
      key: 'data_source',
      path: '/app/data_source',
      title: 'data_source',
      className: '',
      icon: GiMining,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'data_source_data_search',
          path: '/app/data_source/data_search',
          title: 'data_search',
          icon: FaSearchengin,
          moduleId: 'applekkusDataSearch',
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },

        /*
                {
                  key: 'data_source_lottery',
                  path: '/app/data_source/lottery',
                  title: 'lottery',
                  icon: HiOutlineTicket,
                  moduleId: 'applekkusLottery',
                  component: null,
                  marketplace: false,
                  subscriptionLevel: [0, 1],
                },
        */

        /*        {
                  key: 'data_source_leads',
                  path: '/app/data_source/leads',
                  title: 'leads',
                  icon: DeploymentUnitOutlined,
                  moduleId: 'applekkusLeads',
                  component: null,
                  marketplace: false,
                  subscriptionLevel: [0, 1],
                },*/
      ],
    },

    // ******************
    // RECORDS
    // ******************
    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      className: '',
      icon: EditOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        // entities
        {
          key: 'records_entities',
          path: '/app/records/entities',
          title: 'entities',
          icon: TeamOutlined,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'records_entities_companies',
              path: '/app/records/entities/companies',
              title: 'companies',
              icon: HiOutlineBuildingOffice2,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_entities_employers',
              path: '/app/records/entities/employers',
              title: 'employers',
              icon: FaRegIdBadge,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_entities_providers',
              path: '/app/records/entities/providers',
              title: 'providers',
              icon: FaRegHandshake,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_entities_customers',
              path: '/app/records/entities/customers',
              title: 'customers',
              icon: TeamOutlined,
              moduleId: 'applekkusCustomers',
              component: null,
              marketplace: true,
              subscriptionLevel: [0, 1],
            },
            /*            {
                          key: 'records_entities_consultants',
                          path: '/app/records/entities/consultants',
                          title: 'consultants',
                          icon: LuBriefcaseBusiness,
                          moduleId: 'applekkusConsultants',
                          component: null,
                          marketplace: true,
                          subscriptionLevel: [0, 1],
                        },*/
            /*            {
                          key: 'records_entities_contacts',
                          path: '/app/records/entities/contacts',
                          title: 'contacts_list',
                          icon: BsPersonVideo2,
                          moduleId: 'applekkusContacts',
                          component: null,
                          marketplace: true,
                          subscriptionLevel: [0, 1],
                        },*/
          ],
        },

        // services and products
        {
          key: 'records_stock',
          path: '/app/records/stock',
          title: 'stock',
          icon: MdOutlineWarehouse,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'records_stock_services',
              path: '/app/records/stock/services',
              title: 'services',
              icon: LuWrench,
              moduleId: 'applekkusServices',
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_stock_products',
              path: '/app/records/stock/products',
              title: 'products',
              icon: BsBoxSeam,
              moduleId: 'applekkusProducts',
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            /*
                        {
                          key: 'records_stock_fixed_asset',
                          path: '/app/records/stock/fixed_asset',
                          title: 'fixed_asset',
                          icon: FaBarcode,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },
            */

            // coupons
            /*            {
                          key: 'records_stock_coupons',
                          path: '/app/records/stock/coupons',
                          title: 'discount_coupons',
                          icon: RiDiscountPercentLine,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },*/
          ],
        },
      ],
    },

    // ******************
    // ADMIN
    // ******************
    {
      key: 'admin',
      path: '/app/administrative',
      title: 'administrative',
      className: '',
      icon: MdOutlineAccountTree,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        // ------------------
        // PURCHASES
        // ------------------
        {
          key: 'admin_purchases',
          path: '/app/administrative/purchases',
          title: 'purchases',
          className: '',
          icon: MdOutlineShoppingCart,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            /*            {
                          key: 'admin_purchases_requests',
                          path: '/app/administrative/purchases/requests',
                          title: 'requests',
                          icon: FaRegEnvelope,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },
                        {
                          key: 'admin_purchases_budgets',
                          path: '/app/administrative/purchases/budgets',
                          title: 'budgets',
                          icon: IoCalculatorOutline,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },*/
            {
              key: 'admin_purchases_orders',
              path: '/app/administrative/purchases/orders',
              title: 'orders',
              icon: FaRegCalendarCheck,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_purchases_invoices',
              path: '/app/administrative/purchases/invoices_entrance',
              title: 'invoices_entrance',
              icon: IoReceiptOutline,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
          ],
        },

        // ------------------
        // COMMERCIAL
        // ------------------
        {
          key: 'admin_commercial',
          path: '/app/administrative/commercial',
          title: 'commercial',
          className: '',
          icon: FaRegHandshake,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'admin_commercial_crm',
              path: '/app/administrative/commercial/crm',
              title: 'crm',
              icon: FaChartBar,
              moduleId: 'applekkusCrm',
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            /*            {
                          key: 'admin_commercial_quotations',
                          path: '/app/administrative/commercial/quotations',
                          title: 'quotations',
                          icon: IoCalculatorOutline,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },
                        {
                          key: 'admin_commercial_events',
                          path: '/app/administrative/commercial/events',
                          title: 'events',
                          icon: MdOutlineEvent,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },*/
          ],
        },

        // ------------------
        // SALES
        // ------------------
        {
          key: 'admin_sales',
          path: '/app/administrative/sales',
          title: 'sales',
          className: '',
          icon: FiShoppingBag,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'sales_orders',
              path: '/app/administrative/sales/orders',
              title: 'orders',
              icon: FaRegCalendarCheck,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'sales_checkout',
              path: '/app/administrative/sales/checkout',
              title: 'checkout',
              icon: PiMoney,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            /*            {
                          key: 'sales_expedition',
                          path: '/app/administrative/sales/expedition',
                          title: 'expedition',
                          icon: LiaShippingFastSolid,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },
                        {
                          key: 'sales_pos',
                          path: '/app/administrative/sales/pos',
                          title: 'pos',
                          icon: MdShoppingCartCheckout,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },*/
          ],
        },

        // ------------------
        // TECHNICAL SERVICES
        // ------------------
        {
          key: 'admin_technical',
          path: '/app/administrative/technical',
          title: 'technical_area',
          className: '',
          icon: VscTools,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'admin_technical_quotations',
              path: '/app/administrative/technical/quotations',
              title: 'quotations',
              icon: IoCalculatorOutline,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_technical_services',
              path: '/app/administrative/technical/services',
              title: 'order_service',
              icon: FaRegClipboard,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
          ],
        },

        // ------------------
        // INVOICES
        // ------------------
        {
          key: 'admin_invoices',
          path: '/app/administrative/invoices',
          title: 'invoicing',
          className: '',
          icon: PiInvoiceLight,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'admin_invoices_nfe_emissions',
              path: '/app/administrative/invoices/nfe',
              title: 'nfe_emission',
              icon: IoDocumentTextOutline,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_invoicing_nfse_emissions',
              path: '/app/administrative/invoices/nfse',
              title: 'nfse_emission',
              icon: IoDocumentOutline,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_invoices_store_credit',
              path: '/app/administrative/invoices/store_credit',
              title: 'store_credit',
              icon: RiCoupon3Line,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_invoices_ecf',
              path: '/app/administrative/invoices/ecf',
              title: 'ecf',
              icon: HiOutlineDocumentSearch,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            /*            {
                          key: 'admin_invoices_cte',
                          path: '/app/administrative/invoices/cte',
                          title: 'cte',
                          icon: HiOutlineDocumentArrowUp,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },*/
          ],
        },

        // ------------------
        // MANUFACTURING
        // ------------------
        /*
                {
                  key: 'admin_production',
                  path: '/app/administrative/production',
                  title: 'production',
                  className: '',
                  icon: MdOutlineFactory,
                  isGroupTitle: false,
                  subscriptionLevel: [0, 1],
                  submenu: [
                    {
                      key: 'admin_production_requests',
                      path: '/app/administrative/production/requests',
                      title: 'requests',
                      icon: MdOutlineProductionQuantityLimits,
                      moduleId: null,
                      component: null,
                      marketplace: false,
                      subscriptionLevel: [0, 1],
                    },
                    {
                      key: 'admin_production_orders',
                      path: '/app/administrative/production/orders',
                      title: 'production_orders',
                      icon: MdOutlineProductionQuantityLimits,
                      moduleId: null,
                      component: null,
                      marketplace: false,
                      subscriptionLevel: [0, 1],
                    },
                    {
                      key: 'admin_production_orders_stock_requests',
                      path: '/app/administrative/production/stock_requests',
                      title: 'stock_request',
                      icon: TbBuildingWarehouse,
                      moduleId: null,
                      component: null,
                      marketplace: false,
                      subscriptionLevel: [0, 1],
                    },
                    {
                      key: 'admin_production_assembly',
                      path: '/app/administrative/production/product_assembly',
                      title: 'product_assembly',
                      icon: MdOutlinePrecisionManufacturing,
                      moduleId: null,
                      component: null,
                      marketplace: false,
                      subscriptionLevel: [0, 1],
                    },
                  ],
                },
        */

        // ------------------
        // LOGISTICS
        // ------------------
        {
          key: 'admin_logistics',
          path: '/app/administrative/logistics',
          title: 'logistics',
          className: '',
          icon: LuWarehouse,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            /*            {
                          key: 'admin_logistics_separation',
                          path: '/app/administrative/logistics/separation',
                          title: 'separation',
                          icon: TbForklift,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },
                        {
                          key: 'admin_logistics_packing_list',
                          path: '/app/administrative/logistics/packing_list',
                          title: 'packing_list',
                          icon: IoDocumentTextOutline,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },
                        {
                          key: 'admin_logistics_shipping',
                          path: '/app/administrative/logistics/shipping',
                          title: 'shipping',
                          icon: PiTruck,
                          moduleId: null,
                          component: null,
                          marketplace: false,
                          subscriptionLevel: [0, 1],
                        },*/
            {
              key: 'admin_logistics_devolution',
              path: '/app/administrative/logistics/devolution',
              title: 'devolution',
              icon: RiRefund2Line,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
          ],
        },

        // ------------------
        // FINANCIAL
        // ------------------
        {
          key: 'admin_financial',
          path: '/app/administrative/financial',
          title: 'financial',
          className: '',
          icon: GrMoney,
          isGroupTitle: false,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'admin_financial_payable',
              path: '/app/administrative/financial/payable',
              title: 'payable',
              icon: PiHandCoinsLight,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_financial_receivable',
              path: '/app/administrative/financial/receivable',
              title: 'receivable',
              icon: TbMoneybag,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_financial_commissions',
              path: '/app/administrative/financial/commissions',
              title: 'commissions',
              icon: TbSquarePercentage,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_financial_bank_reconciliation',
              path: '/app/administrative/financial/bank_reconciliation',
              title: 'bank_reconciliation',
              icon: MdOutlineAccountBalance,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'admin_financial_contracts',
              path: '/app/administrative/financial/contracts',
              title: 'contracts',
              icon: LiaFileContractSolid,
              moduleId: null,
              component: null,
              marketplace: false,
              subscriptionLevel: [0, 1],
            },
          ],
        },

        // ------------------
        // ACCOUNTING
        // ------------------
        /*
                {
                  key: 'admin_accounting',
                  path: '/app/administrative/accounting',
                  title: 'accounting',
                  className: '',
                  icon: HiOutlineCalculator,
                  isGroupTitle: false,
                  subscriptionLevel: [0, 1],
                  submenu: [
                    {
                      key: 'admin_accounting_purchase_tax',
                      path: '/app/administrative/accounting/purchase_tax',
                      title: 'purchase_tax',
                      icon: HiOutlineDocumentArrowUp,
                      moduleId: null,
                      component: null,
                      marketplace: false,
                      subscriptionLevel: [0, 1],
                    },
                    {
                      key: 'admin_accounting_sales_tax',
                      path: '/app/administrative/accounting/sales_tax',
                      title: 'sales_tax',
                      icon: HiOutlineDocumentArrowDown,
                      moduleId: null,
                      component: null,
                      marketplace: false,
                      subscriptionLevel: [0, 1],
                    },
                  ],
                },
        */
      ],
    },
  ],
  FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_APPLEKKUS_FIREBASE_CONFIG),
};

export default APP_LEKKUS;
