import ServiceForm from './Form/Form';
import {
  ServiceFormHeaderTitle
} from './Components';
import dayjs from 'dayjs';
import { getFromLocalStorage } from 'utils/helpers';
import ServiceIndex from './Index/ServicesIndex';
import ServicesParametersModal from './Parameters/ParametersModal';
import { DefaultFormHeaderColumn1 } from 'components/jarvisly-module/Form/FormHeader';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
const MODULE_ID = 'applekkusServices';
const MODULE_API = '/v1/services';
export const SERVICE_PARAMETERS_API = '/v1/services/parameters';

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************
const SERVICE_MODULE = {
  id: MODULE_ID,
  noBlockScreenWithAppNotConfigured: false,
  api: MODULE_API,
  urlCustomForm: null,
  defaultUrlQueryString: null,

  components: {
    // list
    dashboard: null,
    // list: props => <ServiceList {...props} />,
    list: props => <ServiceIndex {...props} />,
    grid: null,
    kanban: null, // props => <ServiceKanban {...props} />,
    filter: null,

    // form
    form: (props, ref) => <ServiceForm {...props}/>,
    formHeaderTitle: props => <ServiceFormHeaderTitle {...props} />,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: null,
    formHeaderColumn3: null,
    formHeaderTags: null, // props => <ServiceFormHeaderTags {...props} />,
    formHeaderCustomIcons: null,

    // module Parameters
    parameters: props => <ServicesParametersModal {...props} />,

    // module data import
    dataImport: null,

    // data preview
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
    // canEditDocument
    // decorateParameters,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    /*
        filter: {},
    
        filterOptions: {},
    */

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },

    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    /*
        sorter: {
          '_metadata.audit.updatedAt': -1,
        },
    
        sorterOptions: [
          'customer',
          '_metadata.audit.updatedAt',
          '_metadata.counter',
          'status',
        ],
    
        search: {},
    
        textSearch: {},
    */
  },

  listState: {
    override: false,
    hideBreadcrumb: false,

    hideTitleSummary: false,
    hasFilter: false, // show the filter (folders and customs) drawer button
    hasNewOptionsButtons: true, // show options action button. p.s. if not exists 'new' action in documentState.actions, the new button will be hidden

    actions: [
      'refreshList',
      'clearSearch',
      'showLineNumbers'
    ],

    showParameters: true,
    showDataImport: false,

    initTab: null,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasAccessControl: false, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
    fieldsForRegex: [],
    textSearchInDrawer: true, // inform that the textSearch field is into Drawer Filter to mark the filter button as danger (red)
    refreshDataOfList: 0,

    // filter options
    // filterInitialize: {
    //   dataProfile: dataProfile,
    //   dataType: dataType,
    // },

    filterInitialize: {
      // dataProfile: ['customer'],
      // dataType: ['person', 'company'],
    },

    // sort options
    sorterInitialize: {
      '_metadata.audit.updatedAt': -1,
    },

    sorterOrderPrecedence: [
      '_metadata.counter',
      'name',
      'status',
      '_metadata.audit.updatedAt',
    ],
  },

  documentState: {
    showParameters: true,

    disableLockForEdit: false,
    initTab: 'customer',
    fieldName: '_metadata.__documentCode',
    newDocumentTitle: 'new_opportunity',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: true,
    indexes: [],

    refreshDataOfDocument: 0,
    mockDocument: {},
  },
};

// EXPORT ********************************************************************
// ***************************************************************************
export default SERVICE_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================
function decorateDocument(doc) {
  if (doc?.deadline) doc.deadline = dayjs(doc.deadline);
  if (doc?.lastInteraction) doc.lastInteraction = dayjs(doc.lastInteraction);
  if (doc?.nextInteraction) doc.nextInteraction = dayjs(doc.nextInteraction);
  if (doc?.__entity?.birthdate)
    doc.__entity.birthdate = dayjs(doc.__entity.birthdate);

  if (doc?.followUp && Array.isArray(doc.followUp)) {
    doc.followUp = doc.followUp.map(f => {
      f.label = (
        <>
          <div>
            Criação:
            {dayjs(f?.createdAt).format('DD/MM/YYYY')}
          </div>
        </>
      );
      f.children = (
        <>
          <div>Comentario: {f?.text}</div>
        </>
      );
      return f;
    });
  }
  return doc;
}

function buildMockup() {
  return [
    {
      _id: 1, // funnel area
      order: 1,
      title: 'Campanha Natal',
      funnels: [
        {
          _id: 11, // funnel
          _metadata: {},
          title: 'Renovações Auto',
          tags: ['new', 'campaign'],
          team: [
            {
              _id: 111,
              name: 'Ricardo Biruel',
            },
            {
              _id: 112,
              name: 'Caroline',
            },
            {
              _id: 113,
              name: 'Nicole',
            },
          ],
          pipes: 4, // etapas
          opportunities: 34, // leads, oportunidades em andamento
          amount: 85000, // valor em questão
          dealFinishing: {
            team: 2, // 2 consultores quase fechando
            opportunities: 6, // 6 oportunidades quase fechando (nas maos desses 2 consultores)
            amount: 12800, // valor dessas 6 oportunidades quase fechando
          },
          dealFinished: {
            team: 5, // 5 consultores responsáveis por esse volume de fechamento
            opportunities: 15, // 15 oportunidades fechadas pelos 5 consultores
            amount: 42200, // valor dessas 15 oportunidades fechadas no período
          },
          infoPeriod: 'current_month', // 'current_month', 'last_month', 'current_week', 'last_week', 'last_30_days'
        },
      ],
    },

    {
      _id: 2, // funnel area
      order: 2,
      title: 'Campanha Recorrente',
      funnels: [
        {
          _id: 21, // funnel
          _metadata: {},
          title: 'Seguro Residência',
          tags: ['vehicles', 'trucks'],
          team: [
            {
              _id: 211,
              name: 'Ricardo Biruel',
            },
            {
              _id: 212,
              name: 'Caroline',
            },
            {
              _id: 213,
              name: 'Nicole',
            },
          ],
          pipes: 8,
          opportunities: 89,
          amount: 120000, // valor em questão
          dealFinishing: {
            team: 1,
            opportunities: 16,
            amount: 4800,
          },
          dealFinished: {
            team: 7,
            opportunities: 20,
            amount: 88200,
          },
          infoPeriod: 'current_month', // 'current_month', 'last_month', 'current_week', 'last_week', 'last_30_days'
        },

        {
          _id: 22, // funnel
          _metadata: {},
          title: 'Seguro Auto',
          tags: ['new', 'residence'],
          team: [
            {
              _id: 221,
              name: 'Ricardo Biruel',
            },
            {
              _id: 222,
              name: 'Caroline',
            },
            {
              _id: 223,
              name: 'Nicole',
            },
          ],
          pipes: 8,
          opportunities: 89,
          amount: 120000, // valor em questão
          dealFinishing: {
            team: 1,
            opportunities: 16,
            amount: 4800,
          },
          dealFinished: {
            team: 7,
            opportunities: 20,
            amount: 88200,
          },
          infoPeriod: 'current_month', // 'current_month', 'last_month', 'current_week', 'last_week', 'last_30_days'
        },
      ],
    },

    {
      _id: 3, // funnel area
      order: 3,
      title: 'Nova Campanha',
      funnels: [],
    },
  ];
}
