import { useContext, useState } from 'react';
import { translateX } from 'utils/helpers';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import { ParametersContext } from 'components/jarvisly-module/ParametersProvider';
import { JarvislyFormProvider } from 'components/jarvisly-components/others/FormComponents';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { refreshParameters } = useContext(ModuleContext);
  const { moduleType } = useSelector(s => s.moduleSlice);

  // props deconstruction ------------------------------------------------------
  const {
    showDataImportModal,
    setShowDataImportModal,

    isUpdated,
    setIsUpdated,
  } = props;

  // local variables I ----------------------------------------------------------
  // const { Parameters } = useSelector(s => s.moduleSlice);

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowDataImportModal(false);
  };

  // local variables III --------------------------------------------------------
  /*
    const childProps = {
      ...props,
      isDisabled: false,
      isUpdated,

      Parameters,
      setIsUpdated,
    };
  */

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        title={translateX('data_import')}
        destroyOnClose={true}
        open={showDataImportModal}
        width={640}
        onCancel={onClose}
        cancelText={translateX('close')}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={onClose}
        getContainer={false}
        okType={'default'}
        afterClose={() => {
          if (isUpdated) {
            if (moduleType === 'document') refreshParameters(true);
            setIsUpdated(false);
          }
        }}
      >
        AVANÇO PARA IMPORTAÇÃO DE DADOS
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const DataImportModal = props => {
  // providers context ---------------------------------------------------------
  const { showModalLevel, setShowModalLevel } = useContext(ParametersContext);

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [isUpdated, setIsUpdated] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // variables III -------------------------------------------------------------
  const childProps = {
    ...props,
    isUpdated,
    setIsUpdated,
    showModalLevel,
    setShowModalLevel,
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider>
        <ModalComponent {...childProps} />
      </JarvislyFormProvider>

      {/* STOCKS MODAL WRAPPER */}
      {/*<StocksModal {...childProps} />*/}
      {/* STOCKS MODAL WRAPPER */}
    </>
  );
};

export default DataImportModal;
