import AgendasList from './List/List';
import AgendasGrid from './Grid/GridIndex';
import AppdedaloAgendasSettings from './Settings/Settings';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MODULE_ID = 'appdedaloAgendas';
const MODULE_API = '/v1/agendas';

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const APPDEDALO_AGENDAS_MODULE = {
  id: MODULE_ID,
  noBlockScreenWithAppNotConfigured: false,
  api: MODULE_API,
  urlCustomForm: null,
  defaultUrlQueryString: null,

  components: {
    // list
    dashboard: null,
    list: props => <AgendasList {...props} />,
    grid: props => <AgendasGrid {...props} />,
    kanban: null,
    filter: null,

    // form
    form: null,
    formHeaderTitle: null,
    formHeaderSubtitle: null,
    formHeaderColumn1: null,
    formHeaderColumn2: null,
    formHeaderColumn3: null,
    formHeaderTags: null,
    formHeaderCustomIcons: null,

    // module Parameters
    parameters: props => <AppdedaloAgendasSettings {...props} />,

    // module data import
    dataImport: null,

    // data preview
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
    // canEditDocument
    // decorateParameters,
  },

  initialRequest: {
    folder: 'documents',

    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},

    filterOptions: {},

    pagination: {
      pageNumber: 1, // current page
      pageSize: ['10', '20', '50', '100'].includes(
        localStorage.getItem('pageSize'),
      )
        ? Number(localStorage.getItem('pageSize'))
        : 10,
    },

    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },

    sorterOptions: [
      'customer',
      '_metadata.audit.updatedAt',
      '_metadata.counter',
      'status',
    ],

    search: {},

    textSearch: {},
  },

  listState: {
    override: false,
    hideBreadcrumb: false,

    showParameters: false,
    showDataImport: false,

    initTab: null,
    viewMode: ['grid'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for ple selection
    hasFilter: false, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    hasNewOptionsButtons: false,
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
    fieldsForRegex: [],
    textSearchInDrawer: true, // inform that the textSearch field is into Drawer Filter to mark the filter button as danger (red)
    refreshDataOfList: 0,
  },

  documentState: {
    showParameters: true,

    disableLockForEdit: false,
    initTab: 'customer',
    fieldName: '_metadata.__documentCode',
    newDocumentTitle: 'new_agenda',
    autoFill: false,
    actions: [
      // 'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: true,
    indexes: [],

    refreshDataOfDocument: 0,
    mockDocument: {},
  },
};

export default APPDEDALO_AGENDAS_MODULE;

function decorateDocument(doc) {
  console.log('--->', doc);

  return doc;
}
