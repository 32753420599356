import React, { useEffect, useState } from 'react';
import { Button, Flex, Modal, Table } from 'antd';
import { downloadFile, translateX } from 'utils/helpers';
import appmultiService from 'modules/app-views/_apps/appmulti/appmultiService';
import appService from 'services/appService';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const MultiQuotationCoveragesModal = props => {
  // props deconstruction ------------------------------------------------------
  const { record, showModal, setShowModal, setSelectedRecord } = props;

  // component states -----------------------------------------------------------
  const [data, setData] = useState([]);

  // local variables ------------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const coverages = [];
    if (record?.coverages) coverages.push(...record.coverages);

    coverages.push(
      {
        coverageKey: 'all_coverages',
        coveragesFileUrl: record?.coveragesFileUrl,
        name: record?.name,
      },
      {
        coverageKey: 'additional_info',
        coverageAdditionalInfo: record?.coverageAdditionalInfo,
      },
    );

    setData(coverages);
  }, [record?.coverages]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setSelectedRecord && setSelectedRecord(null);
    setShowModal(false);
  };

  const onDownloadFile = item => {
    if (!item?.coveragesFileUrl || !item?.name) return;
    const fileName = `COBERTURAS ${item?.name.toUpperCase()}.pdf`;
    downloadFile(item.coveragesFileUrl, fileName);
  };

  const onMoreInfo = item => {
    if (!item?.coverageAdditionalInfo) return;

    appService.modal('i', 'Info', {
      Content: item.coverageAdditionalInfo,
    });
  };

  // UI COMPONENT --------------------------------------------------------------

  const columns = buildColumns();

  return (
    <>
      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Fechar"
        okButtonProps={{ type: 'primary' }}
        title={
          <div className="text-muted text-uppercase mb-4">{record?.name}</div>
        }
        // centered
        open={showModal}
        onCancel={onClose}
        onOk={onClose}
        getContainer={false}
      >
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildColumns() {
    return [
      {
        dataIndex: 'coverageKey',
        title: translateX('coverage'),
        render: (_, record) => {
          return <div>{translateX(record?.coverageKey)}</div>;
        },
      },

      {
        dataIndex: 'amount',
        align: 'right',
        title: translateX('amount'),
        render: (_, record) => {
          if (record?.coverageKey === 'all_coverages') {
            const plan = record;

            return (
              <Flex justify="flex-end">
                {plan?.coveragesFileUrl ? (
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    onClick={() => onDownloadFile(plan)}
                  >
                    Download
                  </Button>
                ) : (
                  '-'
                )}
              </Flex>
            );
          } else if (record?.coverageKey === 'additional_info') {
            const plan = record;

            return (
              <Flex justify="flex-end">
                {plan?.coverageAdditionalInfo ? (
                  <Button
                    type="default"
                    icon={<PlusOutlined />}
                    onClick={() => onMoreInfo(plan)}
                  >
                    Info
                  </Button>
                ) : (
                  '-'
                )}
              </Flex>
            );
          } else {
            return appmultiService.getCoverageValue(record, true);
          }
        },
      },
    ];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default MultiQuotationCoveragesModal;
