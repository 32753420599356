import axiosService from 'services/axiosService';
import appService from 'services/appService';
import { MODULE_CONTEXT_VALUES } from 'components/jarvisly-module/ModuleContext';
import { IOT_DEVICES_PARAMETERS_API } from './iotDevices';
import { generateHexColor, translateX } from 'utils/helpers';

const iotDevicesService = {};

iotDevicesService.pushAddToSet = async ({
  data,
  selectedRecord,
  path,
}) => {
  console.log('entrou');
  try {
    const { parameters: appParameters, refreshParameters } =
      MODULE_CONTEXT_VALUES || {};
    const parameters = appParameters?.iotDevicesParameters;

    if (!parameters?._id) {
      throw new Error('Parameters ID is missing');
    }

    if (data?.backColor?.toHexString)
      data.backColor = data.backColor?.toHexString();

    data._id = selectedRecord?._id;
  


    const axiosOptions = {
      url: `${IOT_DEVICES_PARAMETERS_API}/add-to-set/${parameters._id}/$push/${path}`,
      data,
      returnAsObject: true,
    };

    await axiosService.put(axiosOptions);
    appService.message('s', 'saved_parameters', 'onSave');
    refreshParameters();
    return true;
  } catch (error) {
    console.error('AddToSet Error:', error);
    appService.message('e', error.message || 'save_failed', 'onSave');
    throw error;
  }
};

iotDevicesService.pullAddToSet = async ({record,field, path })=> {
  try {
    const { parameters: appParameters, refreshParameters } =
      MODULE_CONTEXT_VALUES || {};
    const parameters = appParameters?.iotDevicesParameters;

    if (!parameters?._id) {
      throw new Error('Parameters ID is missing');
    }
    console.log(record, "get")

    const axiosOptionsGet = {
      url: `v1/iot-device/get-by-field/${field}/${record?._id}/`,
      // url: `v1/iotdevices/get-by-field/unitId/${record._id}/`,
    };

    const doc = await axiosService.get(axiosOptionsGet);

    const qtdItems = doc[0]?.length;
    const translateQtd = `${translateX('There_are_still')} ${qtdItems} ${translateX('iotdevices_linked_unit')}`

    if (qtdItems > 0) {
      appService.message('w', translateQtd)
    } else {
      const axiosOptions = {
        url: `${IOT_DEVICES_PARAMETERS_API}/add-to-set/${parameters._id}/$pull/${path}`,
        // url: `${IOT_DEVICES_PARAMETERS_API}/add-to-set/${parameters._id}/$pull/units._id`,
        data: {
          _id: record?._id,
        },
      };

      await axiosService.put(axiosOptions);
      appService.message('s', 'saved_parameters', 'onSave');
      refreshParameters?.();
    }
    return true;
  } catch (error) {
    console.error('Failed to add units:', error);
    appService.message('e', 'save_failed', 'onSave');
    throw error;
  }
};

export default iotDevicesService;
