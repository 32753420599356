import { Button, Col, Flex, Row, Tooltip } from 'antd';
import { getObjectByField, translateWord, translateX } from 'utils/helpers';
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  FolderOutlined,
  IssuesCloseOutlined,
  ReloadOutlined,
  SendOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { FormDropDownButton, submitForm } from './FormComponents';
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import appService from 'services/appService';
import axiosService from 'services/axiosService';
import dayjs from 'dayjs';
import authService from 'services/authService';
import { PiLifebuoy, PiPlugsConnected } from 'react-icons/pi';
import orderService from 'services/orderService';
import Picture from '../others-old/Picture';
// import ModuleSettingsBlocks from 'modules/app-views/_apps/appsindico/units/Parameters/ModuleSettingsBlocks';
import AskUpdateModal from 'modules/app-views/_apps/appsindico/units/AskUpdateModal/AskUpdateModal';
import { TbClockCog } from 'react-icons/tb';
import { ModuleContext } from '../../jarvisly-module/ModuleContext';
import { FaPlay, FaRecycle } from 'react-icons/fa6';
import { FaCog, FaHome, FaTrashAlt } from 'react-icons/fa';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';

const DocumentHeader = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------
  const { moduleForm: form } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const { selectedModule } = useSelector(s => s.moduleSlice);
  const {
    _id,
    isMobile,
    isEmpty,
    document,
    mode,
    components,
    setDocumentContext,
    refreshDocument,
    resetDocumentContext,
    onGoBack,
    setShowSettings,
    duplicateDocument,
    formRef,
    formWorking,

    // showParametersModal,
    setShowParametersModal,
  } = props;
  
  // local variables -----------------------------------------------------------
  const isDisabled = mode === 'view';
  const navigate = useNavigate();
  const { selectedUser } = useSelector(s => s.authSlice);
  const title = getObjectByField(
    document,
    selectedModule?.documentState?.fieldName,
  );
  const documentStatusModalRef =
    formRef?.current?.documentStatusModalRef?.current;

  const showSaveButton =
    selectedModule?.documentState?.actions?.includes('new');
  selectedModule?.documentState?.actions?.includes('edit');

  const showNewOptionsButton =
    selectedModule?.documentState?.actions?.length > 0;

  const hasPicture = selectedModule?.documentState?.hasPicture;
  const showParameters = selectedModule?.documentState?.showParameters;

  // component states ----------------------------------------------------------
  const [attachments, setAttachments] = useState([]);

  // const onClose = () => {
  //   setShowModuleSettings(false);
  // };

  // component states ----------------------------------------------------------
  const [showAskUpdateModal, setShowAskUpdateModal] = useState(false);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setAttachments(document?.__attachments || []);
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => {
    return {
      test,
    };
  });

  // methods -------------------------------------------------------------------
  const onUploadBefore = file => {};

  const onUploadAfter = file => {};

  const onUploadError = (error, file) => {};

  const onClickSubmitForm = () => submitForm(form);
  
  const onMenuClick = async value => {
    if (!value?.key) return;

    switch (value.key) {
      case 'new': {
        resetDocumentContext(true);
        setAttachments([]);
        navigate(`${selectedModule.urlCustomForm || selectedModule.url}/add`);
        break;
      }

      case 'calibrate': {
        const axiosOptions = {
          _id,
          url: `${selectedModule.api}`,
          data: {
            // 'field1.minReached': null,
            // 'field1.maxReached': null,
            'field1.initialRangeValue': null,
            'field1.finalRangeValue': null,
            'field1.minReachedReadAt': null,
            'field1.maxReachedReadAt': null,
            calibratingStartedAt: new Date().toISOString(),
            calibratingFinishedAt: null,
            status: 'calibrating',
          },
        };

        const options = {
          Content: `${translateX('calibrate_document_ask1')} ${document?.field1?.calibrationtime? document?.field1?.calibrationtime : 24}  ${translateX('hours')}  ${translateX('calibrate_document_ask2')}`,
          okText: 'yes',
          cancelText: 'no',
          onOk: async () => {
            try {
              await axiosService.put(axiosOptions);
              appService.message(
                's',
                translateX('sensor_calibrating'),
                'removed',
              );
              refreshDocument(_id);
            } catch (error) {}
          },
        };

        appService.modal('c', 'calibrate_sensor_title', options);
        break;
      }

      case 'change_condition': {
        const conditionStates = [
          'new',
          'assembled',
          'installed',
          'removed',
          'discarded',
        ];
        const getConditionTitle = currentCondition => {
          // Mapeia a condição para uma chave de tradução específica
          const conditionTitles = {
            new: 'change_condition_to_assembled',
            assembled: 'change_condition_to_installed',
            installed: 'change_condition_to_removed',
            removed: 'change_condition_to_discarded',
            discarded: 'change_condition_to_new',
          };

          // Obtém a chave de tradução com base na condição
          const translationKey = conditionTitles[currentCondition];

          // Retorna a tradução correspondente usando translateX
          return translationKey ? translateX(translationKey) : currentCondition;
        };

        const getConditionContent = currentCondition => {
          // Mapeia a condição para uma chave de tradução específica
          const conditionContents = {
            new: 'condition_document_ask_assembled',
            assembled: 'condition_document_ask_installed',
            installed: 'condition_document_ask_removed',
            removed: 'condition_document_ask_discarded',
            discarded: 'condition_document_ask_new',
          };

          // Obtém a chave de tradução com base na condição
          const translationKey = conditionContents[currentCondition];

          // Retorna a tradução correspondente usando translateX
          return translationKey ? translateX(translationKey) : currentCondition;
        };

        const currentCondition = document?.condition;
        const currentIndex = conditionStates.indexOf(currentCondition);
        const nextIndex = (currentIndex + 1) % conditionStates.length;
        const nextCondition = conditionStates[nextIndex];

        if (nextCondition === 'installed') {
          const waterLevelSensor = document?.water_level_sensor;
          const customerId = document?.customerId;

          const requiredFields = [
            'depthFlow',
            'depthSensor',
            'tankMaterial',
            'tankShape',
            'width',
          ];

          const hasRequiredInfo = requiredFields.every(field =>
            waterLevelSensor?.hasOwnProperty(field),
          );

          if (!customerId || !hasRequiredInfo) {
            appService.notification(
              'w',
              '',
              '',
              'missing_required_info_for_installation',
              5,
            );

            return;
          }
        }

        const axiosOptions = {
          _id,
          url: `${selectedModule?.api}`,
          data: {
            condition: nextCondition,
          },
        };

        const options = {
          Content: getConditionContent(currentCondition),
          okText: 'yes',
          cancelText: 'no',
          onOk: async () => {
            try {
              await axiosService.put(axiosOptions);
              appService.message(
                's',
                getConditionTitle(currentCondition),
                nextCondition, // Mensagem personalizada baseada no próximo estado
              );
              refreshDocument(_id); // Atualiza o documento
            } catch (error) {
              appService.message(
                'e',
                translateX('update_failed'),
                error.message,
              );
            }
          },
        };

        appService.modal('q', 'change_of_condition', options);
        break;
      }

      case 'edit': {
        const canEdit = selectedModule?.methods?.canEditDocument
          ? selectedModule?.methods?.canEditDocument(document)
          : true;

        if (!canEdit) return;
        setDocumentContext({ mode: 'edit' });
        break;
      }

      case 'reload': {
        refreshDocument(_id);
        setDocumentContext({ mode: 'view' });
        break;
      }

      case 'duplicate': {
        duplicateDocument(true);
        navigate(`${selectedModule.urlCustomForm || selectedModule.url}/add`);
        appService.message(
          's',
          translateWord('document_duplicated'),
          'duplicated',
        );
        break;
      }

      case 'remove': {
        const axiosOptions = {
          _id,
          url: `${selectedModule.api}`,
        };

        const options = {
          Content: 'remove_document_ask',
          okText: 'yes',
          cancelText: 'no',
          onOk: async () => {
            try {
              await axiosService.delete(axiosOptions);
              appService.message(
                's',
                translateWord('document_removed'),
                'removed',
              );
              onGoBack();
            } catch (error) {}
          },
        };

        appService.modal('c', 'remove_document_title', options);
        break;
      }

      case 'restore': {
        const body = document?._metadata?.audit?.filedAt
          ? {
              '_metadata.audit.filedAt': null,
              '_metadata.audit.filedByAccountId': null,
            }
          : {
              '_metadata.audit.removedAt': null,
              '_metadata.audit.removedByAccountId': null,
            };

        const axiosOptions = {
          _id,
          url: `${selectedModule.api}`,
          data: body,
        };

        try {
          await axiosService.put(axiosOptions);
          appService.message(
            'i',
            translateWord('document_restored'),
            'restore',
          );
          refreshDocument(_id);
          setDocumentContext({ mode: 'view' });
        } catch (error) {}
        break;
      }

      case 'permanently_remove': {
        const axiosOptions = {
          _id,
          url: `${selectedModule.api}`,
          headers: { 'x-forcepermanentlydeletation': true },
        };

        const options = {
          Content: 'permanently_remove_document_ask',
          okText: 'yes',
          cancelText: 'no',
          onOk: async () => {
            try {
              await axiosService.delete(axiosOptions);
              appService.message(
                's',
                translateWord('document_removed'),
                'removed',
              );

              if (selectedModule?.id === 'accounts') {
                authService.refreshAccount();
              }

              onGoBack();
            } catch (error) {}
          },
        };

        appService.modal('c', 'attention', options);
        break;
      }

      case 'file': {
        const body = {
          '_metadata.audit.filedAt': dayjs(),
          '_metadata.audit.filedByAccountId': selectedUser._id,
        };
        const axiosOptions = {
          url: `${selectedModule.api}`,
          _id,
          data: body,
        };

        const options = {
          Content: 'file_document_ask',
          okText: 'yes',
          cancelText: 'no',
          onOk: async () => {
            try {
              await axiosService.put(axiosOptions);
              appService.message(
                's',
                translateWord('document_archived'),
                'file',
              );
              onGoBack();
            } catch (error) {}
          },
        };

        appService.modal('c', 'file_document_title', options);
        break;
      }

      case 'settings': {
        setShowSettings(true);
        break;
      }

      case 'document_status': {
        documentStatusModalRef.setShowModal(true);
        break;
      }

      // MENUS PERSONALIZADOS QUE DEVEM SER ALTERADO PARA O MODULO OU OUTRO LOCAL

      case 'finish_order': {
        orderService
          .finishOrder(document)
          .then(() => refreshDocument())
          .catch(error => console.error(error));
        break;
      }

      case 'resend_email': {
        orderService
          .sendEmail(document?._id, 'done', document?.__buyer?.email, true)
          .then();
        break;
      }

      case 'askForUpdate': {
        setShowAskUpdateModal(true);
        break;
      }

      default:
        console.error('no menu key!');
    }
  };

  // local variables III -------------------------------------------------------
  // const childProps = {
  //   ...props,
  //   showModal: showParametersModal,
  //   setShowModal: setShowParametersModal,
  // };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div
      // style={{ minHeight: 177 }}
      style={{ minHeight: mode !== 'add' ? 177 : 'unset' }}
    >
      {/* SETTINGS BUTTON */}
      {/* <Tooltip>
            <Button
              className="ml-2 mr-2"
              icon={<SettingOutlined />}
              onClick={() => setShowModuleSettings(true)}
            />
          </Tooltip> */}
      {/* <JarvislySettingsModal
        {...props}
        showModal={showModuleSettings}
        setShowModal={setShowModuleSettings}
      >
        <ModuleSettingsBlocks {...props} />
      </JarvislySettingsModal> */}
      {/* SETTINGS BUTTON

          {/* ASK UPDATE MODAL */}
      <AskUpdateModal
        {...props}
        setShowModal={setShowAskUpdateModal}
        showModal={showAskUpdateModal}
      />
      {/* ASK UPDATE MODAL */}

      <Flex
        // className="py-2"
        style={{ minHeight: 69 }}
        vertical={isMobile}
        justify={isMobile ? 'start' : 'space-between'}
        align="center"
      >
        <Flex
          vertical
          style={{ minHeight: 37 }}
          justify="start"
          align={isMobile ? 'center' : 'start'}
          // className={mode !== 'add' ? 'mb-3' : ''}
        >
          <Row>
            <Col>
              {/*picture*/}
              {/* <div className="d-md-flex"> */}
              {hasPicture ? (
                <Picture
                  className="photo-module-form-header"
                  hideUploadButton={true}
                  buttonTitle="Upload"
                  name="photo"
                  tags="photo"
                  listType="picture"
                  // fileFolder="documentsPictures"
                  attachmentType="documentPhoto"
                  attachments={attachments}
                  setAttachments={setAttachments}
                  onUploadBefore={onUploadBefore}
                  onUploadAfter={onUploadAfter}
                  onUploadError={onUploadError}
                  isCroppingEnabled={true}
                  accept={'.jpg,.png'}
                  fileSize={3072}
                  disabled={isDisabled} // ou algum estado que controle a desabilitação
                  maxFiles={1}
                />
              ) : null}
              {/* </div> */}
            </Col>
            <Col>
              <h2
                className={`text-uppercase text-truncate ${
                  _id ? 'mb-1' : 'mb-0'
                }`}
              >
                {!_id
                  ? translateWord(
                      selectedModule?.documentState?.newDocumentTitle ||
                        'new_document',
                    )
                  : components?.formHeaderTitle
                    ? components?.formHeaderTitle(props)
                    : title}
              </h2>
              {_id ? (
                <div>
                  {components?.formHeaderSubtitle &&
                    components.formHeaderSubtitle(props)}
                </div>
              ) : null}
            </Col>
          </Row>
        </Flex>

        <Flex
          className={`${isMobile ? 'm-3' : ''}`}
          justify={isMobile ? 'center' : 'end'}
        >
          {/* SETTINGS BUTTON */}
          {/* <Row>
            <Col>
              {showParameters ? (
                <>
                  <Tooltip>
                    <Button
                      className="ml-2 mr-2"
                      icon={<SettingOutlined />}
                      onClick={() => setShowModuleSettings(true)}
                    />
                  </Tooltip>
                  <JarvislySettingsModal
                    {...props}
                    showModal={showModuleSettings}
                    setShowModal={setShowModuleSettings}
                  >
                    <ModuleSettingsBlocks {...props} />
                  </JarvislySettingsModal>
                </>
              ) : null}
            </Col>
          </Row> */}
          {/* SETTINGS BUTTON

          {/* ASK UPDATE MODAL */}
          {/* <AskUpdateModal
            {...props}
            setShowModal={setShowAskUpdateModal}
            showModal={showAskUpdateModal}
          /> */}
          {/* ASK UPDATE MODAL */}
          {/* PARAMETERS */}
          {/*{['owner', 'keyUser'].includes(rdxSubscription?.profile) && rdxModule?.sectionSettingsComponent &&*/}

          {showParameters && selectedModule?.components?.parameters ? (
            <>
              <Tooltip title={translateX('parameters')} placement="top">
                <Button
                  className="mr-3"
                  style={{
                    width: 40,
                  }}
                  icon={<SettingOutlined />}
                  onClick={() => setShowParametersModal(true)}
                />
              </Tooltip>
            </>
          ) : null}

          {/* PARAMETERS */}

          {/* GO BACK BUTTON */}
          <Button
            type="default"
            icon={<ArrowLeftOutlined />}
            onClick={onGoBack}
            className="mr-3"
            style={{
              width: 116,
            }}
          >
            &nbsp;{translateX('back')}
          </Button>
          {/* GO BACK BUTTON */}

          {/* SAVE BUTTON */}
          <FormDropDownButton
            type={showSaveButton ? 'primary' : 'default'}
            disabled={formWorking}
            title={showSaveButton ? 'save' : ''}
            // onClick={onActionClick}
            showButton={showSaveButton}
            showNewOptionsButton={showNewOptionsButton}
            // type="primary"
            // showButton={true}
            // title="save"
            onClick={onClickSubmitForm}
            // style={{ width: 116 }}
            menu={{
              items: buildActionsItems(),
              onClick: e => onMenuClick(e),
            }}
          />
          {/* SAVE BUTTON */}
        </Flex>
      </Flex>

      <Row
        style={{
          minHeight: mode !== 'add' ? 90 : 'unset',
          display: mode === 'add' && 'none',
        }}
        justify="space-between"
        align="middle"
        className="skeleton-without-paragraph mt-2 mb-2"
      >
        {components?.formHeaderColumn1 && components.formHeaderColumn1(props)}
        {components?.formHeaderColumn2 && components.formHeaderColumn2(props)}
        {components?.formHeaderColumn3 && components.formHeaderColumn3(props)}
      </Row>

      <Row
        justify="end"
        align="middle"
        style={{
          minHeight: isEmpty ? 'unset' : 18,
          display: mode === 'add' && 'none',
        }}
      >
        {components?.formHeaderCustomIcons &&
          components.formHeaderCustomIcons(props)}

        {components?.formHeaderTags && components.formHeaderTags(props)}

        {/*<DocumentTags*/}
        {/*  record={document}*/}
        {/*  className={components?.formHeaderCustomIcons ? 'ml-2' : ''}*/}
        {/*/>*/}
      </Row>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildActionsItems() {
    const conditionIcons = {
      new: <HiOutlineWrenchScrewdriver />,
      assembled: <PiPlugsConnected />,
      installed: <FaTrashAlt />,
      removed: <FaRecycle />,
      discarded: <FileOutlined />, // Ícone para "discarded"
    };

    const getConditionTitle = currentCondition => {
      // Mapeia a condição para uma chave de tradução específica
      const conditionTitles = {
        new: 'change_condition_to_assembled',
        assembled: 'change_condition_to_installed',
        installed: 'change_condition_to_removed',
        removed: 'change_condition_to_discarded',
        discarded: 'change_condition_to_new',
      };

      // Obtém a chave de tradução com base na condição
      const translationKey = conditionTitles[currentCondition];

      // Retorna a tradução correspondente usando translateX
      return translationKey ? translateX(translationKey) : currentCondition;
    };

    const actionsDisabled = $buildDisableActions();
    const actionsHidden = $buildHiddenActions();

    // Ensure document is not null or undefined
    const status = document?.status || document?._metadata?.__documentStatus;
    const condition = document?.condition || 'new'; // Default to 'new' if condition is null

    const allActions = [
      {
        type: 'divider',
      },

      {
        key: 'new',
        icon: <FileOutlined />,
        label: <div>{translateX('new')}</div>,
        disabled: actionsDisabled.includes('new'),
        hidden: actionsHidden.includes('new'),
      },
      {
        key: 'change_condition',
        icon: conditionIcons[condition], // Use default condition icon
        label: <div>{getConditionTitle(condition)}</div>, // Título do próximo estado com base na condição
        disabled: actionsDisabled.includes('condition'),
        hidden: actionsHidden.includes('condition'),
      },

      {
        key: 'calibrate',
        icon: <TbClockCog />,
        label: <div>{translateX('calibrate_sensor_title')}</div>,
        disabled: actionsDisabled.includes('calibrate'),
        hidden: actionsHidden.includes('calibrate'),
      },

      {
        key: 'edit',
        icon: <EditOutlined />,
        label: <div>{translateX('edit')}</div>,
        disabled: actionsDisabled.includes('edit'),
        hidden: actionsHidden.includes('edit'),
      },

      {
        key: 'reload',
        icon: <ReloadOutlined />,
        label: <div>{translateX('reload_document')}</div>,
        disabled: actionsDisabled.includes('reload'),
        hidden: actionsHidden.includes('reload'),
      },

      {
        key: 'remove',
        icon: <CloseOutlined />,
        label: <div>{translateX('remove')}</div>,
        disabled: actionsDisabled.includes('remove'),
        hidden: actionsHidden.includes('remove'),
      },

      {
        key: 'permanently_remove',
        icon: <DeleteOutlined />,
        label: (
          <div
            style={{
              color: !actionsDisabled?.includes('permanently_remove')
                ? '#f44337'
                : '',
            }}
          >
            {translateX('permanently_remove')}
          </div>
        ),
        disabled: actionsDisabled.includes('permanently_remove'),
        hidden: actionsHidden.includes('permanently_remove'),
      },

      {
        key: 'restore',
        icon: <PiLifebuoy />,
        label: <div>{translateX('restore')}</div>,
        disabled: actionsDisabled.includes('restore'),
        hidden: actionsHidden.includes('restore'),
      },

      {
        key: 'file',
        icon: <FolderOutlined />,
        label: <div>{translateX('file_document')}</div>,
        disabled: actionsDisabled.includes('file'),
        hidden: actionsHidden.includes('file'),
      },

      {
        key: 'duplicate',
        disabled: actionsDisabled.includes('duplicate'),
        hidden: actionsHidden.includes('duplicate'),
        icon: <CopyOutlined />,
        label: <div>{translateX('duplicate_document')}</div>,
      },

      {
        key: 'autoFill',
        disabled: actionsDisabled.includes('autoFill'),
        hidden: actionsHidden.includes('autoFill'),
        label: <div>{translateX('auto_fill')}</div>,
      },

      {
        key: 'document_status',
        disabled: actionsDisabled.includes('document_status'),
        hidden: actionsHidden.includes('document_status'),
        icon:
          status === 'with_reservation' ? (
            <IssuesCloseOutlined />
          ) : status === 'revised' ? (
            <CheckCircleOutlined />
          ) : (
            <ExclamationCircleOutlined />
          ),
        label: translateX('change_status'),
        // label: (
        //   <div>
        //     {!status || status === 'in_analysis'
        //       ? translateX('mark_as_revised')
        //       : translateX(status)}
        //   </div>
        // ),
      },

      // {
      //   key: 'status',
      //   disabled: actionsDisabled.includes('status'),
      //   hidden: actionsHidden.includes('status'),
      //   icon: <IssuesCloseOutlined />,
      //   // label: <div>{translateX( 'change_status')}</div>,
      //   label: <div>{translateX( 'document_status')}</div>,
      // },

      {
        key: 'askForUpdate',
        disabled: actionsDisabled.includes('askForUpdate'),
        hidden: actionsHidden.includes('askForUpdate'),
        icon: <SendOutlined />,
        label: <div>{translateX('request_update')}</div>,
      },

      // {
      //   key: 'configurationTab',
      //   disabled: actionsDisabled.includes('configurationTab'),
      //   hidden: actionsHidden.includes('configurationTab'),
      //   icon: <SettingOutlined />,
      //   label: <div>{translateX('parameterization')}...</div>,
      // },

      {
        key: 'finish_order',
        disabled: actionsDisabled.includes('finish_order'),
        hidden: actionsHidden.includes('finish_order'),
        icon:
          status === 'with_reservation' ? (
            <IssuesCloseOutlined />
          ) : (
            <CheckCircleOutlined />
          ),
        label: translateX('finish_order'),
      },

      {
        key: 'resend_email',
        disabled: actionsDisabled.includes('resend_email'),
        hidden: actionsHidden.includes('resend_email'),
        icon: <SendOutlined />,
        label: translateX('resend_email'),
      },
    ];

    const availableActions = [];
    const restoreAction = allActions.find(x => x.key === 'restore');

    selectedModule?.documentState?.actions.forEach(a => {
      const action = allActions.find(x => x.key === a || x.type === a);
      if (action) availableActions.push(action);
    });

    if (availableActions.find(x => ['remove', 'file'].includes(x.key))) {
      availableActions.push(restoreAction);
    }

    return availableActions;

    function $buildHiddenActions() {
      const status = document?.status || document?._metadata?.__documentStatus;

      const hidden = [];

      if (!selectedModule?.components?.parameters) hidden.push('settings');

      const isRemoved = !!document?._metadata?.audit?.removedAt;
      const isFiled = !!document?._metadata?.audit?.filedAt;

      if (selectedModule?.documentState?.disableLockForEdit) {
        hidden.push('edit');
      }

      if (
        isRemoved ||
        !selectedModule?.documentState?.actions?.includes('remove')
      ) {
        hidden.push('remove', 'file');
      } else {
        hidden.push('permanently_remove');
      }

      // if (!_id) hidden.push('permanently_remove');

      if (isFiled) hidden.push('file', 'remove', 'permanently_remove');

      if (!isFiled && !isRemoved) hidden.push('restore');

      if (!['waiting_for_voucher'].includes(status)) {
        hidden.push('finish_order');
      }

      if (!['done'].includes(status)) {
        hidden.push('resend_email');
      }

      return hidden;
    }

    // internal functions
    function $buildDisableActions() {
      const status = document?.status || document?._metadata?.__documentStatus;
      const isRemoved = !!document?._metadata?.audit?.removedAt;
      const isFiled = !!document?._metadata?.audit?.filedAt;

      const disabled = [];

      if (mode === 'add') {
        disabled.push(
          'edit',
          'reload',
          'remove',
          'file',
          'duplicate',
          'document_status',
          'finish_order',
          'askForUpdate',
          'resend_email',
          'calibrate',
          'change_condition',
        );
      }

      if (mode === 'view') {
        disabled.push('autoFill', 'reload');
      }

      if (mode === 'edit') {
        disabled.push('edit', 'autoFill');
      }

      if (mode === 'askForUpdate') {
        disabled.push('askForUpdate');
      }

      if (!isRemoved && !isFiled) {
        disabled.push('restore');
      }

      if (isRemoved || isFiled) {
        disabled.push('edit');
      }

      if (!_id || _id === 'add') {
        disabled.push('permanently_remove');
      }

      if (
        ['waiting_for_voucher', 'paid', 'received', 'done'].includes(status)
      ) {
        disabled.push('remove', 'file');
      }

      // if (['done'].includes(status)) {
      //   disabled.push('finish_order');
      // }

      return disabled;
    }
  }
});

export default DocumentHeader;
