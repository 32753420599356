import { EditOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Flex, Table, Tooltip } from 'antd';
import {
  parseDateToDisplay,
  setLocale,
  translateWord,
  translateX,
} from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import accounting from 'accounting';
import React from 'react';
import { useSelector } from 'react-redux';
import MULTI_PLANS_MODULE from '../plans';
import Icon from 'components/util-components/Icon';
import { DefaultApiIcon } from 'components/jarvisly-module/Form/FormHeader';

const MultiPlansList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    onClickOpen,

    numberLineColumn,
    documentCodeColumn,
    documentStatusColumn,
    lastUpdatedColumn,
    actionsButtonColumn,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const { parameters: appParameters } = useSelector(s => s.moduleSlice);
  const statusFilter = buildDocumentStatusFilter();

  const operatorsFilter = appParameters?.operatorsParameters?.map(o => {
    return {
      text: o?.name,
      value: o?._id,
    };
  });

  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDocumentStatusFilter() {
    const options = [
      // 'not_updated',
      // 'pending_update',
      // 'waiting_for_update',
      // 'updated',
      'in_analysis',
      'with_reservation',
      'revised',
    ];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildTableColumns() {
    return [
      numberLineColumn(),
      documentCodeColumn(),

      {
        title: translateX('plan'),
        dataIndex: 'name',
        ...columnSorter('name'),
        ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => (
          <>
            <div className="text-uppercase">{record?.name}</div>
          </>
        ),
      },

      {
        title: translateX('operator'),
        dataIndex: 'operatorId',
        ...columnFilters('operatorId', operatorsFilter),
        ...columnSorter('operatorId'),
        align: 'left',
        render: (_, record) => (
          <>
            <div className="font-weight-bold">{record?.__operator?.name}</div>

            <Tooltip
              title={translateX(record?.__operator?.parameters.__tooltip)}
            >
              {/*{translateX('exchange')}:*/}
              {record?.__operator?.parameters?.__exchangeRate &&
              !record?.__operator?.parameters?.__hideExchangeOnList
                ? accounting.formatMoney(
                    record.__operator?.parameters.__exchangeRate,
                    `${record?.__operator?.parameters?.__symbol} `,
                    2,
                    '.',
                    ',',
                  )
                : ''}
            </Tooltip>
          </>
        ),
      },

      {
        title: translateX('price'),
        dataIndex: 'price',
        ...columnSorter('price'),
        align: 'right', // Isso já deve alinhar à direita no contexto da tabela
        render: (_, record) => (
          <Flex justify="flex-end" align="start" style={{ width: 100 }}>
            <div style={{ textAlign: 'right', width: '100%' }}>
              <div style={{ textTransform: 'uppercase' }}>
                {accounting.formatMoney(
                  record?.price || record?.dailyPrice,
                  `${
                    record?.__priceSymbol ||
                    record?.__operator?.parameters?.__symbol
                  } `,
                  2,
                  '.',
                  ',',
                )}
              </div>
              <div style={{ textTransform: 'lowercase' }}>
                {translateX(
                  !record?.priceType || record.priceType === 'daily'
                    ? 'day'
                    : record.priceType,
                )}
              </div>
            </div>
          </Flex>
        ),
      },

      {
        title: translateX('api'),
        dataIndex: '_integrations.planCode',
        ...columnSorter('_integrations.planCode'),
        width: 70,
        render: (_, record) => (
          <div className="text-center">
            {record?.__operator?._integrations?.operatorCode ? (
              <DefaultApiIcon document={record} showApiIcon />
            ) : null}
          </div>
        ),
      },

      // {
      //   title: translateX('status'),
      //   dataIndex: 'status',
      //   ...columnFilters('status', statusFilter),
      //   ...columnSorter('status'),
      //   render: (_, record) => <DocumentStatusTag record={record} />,
      // },

      documentStatusColumn(),
      lastUpdatedColumn(),
      actionsButtonColumn(),
    ].filter(x => !x.hidden);
  }
};

export default MultiPlansList;
